import { useState } from "react";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { ProDataGridContext } from "./ProDataGridContext";

export const ProDataGridProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [allSelectedRows, setAllSelectedRows] = useState<GridSelectionModel>([]);

  const resetSelected = () => {
    setIsSelected(false);
    setSelectionModel([]);
    setAllSelectedRows([]);
  };

  return (
    <ProDataGridContext.Provider
      value={{
        isSelected,
        setIsSelected,
        selectionModel,
        setSelectionModel,
        allSelectedRows,
        setAllSelectedRows,
        resetSelected,
      }}
    >
      {children}
    </ProDataGridContext.Provider>
  );
}