import { createTheme } from "@mui/material/styles";
import * as constants from "common/constants/theme";

declare module "@mui/material/styles" {
  interface Palette {
    accent: Palette["primary"];
    purple: Palette["primary"];
    secondaryWhite: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    accent?: PaletteOptions["primary"];
    purple?: PaletteOptions["primary"];
    secondaryWhite?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accent: true;
    purple: true;
    secondaryWhite: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    accent: true;
    purple: true;
    secondaryWhite: true;
  }
}

const theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: "3.5rem",
      "@media (min-width: 0px) and (orientation: landscape)": {
        minHeight: "3rem",
      },
      "@media (min-width: 600px)": {
        minHeight: "3.5rem",
      },
    },
  },
  palette: {
    text: {
      primary: constants.darkBaseGrey,
      secondary: constants.baseGrey,
      disabled: constants.lightBaseGrey,
    },
    divider: constants.lightBaseGrey,
    background: {
      paper: constants.white,
      default: constants.backgroundGrey,
    },
    primary: {
      light: constants.primaryLight,
      main: constants.primaryMain,
      dark: constants.primaryDark,
    },
    secondary: {
      light: constants.secondaryLight,
      main: constants.secondaryMain,
      dark: constants.secondaryDark,
    },
    secondaryWhite: {
      light: constants.secondaryLight,
      main: constants.secondaryMain,
      dark: constants.secondaryDark,
      contrastText: constants.white,
    },
    success: {
      main: constants.InfoPositive,
      contrastText: constants.white,
    },
    accent: {
      main: constants.mainAccent,
      contrastText: constants.white,
    },
    purple: {
      main: constants.shadePurple,
      contrastText: constants.white,
    },
    warning: {
      main: constants.actionNotice,
    },
    error: {
      main: constants.warning,
      contrastText: constants.white,
    },
    grey: {
      A200: constants.outputFieldGrey,
    },
    info: {
      main: constants.formStandardPink,
    },
  },
  typography: {
    fontFamily: "Lato, Arial, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "1.6rem",
      fontWeight: 600,
      margin: 0,
    },
    h2: {
      fontSize: "1.1rem",
      fontWeight: 600,
      margin: 0,
    },
    h3: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: "1.2",
    },
    h4: {
      fontSize: ".9rem",
      fontWeight: 700,
      lineHeight: "1.1",
    },
    h5: {
      fontSize: ".8rem",
      fontWeight: 500,
      lineHeight: "1",
    },
    h6: {
      fontSize: ".7rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          // Use the following if you need to redefine the content size
          html: {
            "@media (max-width: 600px)": {
              WebkitFontSmoothing: "auto",
              fontSize: "80%",
            },
          },
          p: {
            margin: ".5px",
          },
          h2: {
            margin: "1rem",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: constants.rounding,
          padding: ".5rem",
        },
        text: {
          color: constants.baseGrey,
          fontWeight: 600,
          textTransform: "none",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderRadius: constants.rounding,
          padding: "1rem",
          color: constants.darkBaseGrey,
        },
      },
    },
  },
});

export default theme;
