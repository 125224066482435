import { useCallback, useState } from "react";
import { LOADING_STATES } from "common/types/loading";

export const useLoading = () => {
  const [loadingState, setLoadingState] = useState<LOADING_STATES | null>(null);
  const finishLoading = useCallback(() => {
    setLoadingState(null);
  }, [setLoadingState]);

  return {
    loadingState,
    setLoadingState,
    finishLoading,
  };
};
