import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

// Auth
import { useAPI, useAuth, usePopups } from "hooks";

// Material UI
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";

// Material UI Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Custom imports
import * as constants from "common/constants/theme";
import Logo from "components/logo";
import { useStyles } from "./styles";

import { ProfileOptionsLoggedIn } from "./profileConfig";
import {
  Autocomplete,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  TextField,
} from "@mui/material";
import { useDebounce } from "use-debounce";
import { Methods } from "api";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { TYPE_ERROR } from "common/constants/common";
import {
  APPROVED,
  MY_CLAIMS,
  ON_HOLD,
  PAID,
  REJECTED,
  TEAM_CLAIMS,
  UNASSIGNED,
} from "layout/routes/RouteConstants";
import { CloseRounded, SearchRounded } from "@mui/icons-material";
import theme from "theme";
import { GlobalClaim, ClaimFilterInput } from "common/types/commonTypes";

const Appbar: React.FC = () => {
  const [rowData, setRowData] = useState<GlobalClaim[]>([]);
  const { handleBannerMessage } = usePopups();
  const [searchString, setSearchString] = useState<string>("");
  const [debouncedSearchString] = useDebounce(
    searchString && searchString.trim().length > 2 ? searchString.trim() : "",
    1000
  );
  const { authService, user, clearCredentials } = useAuth();
  const classes = useStyles({ loggedIn: !!user });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<Element | null | undefined>(
    null
  );
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const profileOptions = [...ProfileOptionsLoggedIn];

  const isMenuOpen = Boolean(anchorEl);

  const {
    loading: claimLoading,
    error: claimError,
    trigger: getClaims,
  } = useAPI<
    GlobalClaim[],
    {
      filter: ClaimFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "getGlobalClaims",
    manual: true,
    onCompleted(data) {
      if (data) {
        setRowData(data);
      }
    },
  });

  useEffect(() => {
    if (!!debouncedSearchString) {
      getClaims({
        filter: {
          searchValue: debouncedSearchString,
        },
      });
    }
    //!! Don't add getClaims as a dependency, as it might cause a infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchString]);

  useEffect(() => {
    if (claimError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [claimError, handleBannerMessage]);

  useEffect(() => {
    if (claimLoading) {
      setRowData([]);
    }
  }, [claimLoading]);

  // The user has clicked on the profile icon while the popover menu is closed
  const handleProfileMenuOpen = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setPopoverOpen(!popoverOpen);
    setAnchorEl(anchorEl ? null : event?.currentTarget);
  };

  // The user has clicked on the profile icon while the popover menu is open
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Close the menu before redirecting to signin screen
  const handleSignout = async () => {
    try {
      handleMenuClose();
      authService && (await authService.signout());
      clearCredentials && clearCredentials();
    } catch (err) {
      console.log("Error signing out: ", err);
    }
  };

  // Handle the user selection from the popover menu
  const handleProfileSelection = (selected: number) => {
    switch (selected) {
      case 0:
        handleSignout();
        break;
      case 99: // empty item, for version
        break;
      default:
        navigate(`/${profileOptions[selected].path}`);
        break;
    }
  };

  const id = popoverOpen ? "simple-popover" : undefined;

  const renderMenu = (
    <Popover
      data-test-id="user-menu"
      id={id}
      elevation={16}
      PaperProps={{
        classes: {
          root: classes.popoverPaper,
        },
        square: true,
      }}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: -11,
        horizontal: "right",
      }}
    >
      <Fade in={isMenuOpen} {...(isMenuOpen ? { timeout: 300 } : {})}>
        <List classes={{ root: classes.list }}>
          {profileOptions.map(({ key, label, icon: Icon, divider, testId }) => (
            <ListItemButton
              key={`${key}${label}`}
              data-test-id={testId}
              divider={divider}
              onClick={() => handleProfileSelection(key)}
              classes={{
                root: classes.listItem,
              }}
            >
              <ListItemIcon>
                <Icon color="primary" />
              </ListItemIcon>
              <ListItemText secondary={label} />
            </ListItemButton>
          ))}
        </List>
      </Fade>
    </Popover>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        data-test-id="application-appbar"
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar)}
        classes={{
          root: classes.root,
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: constants.s12Blue,
          }}
        >
          <Box ml={2}>
            <Logo />
          </Box>
          <Box pl={1.5}>
            <Typography variant="h3">S12 Claims</Typography>
          </Box>
          <Box pl={1.5}>
            {(() => {
              switch (process.env.REACT_APP_ENV) {
                case "preprod":
                  return (
                    <Chip
                      label="Preprod"
                      className={classes.modeChipWhite}
                      size="small"
                    />
                  );
                case "dev":
                  return (
                    <Chip
                      label="Development"
                      className={classes.modeChipRed}
                      size="small"
                    />
                  );
                case "sandbox":
                  return (
                    <Chip
                      label="Sandbox"
                      className={classes.modeChipPink}
                      size="small"
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Box>
          <Box pl={3} display="flex" flexDirection="row">
            <Box borderRadius={1}>
              <Autocomplete
                filterOptions={(x) => x}
                options={rowData}
                loading={claimLoading && rowData.length === 0}
                inputValue={searchString}
                multiple={false}
                disableClearable
                noOptionsText={searchString ? 'No results found' : 'Please enter a search term'}
                onInputChange={(event, value, reason) => {
                  switch (reason) {
                    case "input":
                      setSearchString(value);
                      break;

                    default:
                      break;
                  }
                }}
                onChange={(event, value, reason) => {
                  if (reason === "selectOption" && value) {
                    switch (value.status) {
                      case "under_review":
                        if (value.assigneeId && value.assigneeId === "!!!") {
                          navigate(
                            `${UNASSIGNED}?${encodeURIComponent(value.id)}`
                          );
                        } else {
                          if (
                            value.assigneeId &&
                            user &&
                            value.assigneeId === user.username
                          ) {
                            navigate(
                              `${MY_CLAIMS}?${encodeURIComponent(value.id)}`
                            );
                          } else if (
                            value.assigneeId &&
                            value.assigneeId !== "!!!"
                          ) {
                            navigate(
                              `${TEAM_CLAIMS}?${encodeURIComponent(value.id)}`
                            );
                          }
                        }

                        break;
                      case "on_hold":
                        navigate(`${ON_HOLD}?${encodeURIComponent(value.id)}`);
                        break;
                      case "approved":
                        navigate(`${APPROVED}?${encodeURIComponent(value.id)}`);
                        break;
                      case "rejected":
                        navigate(`${REJECTED}?${encodeURIComponent(value.id)}`);
                        break;
                      case "approved_and_paid":
                        navigate(`${PAID}?${encodeURIComponent(value.id)}`);
                        break;
                      default:
                        break;
                    }
                  }
                }}
                renderOption={(props, options, state) => (
                  <>
                    <Box component="li" {...props}>
                      {`${options.displayId} - ${options.doctor.name} -`}
                      <Chip
                        color={
                          options.status === "on_hold"
                            ? "warning"
                            : options.status === "rejected"
                              ? "accent"
                              : options.status === "approved"
                                ? "secondaryWhite"
                                : options.status === "approved_and_paid"
                                  ? "purple"
                                  : "primary"
                        }
                        sx={{
                          height: 30,
                          borderRadius: constants.chipRounding,
                          marginRight: 0.5,
                          marginLeft: 1,
                        }}
                        label={
                          options.status === "on_hold"
                            ? "On Hold"
                            : options.status === "rejected"
                              ? "Rejected"
                              : options.status === "approved"
                                ? "Approved"
                                : options.status === "approved_and_paid"
                                  ? "Paid"
                                  : "Open"
                        }
                      />
                    </Box>
                    <Divider />
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    sx={{
                      ".MuiOutlinedInput-root": {
                        backgroundColor: theme.palette.background.paper,
                      },
                    }}
                    InputLabelProps={{ shrink: false }}
                    style={{
                      width: 415,
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <>
                          <Icon>
                            <SearchRounded />
                          </Icon>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      endAdornment: (
                        <React.Fragment>
                          {!!searchString && searchString.length > 0 ? (
                            <IconButton
                              size="small"
                              onClick={() => {
                                setSearchString("");
                                setRowData([]);
                              }}
                            >
                              <CloseRounded fontSize="small" />
                            </IconButton>
                          ) : null}
                          {claimLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <div className={classes.grow} />
          {/*<Notifications />*/}
          <div className={classes.sectionDesktop}>
            {user?.attributes?.name && (
              <Typography
                data-test-id="user-loggedin"
                className={classes.title}
                variant="h6"
                noWrap
              >
                {`${user.attributes.name}`}
              </Typography>
            )}
            <MenuItem
              data-test-id="user-menu-button"
              onClick={handleProfileMenuOpen}
              classes={{
                root: clsx(classes.profileButton, !user && classes.loggedOut),
              }}
            >
              <MoreVertIcon />
            </MenuItem>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default React.memo(Appbar);
