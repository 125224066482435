import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
// Hooks
import ProDataGrid from "components/proDataGrid/ProDataGrid";
import { Card, Grid } from "@mui/material";
import { CLAIM_STATUS } from "common/constants/options";
import OnHoldDetails from "./OnHoldDetails";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import {
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "common/constants/common";
import {
  mapCSVData,
  mapTableData,
  tableColumnList,
} from "utils/commonFunctions";
import dayjs, { Dayjs } from "dayjs";
import {
  CcgUpdateClaimInput,
  Claim,
  ModelClaimFilterInputNew,
  OrgUserResponse,
  User,
} from "@s12solutions/types";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";
import {
  ColumnData,
  CSVData,
  CSVHeader,
  FeatureFlags,
  FilterData,
  TypeClaim,
} from "common/types/commonTypes";
import { DateRange } from "@mui/x-date-pickers-pro";
import { isEmpty } from "lodash";
import DialogList from "components/dialogList";
import { ModelListClaimUsersFilterInput } from "@s12solutions/types";
import { uniq } from "lodash";
import { AssignmentInd, AssignmentRounded } from "@mui/icons-material";
import { Methods } from "api";
import { LOADING_STATES } from "common/types/loading";
import ApprovedIcon from "@mui/icons-material/Check";
import UnassignedIcon from "@mui/icons-material/Inbox";
import { CLAIM_NOTES } from "common/constants/notes";
import { useLocation, useNavigate } from "react-router-dom";
import { useProDataGrid } from "components/proDataGrid/useProDataGrid";
import { ProDataGridProvider } from "components/proDataGrid/ProDataGridProvider";

const OnHoldWrapper = () => {
  return (
    <ProDataGridProvider>
      <OnHold />
    </ProDataGridProvider>
  )
}

const OnHold: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { loadingState, setLoadingState, finishLoading } = useLoading();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TypeClaim[]>([]);
  const [clickedRowId, setClickedRowId] = useState<string>("");
  const [searchData, setSearchData] = useState<FilterData | null>(null);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [ccgFilterData, setCcgFilterData] = useState<ColumnData | null>(null);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);
  const [csvData, setCsvData] = useState<[CSVHeader[], CSVData[]]>([[], []]);
  const [selectedCsvData, setSelectedCsvData] = useState<
    [CSVHeader[], CSVData[]]
  >([[], []]);
  const { resetSelected } = useProDataGrid();
  const { handleBannerMessage, handleConfirmation } = usePopups();
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [userData, setUserData] = useState<OrgUserResponse[] | undefined>(
    undefined
  );

  // Queries
  const {
    loading: usersLoading,
    error: usersError,
    trigger: getUsers,
  } = useAPI<
    OrgUserResponse[],
    {
      filter: ModelListClaimUsersFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaimUsers",
    manual: true,
  });

  const {
    data: onHoldClaimData,
    loading: onHoldClaimLoading,
    error: onHoldClaimError,
    trigger: getOnHoldClaims,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });
  const {
    data: dateRangeData,
    loading: dateRangeDataLoading,
    error: dateRangeDataError,
    trigger: getDateRangeData,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });

  const {
    data: ccgData,
    loading: ccgLoading,
    error: ccgError,
    trigger: getCcg,
  } = useAPI<
    OrgUserResponse[],
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUserOrgs",
    manual: true,
  });

  const {
    data: claimUserData,
    error: claimUserDataError,
    trigger: getUserData,
  } = useAPI<
    User,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUser",
    manual: true,
  });

  // Mutations
  const {
    loading: updateClaimLoading,
    error: updateClaimError,
    trigger: updateClaim,
  } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput;
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
    onCompleted() {
      setSelectedRowIds([]);
      resetSelected();
    }
  });

  const ccgList =
    ccgData?.map((item) => {
      return {
        key: item.id,
        value: item.name,
      };
    }) ?? [];

  const getClaims = useCallback(() => {
    return getOnHoldClaims({
      filter:
        searchData && ccgFilterData
          ? {
            status: CLAIM_STATUS.onHold,
            searchValue: searchData.searchString,
            claimOrganisationId: ccgFilterData.key,
          }
          : searchData
            ? {
              status: CLAIM_STATUS.onHold,
              searchValue: searchData.searchString,
            }
            : ccgFilterData
              ? {
                status: CLAIM_STATUS.onHold,
                claimOrganisationId: ccgFilterData.key,
              }
              : {
                status: CLAIM_STATUS.onHold,
              },
    });
  }, [ccgFilterData, getOnHoldClaims, searchData]);

  useEffect(() => {
    if (onHoldClaimData && !onHoldClaimLoading) {
      setRowData(onHoldClaimData?.map(mapTableData));
    } else {
      setRowData([]);
    }
    return () => {
      setRowData([]);
    };
  }, [onHoldClaimData, onHoldClaimLoading]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setClickedRowId(decodeURIComponent(location?.search.substring(1)));
      setShowDetails(true);
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: true },
      });
    }
  }, [location.pathname, location?.search, navigate]);

  const assign = useCallback(() => {
    if (!user) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.ASSIGN_CLAIMS.replace(
        "{count}",
        `${selectedRowIds.length === 1
          ? selectedRowIds.length + " claim"
          : selectedRowIds.length + " claims"
        }`
      ),
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.ASSIGN);
        updateClaim({
          input: {
            id: selectedRowIds,
            assigneeId: user.username,
            assigneeName: user.attributes.name,
            notes: `On Hold ${CLAIM_NOTES.CLAIM_ASSIGNED}@${user.attributes.name
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.underReview,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              getClaims();
              handleBannerMessage(TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
            } else {
              handleBannerMessage(
                TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
          })
          .finally(finishLoading);
      },
      "Assign Claim",
      selectedRowIds.length === 1 ? "ASSIGN CLAIM" : "ASSIGN CLAIMS",
      "CANCEL"
    );
  }, [
    finishLoading,
    getClaims,
    handleBannerMessage,
    handleConfirmation,
    selectedRowIds,
    setLoadingState,
    updateClaim,
    user,
  ]);

  const assignClaimToUser = useCallback(
    (
      userData: {
        id: string;
        name: string;
      } | null
    ) => {
      if (!userData) {
        return;
      }
      setRowData((data) =>
        data.filter((val) => !selectedRowIds.includes(val.id))
      );
      setLoadingState(LOADING_STATES.ASSIGN_TO_USER);
      updateClaim({
        input: {
          id: selectedRowIds,
          assigneeId: userData.id.toString(),
          assigneeName: userData.name,
          notes: `On Hold ${CLAIM_NOTES.CLAIM_ASSIGNED}@${userData.name ?? "N/A"
            }@${dayjs().format()}`,
          status: CLAIM_STATUS.underReview,
        },
      })
        .then((data) => {
          if (data && data.length > 0) {
            getClaims();
            handleBannerMessage(TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
          } else {
            handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
          }
        })
        .catch(() => {
          handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
        })
        .finally(finishLoading);
    },
    [
      finishLoading,
      getClaims,
      handleBannerMessage,
      selectedRowIds,
      setLoadingState,
      updateClaim,
    ]
  );

  // Get Unassigned Claim
  useEffect(() => {
    getClaims();
  }, [getClaims]);

  useEffect(() => {
    if (user) {
      getCcg({
        id: user.username,
      });
    }
  }, [getCcg, user]);

  useEffect(() => {
    if (dateRange) {
      getDateRangeData({
        filter: {
          status: CLAIM_STATUS.onHold,
          receivedDateFrom:
            dateRange && dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
          receivedDateTo:
            dateRange && dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
        },
      });
    }
  }, [dateRange, getDateRangeData]);

  const ccg = useMemo(() => ccgData ?? [], [ccgData]);
  const featuresFromAllOrgs = useMemo(
    () =>
      ccg
        .map(
          (o) =>
            JSON.parse(
              o?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
            ) as FeatureFlags
        )
        .reduce(
          (acc, curr) => ({
            ...acc,
            ...Object.entries(curr)
              .filter(([_, value]) => !!value)
              .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
          }),
          {} as FeatureFlags
        ),
    [ccg]
  );

  const currentOrg = useMemo(
    () =>
      ccgFilterData
        ? ccg.find((o) => o.id === ccgFilterData?.key)
        : ({} as OrgUserResponse),
    [ccg, ccgFilterData]
  );
  const featuresFromCurrentOrg = useMemo(
    () =>
      !isEmpty(
        JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags
      )
        ? (JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags)
        : featuresFromAllOrgs,
    [currentOrg?.featureFlags, featuresFromAllOrgs]
  );

  useEffect(() => {
    if (dateRangeData) {
      setCsvData(mapCSVData(dateRangeData));
    }
  }, [dateRangeData]);

  useEffect(() => {
    if (
      onHoldClaimError ||
      ccgError ||
      dateRangeDataError ||
      usersError ||
      updateClaimError ||
      claimUserDataError
    ) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    ccgError,
    claimUserDataError,
    dateRangeDataError,
    handleBannerMessage,
    onHoldClaimError,
    updateClaimError,
    usersError,
  ]);

  const setSearchDataValue = useCallback(
    (val: FilterData) => {
      setSearchData(val);
    },
    [setSearchData]
  );

  useEffect(() => {
    if (user) {
      getUserData({
        id: user.username,
      });
    }
  }, [getUserData, user]);

  const userPermissions = useMemo(
    () => ({
      claimApprover: claimUserData?.claimApprover ?? false,
      claimPayer: claimUserData?.claimPayer ?? false,
    }),
    [claimUserData]
  );

  const unAssign = useCallback(() => {
    if (!user) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.UNASSIGN_CLAIMS.replace(
        "{count}",
        `${selectedRowIds.length === 1
          ? selectedRowIds.length + " claim"
          : selectedRowIds.length + " claims"
        }`
      ),
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.UNASSIGN);
        updateClaim({
          input: {
            id: selectedRowIds,
            assigneeId: "!!!",
            assigneeName: "!!!",
            notes: `${CLAIM_NOTES.CLAIM_UNASSIGNED}@${user.attributes.name
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.underReview,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              getClaims();
              handleBannerMessage(TYPE_SUCCESS, MESSAGES.CLAIM_UNASSIGNED);
            } else {
              handleBannerMessage(
                TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
          })
          .finally(finishLoading);
      },
      "Unassign Claim",
      selectedRowIds.length === 1 ? "UNASSIGN CLAIM" : "UNASSIGN CLAIMS",
      "CANCEL"
    );
  }, [
    finishLoading,
    getClaims,
    handleBannerMessage,
    handleConfirmation,
    selectedRowIds,
    setLoadingState,
    updateClaim,
    user,
  ]);

  const approve = useCallback(() => {
    if (!user) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.APPROVE_CLAIMS,
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.APPROVE);
        updateClaim({
          input: {
            id: selectedRowIds,
            notes: `${CLAIM_NOTES.CLAIM_APPROVED}@${user.attributes.name ?? "N/A"
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.approved,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              handleBannerMessage(
                "success",
                `Claim/s approved by ${user.attributes.name}`
              );
              getClaims();
            } else {
              handleBannerMessage(
                TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
          })
          .finally(finishLoading);
      },
      selectedRowIds.length === 1 ? "Approve Claim" : "Approve Claims",
      selectedRowIds.length === 1 ? "APPROVE CLAIM" : "APPROVE CLAIMS",
      "CANCEL"
    );
  }, [
    user,
    handleConfirmation,
    selectedRowIds,
    handleBannerMessage,
    setLoadingState,
    updateClaim,
    finishLoading,
    getClaims,
  ]);

  return (
    <>
      {showDetails ? (
        <OnHoldDetails
          rowId={clickedRowId}
          goBack={(from: "claimUpdating" | "backButton" | "sideMenuClick") => {
            switch (from) {
              case "sideMenuClick":
              case "backButton":
              case "claimUpdating":
                getClaims();
                break;
              default:
                break;
            }

            setShowDetails(false);
          }}
          ccgId={rowData.find((val) => val.id === clickedRowId)?.ccgId}
        />
      ) : (
        <>
          <DialogList
            openDialog={showUsers}
            closeDialog={() => {
              setShowUsers(false);
            }}
            selectedRowIds={selectedRowIds.length}
            listData={userData}
            handleUser={(user) => {
              setShowUsers(false);
              assignClaimToUser(user);
            }}
            loading={updateClaimLoading}
            loadingUser={usersLoading}
          />
          <Grid
            m={1}
            p={2}
            component={Card}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: 1,
              minHeight: window.innerHeight - 85,
            }}
          >
            <Grid container m={0}>
              <Grid container spacing={3} sx={{ height: "fit-content" }}>
                {/* {assignClaimsLoading && <LoadingCircle />} */}
                <Grid item xs={12}>
                  <ProDataGrid
                    title="On Hold Claims"
                    rows={rowData}
                    columns={tableColumnList(
                      featuresFromAllOrgs,
                      featuresFromCurrentOrg,
                      ccgFilterData ? ccgFilterData.key : undefined
                    )}
                    ccgList={ccgList}
                    filterData={searchData}
                    filterCcgData={ccgFilterData}
                    dataError={!onHoldClaimData && !onHoldClaimLoading}
                    csvData={csvData}
                    selectedCsvData={selectedCsvData}
                    dateRangeLoading={dateRangeDataLoading}
                    button={{
                      unassignClaims: {
                        id: "un_assign",
                        icon: <UnassignedIcon />,
                        loading: loadingState === LOADING_STATES.UNASSIGN,
                      },
                      assignMe: {
                        id: "assign_to_me",
                        icon: <AssignmentRounded />,
                        loading: loadingState === LOADING_STATES.ASSIGN,
                      },
                      assignClaims: {
                        id: "assign_claim",
                        icon: <AssignmentInd />,
                        loading:
                          usersLoading ||
                          loadingState === LOADING_STATES.ASSIGN_TO_USER,
                      },
                      ...(userPermissions.claimApprover && {
                        approve: {
                          id: "approve",
                          icon: <ApprovedIcon />,
                          loading: loadingState === LOADING_STATES.APPROVE,
                        },
                      }),
                    }}
                    buttonId={(val,) => {
                      switch (val) {
                        case "approve":
                          !!userPermissions.claimApprover && approve();
                          break;
                        case "un_assign":
                          unAssign();
                          break;
                        case "assign_to_me":
                          assign();
                          break;
                        case "assign_claim":
                          if (selectedRowIds.length > 0 && user) {
                            getUsers({
                              filter: {
                                loggedInUserId: user.username,
                                selectedClaimOrganisations: uniq(
                                  rowData
                                    .filter((val) =>
                                      selectedRowIds.some((v) => v === val.id)
                                    )
                                    .map((val) => val.ccgId)
                                ),
                              },
                            })
                              .then((data) => {
                                setShowUsers(true);
                                setUserData(data);
                              })
                              .catch(() => {
                                handleBannerMessage(
                                  TYPE_ERROR,
                                  MESSAGES.UNEXPECTED_ERROR_MESSAGE
                                );
                              })
                          }
                          break;
                        default:
                          break;
                      }
                    }}
                    dateRange={(val) => {
                      setDateRange(val);
                    }}
                    loading={onHoldClaimLoading}
                    ccgLoading={ccgLoading}
                    refetchData={() => {
                      setSearchData(null);
                    }}
                    debouncedSearchString={setSearchDataValue}
                    ccgValue={(val) => {
                      setCcgFilterData(val);
                    }}
                    selectedRows={(rowIds: string[]) => {
                      setSelectedRowIds(rowIds);
                      if (onHoldClaimData) {
                        setSelectedCsvData(mapCSVData(onHoldClaimData, rowIds));
                      }
                    }}
                    onClickRowData={(event) => {
                      setClickedRowId(event.id);
                      setShowDetails(true);
                      navigate(location.pathname, {
                        state: { isSameMenu: false, isInDetailsPage: true },
                      });
                    }}
                    endpointsLoading={loadingState}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default memo(OnHoldWrapper);
