export const white = "#FFFFFF";
export const darkOrange = "#c95432";
export const midOrange = "#f0633a";
export const lightOrange = "#FCD3C7";
export const s12Blue = "#0072CE"; // The S12 blue colour for use in menu bars

export const primaryMain = s12Blue; // The S12 blue colour by default
export const primaryLight = "#EEF6FE";
export const primaryMid = "#A7C6ED";
export const primaryDark = "#0050AD"; // Darker S12 blue

export const secondaryMain = "#2AD2C9";
export const secondaryLight = "#BBE3E2";
export const secondaryMid = "#78E4D6";
export const secondaryDark = "#65CFC8";
export const alternateDarkMode = "#475157";

export const shadePurple = "#9B7DD4";
export const shadeGrey1 = "#D9EAF8";
export const shadeGrey2 = "#CFD3E4";
export const shadeGrey3 = "#DAE1E9";

export const mainAccent = "#FF5C39";
export const warning = "#D32F2F";
export const InfoPositive = "#4CAF50";
export const actionNotice = "#FFC600";

export const formStandardPink = "#fbebf0"; // Standard pink associated with forms
export const darkPink = "hsl(342, 97%, 70%)";
export const midPink = "hsl(342, 97%, 79%)";
export const lightPink = "hsl(342, 97%, 86%)";

export const darkRed = "hsl(3, 55%, 49%)";
export const midRed = "hsl(3, 55%, 60%)";
export const lightRed = "hsl(3, 55%, 75%)";
export const red = "#d32f2f";

export const darkGreen = "#4CAF50";

export const backgroundGrey = "hsl(100, 14%, 96%)"; // Controls the background colour
export const lightGrey = "#f5f5f5"; // General light grey for comments etc
export const baseGrey = "#6e6e6e"; // Base grey for text and icons
export const darkBaseGrey = "#545454"; // Darker base grey for selected / hover effect
export const lightBaseGrey = "#ddd"; // Light base grey for structural lines etc
export const midBaseGrey = "#858585";
export const brightGreen = "#78e4d6";

export const outputFieldGrey = "hsl(226, 87%, 97%)";

export const rounding = ".3rem";
export const chipRounding = "1rem";
export const ButtonSpacing = 2;

export const aqua = "#2AD2C9"; // brand color
export const textLabel = "#647692"; // Text label color
export const grey = "#EAECF2";
export const blue = "#0072CE";

export const successGreen = "#2E7D32";
export const infoBlue = "#0288D1";
