import React, { useState } from "react";

// Hooks
import { usePopups } from "hooks";

// Material UI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as constants from "common/constants/theme";
import { StyledInput } from "components/uiElements";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";
import { s12Blue } from "common/constants/theme";
import { CSVHeader, CSVData } from "common/types/commonTypes";

interface ConfirmationDialogProps {
  open: boolean;
  message: string;
  title?: string;
  yesLabel?: string;
  noLabel?: string;
  closeConfirmation: () => void;
  successCallback: (reason?: string) => void;
  withReason?: boolean;
  selectedCsvData?: [CSVHeader[], CSVData[]];
}

// Extracted for ease of testing
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  message,
  title = "Confirm",
  closeConfirmation,
  successCallback,
  yesLabel = "Yes",
  noLabel = "No",
  withReason = false,
  selectedCsvData,
}) => {
  const [reason, setReason] = useState("");

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: 8,
            minHeight: 220,
            width: window.innerWidth / 3,
          },
        }}
        onBackdropClick={closeConfirmation}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            closeConfirmation();
          }
        }}
      >
        <DialogTitle data-test-id="title">
          {title === "Warning!" ? (
            <Typography
              component="div"
              variant="h1"
              sx={{ fontWeight: "bold", color: constants.darkRed }}
            >
              {title}
            </Typography>
          ) : (
            <Typography
              component="div"
              variant="h1"
              sx={{ fontWeight: "bold" }}
            >
              {title}
            </Typography>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Typography
            sx={{
              paddingRight: 1,
              paddingLeft: 1,
              fontSize: 20,
              whiteSpace: "pre-wrap",
            }}
          >
            {message}
          </Typography>
          {withReason && (
            <StyledInput
              id="reason"
              label="Reason"
              sx={{
                marginTop: 3,
              }}
              multiline
              minRows={3}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions
          sx={{ paddingRight: 2, paddingBottom: 1.5, paddingTop: 1.5 }}
        >
          <Button
            data-test-id="cancel-button"
            autoFocus
            onClick={closeConfirmation}
            variant={
              title === "Warning!" || title === "Confirm"
                ? "contained"
                : "outlined"
            }
          >
            {noLabel}
          </Button>
          {!selectedCsvData && (
            <Button
              data-test-id="confirm-button"
              disabled={withReason && reason.trim().length < 3}
              onClick={() => successCallback(reason)}
              variant={
                title === "Warning!" || title === "Confirm"
                  ? "outlined"
                  : "contained"
              }
            >
              {yesLabel}
            </Button>
          )}
          {selectedCsvData && (
            <Button
              data-test-id="confirm-button"
              disabled={withReason && reason.trim().length < 3}
              onClick={() => {
                successCallback(reason);
                closeConfirmation();
              }}
              variant={
                title === "Warning!" || title === "Confirm"
                  ? "outlined"
                  : "contained"
              }
            >
              <CSVLink
                color="primary"
                data={selectedCsvData[1] ? selectedCsvData[1] : []}
                headers={selectedCsvData[0] ? selectedCsvData[0] : []}
                filename={`${dayjs()
                  .subtract(1, "day")
                  ?.format("YYYY-MM-DD")} to ${dayjs()?.format(
                  "YYYY-MM-DD"
                )}.csv`}
                style={{
                  color: s12Blue,
                  textDecoration: "none",
                }}
              >
                {yesLabel}
              </CSVLink>
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const Confirmation: React.FC = () => {
  const {
    confirmation: {
      message,
      successCallback,
      title,
      yesLabel,
      noLabel,
      withReason,
    },
    closeConfirmation,
  } = usePopups();
  const isOpen = message && successCallback ? true : false;

  if (!isOpen) return <></>;

  return (
    <ConfirmationDialog
      open={isOpen}
      message={message}
      title={title}
      yesLabel={yesLabel}
      noLabel={noLabel}
      closeConfirmation={closeConfirmation}
      successCallback={successCallback}
      withReason={withReason}
    />
  );
};

export default React.memo(Confirmation);
