import { ClaimStatus } from "@s12solutions/types";

interface IClaimStatus {
  underReview: ClaimStatus;
  approved: ClaimStatus;
  paid: ClaimStatus;
  onHold: ClaimStatus;
  rejected: ClaimStatus;
}

export const CLAIM_STATUS: IClaimStatus = {
  underReview: "under_review",
  approved: "approved",
  paid: "approved_and_paid",
  onHold: "on_hold",
  rejected: "rejected",
};
