import React from "react";
import { Card, Divider, Grid, Typography as Text } from "@mui/material";

const SupportExplained = () => {
  return (
    <>
      <Grid
        m={1}
        p={2}
        component={Card}
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Text mb={5} variant="h1">
          Support Explained
        </Text>

        <Text variant="body1" mb={3}>
          Target resolution time of 4 hours for critical issues, 24 hours for
          major issues, 72 hours for minor issues.
        </Text>
        <Text variant="body1" mb={3}>
          Critical issues prevent access to important workflows and data. Major
          issues prevent access to secondary data and workflows (reporting,
          onboarding).
        </Text>
        <Text variant="body1" mb={3}>
          Minor issues prevent access, but workarounds exist or the issue affect
          a subset of non-critical users (device specific issues, issues that
          can be resolved with administrator intervention, usability
          annoyances).
        </Text>

        <Divider />

        <Text mt={3} mb={3} variant="h1">
          Support Hours
        </Text>
        <Text mt={3} variant="body1">
          Monday-Friday - 8.30am-5.30pm
        </Text>
        <Text mt={3} variant="body1">
          (Excluding Bank Holidays).
        </Text>
      </Grid>
    </>
  );
};

export { SupportExplained };
