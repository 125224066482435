import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
// Hooks
import ProDataGrid from "components/proDataGrid/ProDataGrid";
import { Card, Grid } from "@mui/material";
import { CLAIM_STATUS } from "common/constants/options";
import TeamClaimsDetails from "./TeamClaimsDetails";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import dayjs, { Dayjs } from "dayjs";
import { isEmpty } from "lodash";
import UnassignedIcon from "@mui/icons-material/Inbox";
import {
  mapCSVData,
  mapTableData,
  tableColumnList,
} from "utils/commonFunctions";
import {
  CcgUpdateClaimInput,
  Claim,
  ModelClaimFilterInputNew,
  ModelListClaimUsersFilterInput,
  OrgUserResponse,
} from "@s12solutions/types";
import {
  ColumnData,
  CSVData,
  CSVHeader,
  FeatureFlags,
  FilterData,
  TypeClaim,
} from "common/types/commonTypes";
import { LOADING_STATES } from "common/types/loading";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";
import { DateRange } from "@mui/x-date-pickers-pro";
import { AssignmentInd, AssignmentRounded } from "@mui/icons-material";
import DialogList from "components/dialogList";
import { uniq } from "lodash";
import { Methods } from "api";
import { useLocation, useNavigate } from "react-router-dom";
import { CLAIM_NOTES } from "common/constants/notes";
import { useProDataGrid } from "components/proDataGrid/useProDataGrid";
import { ProDataGridProvider } from "components/proDataGrid/ProDataGridProvider";

const TeamClaimsWrapper = () => {
  return (
    <ProDataGridProvider>
      <TeamClaims />
    </ProDataGridProvider>
  )
}

const TeamClaims: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { loadingState, setLoadingState, finishLoading } = useLoading();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TypeClaim[]>([]);
  const [clickedRowId, setClickedRowId] = useState<string>("");
  const [searchData, setSearchData] = useState<FilterData | null>(null);
  const [ccgFilterData, setCcgFilterData] = useState<ColumnData | null>(null);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [assigneeFilterData, setAssigneeFilterData] =
    useState<ColumnData | null>(null);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);
  const [csvData, setCsvData] = useState<[CSVHeader[], CSVData[]]>([[], []]);
  const [userData, setUserData] = useState<OrgUserResponse[] | undefined>(
    undefined
  );
  const [selectedCsvData, setSelectedCsvData] = useState<
    [CSVHeader[], CSVData[]]
  >([[], []]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const { resetSelected } = useProDataGrid();

  // Queries
  const {
    data: teamClaimData,
    loading: teamClaimLoading,
    error: teamClaimError,
    trigger: getTeamClaim,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });

  const {
    data: dateRangeData,
    loading: dateRangeDataLoading,
    error: dateRangeDataError,
    trigger: getDateRangeData,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
    args: {
      filter: {
        status: CLAIM_STATUS.underReview,
        assigneeId: { ne: "!!!" },
        receivedDateFrom:
          dateRange && dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
        receivedDateTo:
          dateRange && dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
      },
    },
  });

  const {
    data: assigneeListData,
    loading: assigneeListLoading,
    error: assigneeListError,
    trigger: listClaimUsers,
  } = useAPI<
    OrgUserResponse[],
    {
      filter: ModelListClaimUsersFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaimUsers",
    manual: true,
  });

  const {
    loading: assigneeListToAssignLoading,
    error: assigneeListToAssignError,
    trigger: listClaimUsersToAssign,
  } = useAPI<
    OrgUserResponse[],
    {
      filter: ModelListClaimUsersFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaimUsers",
    manual: true,
  });

  const {
    data: ccgData,
    loading: ccgLoading,
    error: ccgError,
    trigger: getCcg,
  } = useAPI<
    OrgUserResponse[],
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUserOrgs",
    manual: true,
  });

  const {
    loading: updateClaimLoading,
    error: updateClaimError,
    trigger: updateClaim,
  } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput;
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
    onCompleted() {
      setSelectedRowIds([]);
      resetSelected();
    }
  });

  const ccgList = useMemo(
    () =>
      ccgData?.map((item) => {
        return {
          key: item.id,
          value: item.name,
        };
      }) ?? [],
    [ccgData]
  );

  // Get the users that we can assign claims to
  const assigneeList = useMemo(
    () =>
      user
        ? assigneeListData
          ?.map((item) => {
            return {
              key: item.id,
              value: item.name ?? "",
            };
          })
          .concat([
            {
              key: user.username,
              value: user.attributes.name,
            },
          ])
        : [],
    [assigneeListData, user]
  );

  const getClaims = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    return getTeamClaim({
      filter:
        searchData && ccgFilterData && assigneeFilterData
          ? {
            status: CLAIM_STATUS.underReview,
            assigneeId: { eq: assigneeFilterData.key },
            searchValue: searchData.searchString,
            claimOrganisationId: ccgFilterData.key,
          }
          : searchData && ccgFilterData
            ? {
              status: CLAIM_STATUS.underReview,
              assigneeId: { ne: "!!!" },
              searchValue: searchData.searchString,
              claimOrganisationId: ccgFilterData.key,
            }
            : searchData && assigneeFilterData
              ? {
                status: CLAIM_STATUS.underReview,
                assigneeId: { eq: assigneeFilterData.key },
                searchValue: searchData.searchString,
              }
              : assigneeFilterData && ccgFilterData
                ? {
                  status: CLAIM_STATUS.underReview,
                  assigneeId: { eq: assigneeFilterData.key },
                  claimOrganisationId: ccgFilterData.key,
                }
                : searchData
                  ? {
                    status: CLAIM_STATUS.underReview,
                    assigneeId: { ne: "!!!" },
                    searchValue: searchData.searchString,
                  }
                  : ccgFilterData
                    ? {
                      status: CLAIM_STATUS.underReview,
                      assigneeId: { ne: "!!!" },
                      claimOrganisationId: ccgFilterData.key,
                    }
                    : assigneeFilterData
                      ? {
                        status: CLAIM_STATUS.underReview,
                        assigneeId: { eq: assigneeFilterData.key },
                      }
                      : {
                        status: CLAIM_STATUS.underReview,
                        assigneeId: { ne: "!!!" },
                      },
    });
  }, [
    user,
    getTeamClaim,
    searchData,
    ccgFilterData,
    assigneeFilterData,
    handleBannerMessage,
  ]);

  const unAssign = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.UNASSIGN_CLAIMS.replace(
        "{count}",
        `${selectedRowIds.length === 1
          ? selectedRowIds.length + " claim"
          : selectedRowIds.length + " claims"
        }`
      ),
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.UNASSIGN);
        updateClaim({
          input: {
            id: selectedRowIds,
            assigneeId: "!!!",
            assigneeName: "!!!",
            notes: `${CLAIM_NOTES.CLAIM_UNASSIGNED}@${user.attributes.name
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.underReview,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              getClaims();
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.CLAIM_UNASSIGNED
              );
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .finally(finishLoading);
      },
      "Unassign Claim",
      selectedRowIds.length === 1 ? "UNASSIGN CLAIM" : "UNASSIGN CLAIMS",
      "CANCEL"
    );
  }, [
    finishLoading,
    getClaims,
    handleBannerMessage,
    handleConfirmation,
    selectedRowIds,
    setLoadingState,
    updateClaim,
    user,
  ]);

  const assign = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.ASSIGN_CLAIMS.replace(
        "{count}",
        `${selectedRowIds.length === 1
          ? selectedRowIds.length + " claim"
          : selectedRowIds.length + " claims"
        }`
      ),
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.ASSIGN);
        updateClaim({
          input: {
            id: selectedRowIds,
            assigneeId: user.username,
            assigneeName: user.attributes.name,
            notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${user.attributes.name
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.underReview,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              getClaims();
              handleBannerMessage(COMMON.TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .finally(finishLoading);
      },
      "Assign Claim",
      selectedRowIds.length === 1 ? "ASSIGN CLAIM" : "ASSIGN CLAIMS",
      "CANCEL"
    );
  }, [
    user,
    handleConfirmation,
    selectedRowIds,
    handleBannerMessage,
    setLoadingState,
    updateClaim,
    finishLoading,
    getClaims,
  ]);

  useEffect(() => {
    if (teamClaimData && !teamClaimLoading) {
      setRowData(teamClaimData?.map(mapTableData));
    } else {
      setRowData([]);
    }

    return () => {
      setRowData([]);
    };
  }, [teamClaimData, teamClaimLoading]);

  useEffect(() => {
    getClaims();
  }, [getClaims]);

  useEffect(() => {
    if (user) {
      listClaimUsers({
        filter: {
          loggedInUserId: user.username,
          selectedClaimOrganisations: [],
        },
      });
    }
  }, [listClaimUsers, user]);

  useEffect(() => {
    if (user) {
      getCcg({
        id: user.username,
      });
    }
  }, [getCcg, user]);

  useEffect(() => {
    if (dateRange) {
      getDateRangeData();
    }
  }, [dateRange, getDateRangeData]);

  useEffect(() => {
    if (
      teamClaimError ||
      ccgError ||
      dateRangeDataError ||
      assigneeListError ||
      assigneeListToAssignError ||
      updateClaimError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    assigneeListError,
    assigneeListToAssignError,
    ccgError,
    dateRangeDataError,
    handleBannerMessage,
    teamClaimError,
    updateClaimError,
  ]);

  const ccg = useMemo(() => ccgData ?? [], [ccgData]);
  const featuresFromAllOrgs = useMemo(
    () =>
      ccg
        .map(
          (o) =>
            JSON.parse(
              o?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
            ) as FeatureFlags
        )
        .reduce(
          (acc, curr) => ({
            ...acc,
            ...Object.entries(curr)
              .filter(([_, value]) => !!value)
              .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
          }),
          {} as FeatureFlags
        ),
    [ccg]
  );

  const currentOrg = useMemo(
    () =>
      ccgFilterData
        ? ccg.find((o) => o.id === ccgFilterData?.key)
        : ({} as OrgUserResponse),
    [ccg, ccgFilterData]
  );
  const featuresFromCurrentOrg = useMemo(
    () =>
      !isEmpty(
        JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags
      )
        ? (JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags)
        : featuresFromAllOrgs,
    [currentOrg?.featureFlags, featuresFromAllOrgs]
  );

  useEffect(() => {
    if (dateRangeData) {
      setCsvData(mapCSVData(dateRangeData));
    }
  }, [dateRangeData]);

  const assignClaimToUser = useCallback(
    (
      userData: {
        id: string;
        name: string;
      } | null
    ) => {
      if (!userData) {
        return;
      }
      setRowData((data) =>
        data.filter((val) => !selectedRowIds.includes(val.id))
      );
      setLoadingState(LOADING_STATES.ASSIGN_TO_USER);
      updateClaim({
        input: {
          id: selectedRowIds,
          assigneeId: userData.id.toString(),
          assigneeName: userData.name,
          notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${userData.name ?? "N/A"
            }@${dayjs().format()}`,
          status: CLAIM_STATUS.underReview,
        },
      })
        .then((data) => {
          if (data && data.length > 0) {
            getClaims();
            handleBannerMessage(COMMON.TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
          } else {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        })
        .finally(finishLoading);
    },
    [
      setLoadingState,
      updateClaim,
      selectedRowIds,
      finishLoading,
      handleBannerMessage,
      getClaims,
    ]
  );

  const setSearchDataValue = useCallback(
    (val: FilterData) => {
      setSearchData(val);
    },
    [setSearchData]
  );

  useEffect(() => {
    if (location?.search.length > 0) {
      setClickedRowId(decodeURIComponent(location?.search.substring(1)));
      setShowDetails(true);
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: true },
      });
    }
  }, [location.pathname, location?.search, navigate]);

  return (
    <>
      {showDetails && (
        <TeamClaimsDetails
          rowId={clickedRowId}
          goBack={(from: "claimUpdating" | "backButton" | "sideMenuClick") => {
            switch (from) {
              case "sideMenuClick":
              case "backButton":
              case "claimUpdating":
                getClaims();
                break;
              default:
                break;
            }

            setShowDetails(false);
          }}
          ccgId={rowData.find((val) => val.id === clickedRowId)?.ccgId}
        />
      )}
      {!showDetails && (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <DialogList
            openDialog={showUsers}
            closeDialog={() => {
              setShowUsers(false);
            }}
            selectedRowIds={selectedRowIds.length}
            listData={userData}
            handleUser={(user) => {
              setShowUsers(false);
              assignClaimToUser(user);
            }}
            loading={updateClaimLoading}
            loadingUser={assigneeListToAssignLoading}
          />
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <ProDataGrid
                  title="Team Claims"
                  rows={rowData}
                  columns={tableColumnList(
                    featuresFromAllOrgs,
                    featuresFromCurrentOrg,
                    ccgFilterData ? ccgFilterData?.key : undefined
                  )}
                  ccgList={ccgList}
                  isAssigneeList
                  assigneeList={assigneeList}
                  dataError={!teamClaimData && !teamClaimLoading}
                  assigneeListLoading={assigneeListLoading}
                  filterData={searchData}
                  filterCcgData={ccgFilterData}
                  filterAssigneeData={assigneeFilterData}
                  csvData={csvData}
                  selectedCsvData={selectedCsvData}
                  dateRangeLoading={dateRangeDataLoading}
                  button={{
                    assignClaims: {
                      id: "assign_claim",
                      icon: <AssignmentInd />,
                      loading:
                        assigneeListToAssignLoading ||
                        loadingState === LOADING_STATES.ASSIGN_TO_USER,
                    },
                    assignMe: {
                      id: "assign_to_me",
                      icon: <AssignmentRounded />,
                      loading: loadingState === LOADING_STATES.ASSIGN,
                    },

                    unassignClaims: {
                      id: "un_assign",
                      icon: <UnassignedIcon />,
                      loading: loadingState === LOADING_STATES.UNASSIGN,
                    },
                  }}
                  buttonId={(val) => {
                    switch (val) {
                      case "assign_to_me":
                        assign();
                        break;
                      case "un_assign":
                        unAssign();
                        break;
                      case "assign_claim":
                        if (selectedRowIds.length > 0 && user) {
                          listClaimUsersToAssign({
                            filter: {
                              loggedInUserId: user.username,
                              selectedClaimOrganisations: uniq(
                                rowData
                                  .filter((val) =>
                                    selectedRowIds.some((v) => v === val.id)
                                  )
                                  .map((val) => val.ccgId)
                              ),
                            },
                          })
                            .then((data) => {
                              setShowUsers(true);
                              setUserData(data);
                            })
                            .catch(() => {
                              handleBannerMessage(
                                COMMON.TYPE_ERROR,
                                MESSAGES.UNEXPECTED_ERROR_MESSAGE
                              );
                            });
                        }
                        break;
                      default:
                        break;
                    }
                  }}
                  dateRange={(val) => {
                    setDateRange(val);
                  }}
                  loading={teamClaimLoading}
                  ccgLoading={ccgLoading}
                  refetchData={() => {
                    setSearchData(null);
                  }}
                  debouncedSearchString={setSearchDataValue}
                  ccgValue={(val) => {
                    setCcgFilterData(val);
                  }}
                  assigneeValue={(val) => {
                    setAssigneeFilterData(val);
                  }}
                  selectedRows={(rowIds) => {
                    setSelectedRowIds(rowIds);
                    if (teamClaimData) {
                      setSelectedCsvData(mapCSVData(teamClaimData, rowIds));
                    }
                  }}
                  onClickRowData={(event) => {
                    setClickedRowId(event.id);
                    setShowDetails(true);
                    navigate(location.pathname, {
                      state: { isSameMenu: false, isInDetailsPage: true },
                    });
                  }}
                  endpointsLoading={loadingState}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(TeamClaimsWrapper);
