import { FC, memo, useEffect, useState } from "react";
import HeaderCard from "components/detailsPage/HeaderCard";
import AssessmentCard from "components/detailsPage/AssessmentCard";
import ContactCard from "components/detailsPage/ContactCard";
import HistoryCard from "components/detailsPage/HistoryCard";
import { useAPI, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import { CLAIM_STATUS } from "common/constants/options";
import LoadingCircle from "components/loadingCircle";
import { TYPE_ERROR } from "common/constants/common";
import { Claim } from "@s12solutions/types";
import { Grid, Card, Typography } from "@mui/material";
import { Methods } from "api";
import { useLocation, useNavigate } from "react-router-dom";

interface RejectedDetailsProps {
  goBack?: () => void;
  rowId: string;
}

const RejectedDetails: FC<RejectedDetailsProps> = ({ goBack, rowId }) => {
  const { handleBannerMessage } = usePopups();
  const [data, setData] = useState<Claim | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  // Queries
  const {
    data: rejectedClaimDetailsData,
    loading: rejectedClaimDetailsLoading,
    error: rejectedClaimDetailsError,
  } = useAPI<
    Claim,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaim",
    args: {
      id: rowId,
    },
  });

  useEffect(() => {
    if (
      rejectedClaimDetailsData &&
      rejectedClaimDetailsData.status === "rejected"
    ) {
      setData(rejectedClaimDetailsData);
    }
  }, [rejectedClaimDetailsData]);

  useEffect(() => {
    if (rejectedClaimDetailsError) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [handleBannerMessage, rejectedClaimDetailsError]);

  useEffect(() => {
    if (location?.state?.isSameMenu && !!goBack) {
      goBack();
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: false },
      });
    }
  }, [goBack, location.pathname, location?.state?.isSameMenu, navigate]);

  return (
    <>
      {rejectedClaimDetailsLoading ? (
        <LoadingCircle />
      ) : data ? (
        <>
          <HeaderCard
            status={data?.status === CLAIM_STATUS.rejected ? "REJECTED" : ""}
            assigneeName={data?.assigneeName ?? ""}
            goBack={goBack}
            buttons={[]}
            onClick={() => {}}
            claimId={data?.displayId}
          />
          <AssessmentCard claim={data} />
          <ContactCard docData={data?.doctor} amhpData={data?.amhp} />
          <HistoryCard data={data} />
        </>
      ) : (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <Typography variant="h1">
                  Unexpected error occurred when fetching the claim data
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(RejectedDetails);
