import { Claim } from "@s12solutions/types";

export const calculateInvoiceTotals = (claim: Claim, allowedVat: boolean) => {
  const subtotal =
    Number(claim.invoiceData?.assessmentFee ?? 0) +
    Number(claim.invoiceData?.travelFee ?? 0) +
    Number(claim.additionalExpenses ?? 0);
  const vat = allowedVat ? subtotal * 0.2 : 0;
  const totalExcVat = subtotal;
  const totalIncVat = totalExcVat + vat;

  return {
    subtotal: subtotal?.toFixed(2),
    vat: vat?.toFixed(2),
    totalExcVat: totalExcVat?.toFixed(2),
    totalIncVat: totalIncVat?.toFixed(2),
  };
};
