import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Select,
  SelectProps,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  select: {
    width: "100%",
    borderRadius: ".5rem",
    backgroundColor: theme.palette.common.white,
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.warning.dark,
    fontWeight: "bold",
  },
  helperText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

export interface StyledSelectProps {
  testId?: string;
  variant?: SelectProps["variant"];
  disabled?: boolean;
  required?: boolean;
  value: number | string | null;
  label: string;
  onChange: ((event: SelectChangeEvent<unknown>, child: React.ReactNode) => void) | undefined;
  errorText?: string;
  menuItems: { key: number | string; value: string }[];
  placeholder?: string;
  helperText?: string;
  getKeyFromValue?: boolean;
  autoFocus?: boolean;
}

export const StyledSelect: React.FC<StyledSelectProps & SelectProps> = (
  {
    testId,
    variant = "outlined",
    disabled,
    value,
    label,
    onChange,
    error,
    errorText,
    menuItems,
    placeholder,
    required = false,
    helperText,
    getKeyFromValue = false,
    autoFocus = false,
  },
  { ...rest }
) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <InputLabel shrink required={required} id={label}>
        {label}
      </InputLabel>
      <Select
        tabIndex={-1}
        data-test-id={testId}
        color="primary"
        disabled={disabled}
        className={classes.select}
        autoComplete="off"
        value={value}
        onChange={onChange}
        labelId={label}
        label={label}
        variant={variant}
        placeholder={placeholder}
        notched
        InputLabel
        required={required}
        autoFocus={autoFocus}
        {...rest}
      >
        {menuItems?.map((item, index) => (
          <MenuItem
            key={index}
            value={getKeyFromValue ? item.key : item.value}
            disabled={item.key === "##"}
          >
            {item.value}
          </MenuItem>
        ))}
      </Select>
      {error && errorText && (
        <Typography variant="h5" className={classes.errorText}>
          {errorText}
        </Typography>
      )}
      {helperText && (
        <Typography variant="h5" className={classes.helperText}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};
