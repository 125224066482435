import React, { useState, useCallback, useEffect } from "react";

// Material UI
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

// Custom
//import { primaryMain } from "common/constants/theme";
import { useStyles } from "./styles";
import config from "./config";
import { useLocation } from "react-router-dom";
import { HOME } from "layout/routes/RouteConstants";
import {
  Collapse,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigateRef } from "hooks/useNavigateRef";

const SideMenu: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigateRef();
  const location = useLocation();

  const [menuName, setMenuName] = useState("Home");
  const [selectedMenu, setSelectedMenu] = useState("Home");
  const handleToggleOpen = useCallback((e: string) => {
    setMenuName(e);
  }, []);

  // Disabled to allow navigation from a page via url or in-page button
  useEffect(() => {
    if (
      location?.pathname.includes("/signin") ||
      location?.pathname.toString() === "/"
    ) {
      setMenuName("Home");
      navigate.current(HOME);
    }
  }, [location, navigate]);

  useEffect(() => {
    let selectedItem = config?.find((i) => {
      return location.pathname.toString().includes(i.href);
    });
    if (!selectedItem) {
      let selectedMenu = config?.find((i) => {
        return location.pathname.toString().includes(i.href);
      });
      setSelectedMenu(selectedMenu?.name ?? "Home");
      setMenuName(selectedMenu?.name ?? "Home");
      return;
    }
    setSelectedMenu(selectedItem?.name ?? "Home");
    setMenuName(selectedItem?.name ?? "Home");
  }, [location.pathname, selectedMenu]);

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={true}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
      >
        <div data-test-id="side-menu">
          <div className={classes.drawerHeader}></div>
          <Divider />
          <List>
            {config?.map((data) => {
              return (
                <React.Fragment key={data.testId}>
                  <ListItemButton
                    data-test-id={data.testId}
                    onClick={() => {
                      handleToggleOpen(data.name);
                      if (
                        window.location.pathname.includes(data.href) &&
                        location.state && location.state.isInDetailsPage
                      ) {
                        navigate.current(data.href, {
                          state: {
                            isSameMenu: true,
                            isInDetailsPage: true,
                          },
                        });
                      } else {
                        navigate.current(data.href);
                      }
                    }}
                    component={Link}
                    selected={menuName === data.name}
                    mb={1}
                  >
                    <ListItemIcon>{data.icon}</ListItemIcon>
                    <ListItemText
                      sx={
                        menuName === data.name
                          ? {
                            "& .MuiListItemText-secondary": {
                              fontWeight: "bold",
                            },
                          }
                          : {}
                      }
                      secondary={data.name}
                    />
                    {data.items ? (
                      <>
                        {menuName === data.name ||
                          selectedMenu === data.name ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </ListItemButton>
                </React.Fragment>
              );
            })}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default React.memo(SideMenu);
