import {
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography as Text,
} from "@mui/material";
import { OrgUserResponse } from "@s12solutions/types";
import { Methods } from "api";
import { TYPE_ERROR } from "common/constants/common";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { useAPI, usePopups } from "hooks";
import { useEffect } from "react";

const ClaimExplained = () => {
  const { data, error, loading } = useAPI<OrgUserResponse[]>({
    method: Methods.GET,
    fieldName: "listActiveOrgs",
  });

  const { handleBannerMessage } = usePopups();

  useEffect(() => {
    if (error) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [error, handleBannerMessage]);

  return (
    <>
      <Grid
        m={1}
        p={2}
        component={Card}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
          lineHeight: "10px",
        }}
      >
        <Text variant="h1" mb={5}>
          Assessment Claim Process Explained
        </Text>
        <Text variant="body1" mb={3}>
          S12 Solutions has developed a simple, standardised, GDPR-compliant
          claim form process. Once Create Claim is initiated by the AMHP, the
          platform prompts them to select the patient’s GP practice to define
          the organisation that’s accountable for paying the claim. As per NHS
          England’s 'Who Pays Guidance', if the patient’s GP practice is
          unknown, the AMHP will be asked to input the patient’s usual place of
          residence, or if also unknown, the platform will use the assessment
          location. Once complete, the AMHP will issue the claim form to the
          doctor, who will add the additional information required by the paying
          authority, then submit the form.
        </Text>
        <Text variant="body1" mb={3}>
          Within their dashboard, a doctor can see whether a claim requires
          action, is under review, rejected – with explanatory notes and actions
          - or approved.
        </Text>
        <Text variant="body1" mb={3}>
          This process applies to CCGs enrolled on the platform because they
          belong to an area that’s commissioned S12 Solutions. If the required
          CCG is not currently enrolled, the AMHP will be notified that the
          platform’s Create Claim process is not available for the CCG
          identified. In this scenario, the doctor will see the status ‘CCG –
          Not on Platform’ in the assessment within their Visits tab, along with
          the name of the accountable CCG so that the doctor may follow the
          CCG’s usual paper claim form process.
        </Text>

        <Divider />

        <Text variant="h1" mt={3} mb={3}>
          Enrolled CCGs are listed below:
        </Text>
        <List>
          {loading ? (
            <ListItem>
              <ListItemText>Loading</ListItemText>
            </ListItem>
          ) : data ? (
            data.map((val: OrgUserResponse) => (
              <ListItem key={val.id}>
                <ListItemText key={val.id}>{val.name}</ListItemText>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText>
                Error occurred when loading the ccg data
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Grid>
    </>
  );
};
export { ClaimExplained };
