import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import HeaderCard from "components/detailsPage/HeaderCard";
import AssessmentCard from "components/detailsPage/AssessmentCard";
import ContactCard from "components/detailsPage/ContactCard";
import HistoryCard from "components/detailsPage/HistoryCard";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import { CLAIM_STATUS } from "common/constants/options";
import LoadingCircle from "components/loadingCircle";
import dayjs from "dayjs";
import * as COMMON from "common/constants/common";
import {
  AssignmentInd,
  Check,
  Close,
  Inbox,
  Pause,
  AssignmentRounded,
} from "@mui/icons-material";
import {
  Claim,
  CcgUpdateClaimInput,
  ModelListClaimUsersFilterInput,
  OrgUserResponse,
  Scalars,
} from "@s12solutions/types";
import { HeaderActionButtonId } from "common/types/commonTypes";
import { LOADING_STATES } from "common/types/loading";
import { CognitoUserExt } from "common/types/auth";
import DialogList from "components/dialogList";
import { Grid, Card, Typography } from "@mui/material";
import { User } from "@s12solutions/types";
import { Methods } from "api";
import { compact } from "lodash";
import { CLAIM_NOTES } from "common/constants/notes";
import { useLocation, useNavigate } from "react-router-dom";

interface TeamDetailsProps {
  goBack: (from: "claimUpdating" | "backButton" | "sideMenuClick") => void;
  rowId: string;
  ccgId: string | undefined;
}

const TeamClaimsDetails: FC<TeamDetailsProps> = ({
  goBack,
  rowId,
  ccgId,
}) => {
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const { loadingState, setLoadingState, finishLoading } = useLoading();
  const [data, setData] = useState<Claim | undefined>(undefined);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [userData, setUserData] = useState<OrgUserResponse[] | undefined>(
    undefined
  );
  const { user } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  // Mutations
  const {
    loading: updateClaimLoading,
    error: updateClaimError,
    trigger: updateClaim,
  } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput;
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
  });

  // Queries
  const {
    loading: usersLoading,
    error: usersError,
    trigger: getUsers,
  } = useAPI<
    OrgUserResponse[],
    {
      filter: ModelListClaimUsersFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaimUsers",
    manual: true,
  });

  const {
    data: claimUserData,
    error: claimUserDataError,
    trigger: getUserData,
  } = useAPI<
    User,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUser",
    manual: true,
  });

  const {
    data: teamClaimDetailsData,
    loading: teamClaimDetailsLoading,
    error: teamClaimDetailsError,
  } = useAPI<
    Claim,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaim",
    args: {
      id: rowId,
    },
  });

  const assignClaimToUser = useCallback(
    (
      userData: {
        id: string;
        name: string;
      } | null
    ) => {
      if (!userData) {
        return;
      }
      setLoadingState(LOADING_STATES.ASSIGN_TO_USER);
      updateClaim({
        input: {
          id: [rowId],
          assigneeId: userData.id.toString(),
          assigneeName: userData.name,
          notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${userData.name ?? "N/A"
            }@${dayjs().format()}`,
          status: CLAIM_STATUS.underReview,
        },
      })
        .then((data) => {
          if (data && data.length > 0) {
            goBack("claimUpdating");
            handleBannerMessage(COMMON.TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
          } else {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        })
        .catch(() => {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        })
        .finally(finishLoading);
    },
    [
      finishLoading,
      goBack,
      handleBannerMessage,
      rowId,
      setLoadingState,
      updateClaim,
    ]
  );

  useEffect(() => {
    if (user && user.username) {
      getUserData({
        id: user.username,
      });
    }
  }, [getUserData, user]);

  const userPermissions = useMemo(
    () => ({
      claimApprover: claimUserData?.claimApprover ?? false,
      claimPayer: claimUserData?.claimPayer ?? false,
    }),
    [claimUserData]
  );

  let handleClick = useCallback(
    (name: HeaderActionButtonId, ccgId: string, user: CognitoUserExt) => {
      switch (name) {
        case "AssignToAnother":
          getUsers({
            filter: {
              loggedInUserId: user.username,
              selectedClaimOrganisations: [ccgId],
            },
          })
            .then((data) => {
              setShowUsers(true);
              setUserData(data);
            })
            .catch(() => {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            });
          break;
        case "Approve":
          if (!userPermissions.claimApprover) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
            return;
          }
          handleConfirmation(
            MESSAGES.APPROVE_CLAIM,
            () => {
              setLoadingState(LOADING_STATES.APPROVE);
              updateClaim({
                input: {
                  id: [rowId],
                  notes: `${CLAIM_NOTES.CLAIM_APPROVED}@${user.attributes.name ?? "N/A"
                    }@${dayjs().format()}`,
                  status: "approved",
                },
              })
                .then((data) => {
                  if (data && data.length > 0) {
                    handleBannerMessage(
                      "success",
                      `Claim approved by ${user.attributes.name}`
                    );
                    goBack("claimUpdating");
                  } else {
                    handleBannerMessage(
                      COMMON.TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                  }
                })
                .catch(() => {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.UNEXPECTED_ERROR_MESSAGE
                  );
                })
                .finally(finishLoading);
            },
            "Approve Claim",
            "APPROVE CLAIM",
            "CANCEL"
          );
          break;
        case "Reject":
          if (!userPermissions.claimApprover) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
            return;
          }
          handleConfirmation(
            MESSAGES.REJECT_CLAIM,
            (reason?: string) => {
              setLoadingState(LOADING_STATES.REJECT);
              updateClaim({
                input: {
                  id: [rowId],
                  notes: reason
                    ? `${CLAIM_NOTES.CLAIM_REJECTED}@${user.attributes.name ?? "N/A"
                    }@${dayjs().format()}@${reason}`
                    : `${CLAIM_NOTES.CLAIM_REJECTED}@${user.attributes.name ?? "N/A"
                    }@${dayjs().format()}`,
                  status: "rejected",
                },
              })
                .then((data) => {
                  if (data && data.length > 0) {
                    handleBannerMessage(
                      "success",
                      `Claim rejected by ${user.attributes.name}`
                    );
                    goBack("claimUpdating");
                  } else {
                    handleBannerMessage(
                      COMMON.TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                  }
                })
                .catch(() => {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.UNEXPECTED_ERROR_MESSAGE
                  );
                })
                .finally(finishLoading);
            },
            "Reject Claim",
            "REJECT CLAIM",
            "CANCEL",
            true
          );
          break;
        case "PutOnHold":
          handleConfirmation(
            MESSAGES.PUT_CLAIM_ON_HOLD,
            (reason?: string) => {
              setLoadingState(LOADING_STATES.ON_HOLD);
              updateClaim({
                input: {
                  id: [rowId],
                  notes: reason
                    ? `${CLAIM_NOTES.CLAIM_PUT_TO_ON_HOLD}@${user.attributes.name ?? "N/A"
                    }@${dayjs().format()}@${reason}`
                    : `${CLAIM_NOTES.CLAIM_PUT_TO_ON_HOLD}@${user.attributes.name ?? "N/A"
                    }@${dayjs().format()}`,
                  status: "on_hold",
                },
              })
                .then((data) => {
                  if (data && data.length > 0) {
                    handleBannerMessage(
                      "success",
                      `Claim put to on hold by ${user.attributes.name}`
                    );
                    goBack("claimUpdating");
                  } else {
                    handleBannerMessage(
                      COMMON.TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                  }
                })
                .catch(() => {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.UNEXPECTED_ERROR_MESSAGE
                  );
                })
                .finally(finishLoading);
            },
            "Put On Hold",
            "PUT ON HOLD",
            "CANCEL",
            true
          );
          break;
        case "AssignToMe":
          if (!data || data.assigneeId === user.username) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
            return;
          }
          handleConfirmation(
            MESSAGES.ASSIGN_CLAIM,
            () => {
              setLoadingState(LOADING_STATES.ASSIGN);
              updateClaim({
                input: {
                  id: [rowId],
                  assigneeId: user.username,
                  assigneeName: user.attributes.name,
                  notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${user.attributes.name
                    }@${dayjs().format()}`,
                  status: "under_review",
                },
              })
                .then((data) => {
                  if (data && data.length > 0) {
                    goBack("claimUpdating");
                    handleBannerMessage(
                      COMMON.TYPE_SUCCESS,
                      MESSAGES.CLAIM_ASSIGNED
                    );
                  } else {
                    handleBannerMessage(
                      COMMON.TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                  }
                })
                .catch(() => {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.UNEXPECTED_ERROR_MESSAGE
                  );
                })
                .finally(finishLoading);
            },
            "Assign Claim",
            "ASSIGN CLAIM",
            "CANCEL"
          );
          break;
        case "Unassign":
          handleConfirmation(
            MESSAGES.UNASSIGN_CLAIM,
            () => {
              setLoadingState(LOADING_STATES.UNASSIGN);
              updateClaim({
                input: {
                  id: [rowId],
                  assigneeId: "!!!",
                  assigneeName: "!!!",
                  notes: `${CLAIM_NOTES.CLAIM_UNASSIGNED}@${user.attributes.name
                    }@${dayjs().format()}`,
                  status: "under_review",
                },
              })
                .then((data) => {
                  if (data && data.length > 0) {
                    goBack("claimUpdating");
                    handleBannerMessage(
                      COMMON.TYPE_SUCCESS,
                      MESSAGES.CLAIM_UNASSIGNED
                    );
                  } else {
                    handleBannerMessage(
                      COMMON.TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                  }
                })
                .catch(() => {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.UNEXPECTED_ERROR_MESSAGE
                  );
                })
                .finally(finishLoading);
            },
            "Unassign Claim",
            "UNASSIGN CLAIM",
            "CANCEL"
          );
          break;
        default:
          break;
      }
    },
    [
      data,
      finishLoading,
      getUsers,
      goBack,
      handleBannerMessage,
      handleConfirmation,
      rowId,
      setLoadingState,
      updateClaim,
      userPermissions,
    ]
  );

  const buttonClick = useCallback(
    (name: HeaderActionButtonId) => {
      if (!user) {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
        return;
      }
      if (ccgId) {
        handleClick(name, ccgId, user);
      } else if (data?.organisation.id) {
        handleClick(name, data?.organisation.id, user);
      } else {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
        return;
      }
    },
    [
      ccgId,
      handleBannerMessage,
      handleClick,
      data?.organisation.id,
      user,
    ]
  );

  useEffect(() => {
    if (
      teamClaimDetailsData &&
      teamClaimDetailsData.status === "under_review" &&
      teamClaimDetailsData.assigneeId !== "!!!"
    ) {
      setData(teamClaimDetailsData);
    }
  }, [teamClaimDetailsData]);

  useEffect(() => {
    if (
      teamClaimDetailsError ||
      usersError ||
      updateClaimError ||
      claimUserDataError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    claimUserDataError,
    handleBannerMessage,
    teamClaimDetailsError,
    updateClaimError,
    usersError,
  ]);

  useEffect(() => {
    if (location?.state?.isSameMenu && !!goBack) {
      goBack("sideMenuClick");
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: false },
      });
    }
  }, [goBack, location.pathname, location?.state?.isSameMenu, navigate]);

  return (
    <>
      {teamClaimDetailsLoading ? (
        <LoadingCircle />
      ) : data && data.organisation && data.organisation.featureFlags ? (
        <>
          <DialogList
            openDialog={showUsers}
            closeDialog={() => {
              setShowUsers(false);
            }}
            selectedRowIds={[rowId].length}
            listData={userData}
            handleUser={(user) => {
              setShowUsers(false);
              assignClaimToUser(user);
            }}
            loadingUser={usersLoading}
            loading={updateClaimLoading}
          />
          <HeaderCard
            status={data?.status === CLAIM_STATUS.underReview ? "OPEN" : ""}
            assigneeName={data?.assigneeName ?? ""}
            endpointsLoading={loadingState}
            goBack={() => goBack("backButton")}
            buttons={compact([
              !!userPermissions.claimApprover
                ? {
                  key: "Approve",
                  loading: loadingState === LOADING_STATES.APPROVE,
                  icon: <Check />,
                }
                : null,
              !!userPermissions.claimApprover
                ? {
                  key: "Reject",
                  loading: loadingState === LOADING_STATES.REJECT,
                  icon: <Close />,
                }
                : null,
              {
                key: "PutOnHold",
                loading: loadingState === LOADING_STATES.ON_HOLD,
                icon: <Pause />,
              },
              data?.assigneeId !== user?.username
                ? {
                  key: "AssignToMe",
                  loading: loadingState === LOADING_STATES.ASSIGN,
                  icon: <AssignmentRounded />,
                }
                : null,
              {
                key: "AssignToAnother",
                loading:
                  usersLoading ||
                  loadingState === LOADING_STATES.ASSIGN_TO_USER,
                icon: <AssignmentInd />,
              },
              {
                key: "Unassign",
                loading: loadingState === LOADING_STATES.UNASSIGN,
                icon: <Inbox />,
              },
            ])}
            onClick={(a) => {
              buttonClick(a);
            }}
            claimId={data?.displayId}
          />
          <AssessmentCard claim={data} />
          <ContactCard docData={data?.doctor} amhpData={data?.amhp} />
          <HistoryCard data={data} />
        </>
      ) : (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <Typography variant="h1">
                  Unexpected error occurred when fetching the claim data
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(TeamClaimsDetails);
