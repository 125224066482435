import { useEffect, useState, useCallback, useRef, useContext } from "react";
import axios from "axios";
import API, { Methods } from "api";
import AppVersionContext from "store/appVersionContext";

interface useAPIRes<TData = any, TArgs = any> {
  loading: boolean;
  data?: TData;
  error: any;
  trigger: (args?: TArgs) => Promise<TData | undefined>;
}
interface useAPIParams<TData = any, TArgs = any> {
  method: Methods;
  fieldName: string;
  args?: TArgs;
  skip?: boolean;
  manual?: boolean;
  onCompleted?: (data?: TData) => void;
}
export const useAPI = <TData = any, TArgs = any>({
  method,
  fieldName,
  args,
  skip,
  manual,
  onCompleted,
}: useAPIParams<TData, TArgs>): useAPIRes<TData, TArgs> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TData>();
  const [error, setErrors] = useState<any>();
  const { version } = useContext(AppVersionContext);
  const api = version === "1" ? new API("ClaimsV3API") : new API("ClaimsAPI");

  // Create axios cancel token source
  const cancelTokenSource = axios.CancelToken.source();
  const cancelTokenSourceRef = useRef(cancelTokenSource);
  cancelTokenSourceRef.current = cancelTokenSource;

  // manualRef
  const manualRef = useRef<boolean | undefined>(manual);
  manualRef.current = manual;

  // argsRef
  // this prevents infinite loop from args being re-rendered from being included
  // in `request` dependency array
  const argsRef = useRef<TArgs | undefined>(args);
  argsRef.current = args;

  // inProgressRef
  // this prevents multiple requests from being made concurrently
  const inProgressRef = useRef<boolean>(false);

  // runOnceRef
  // this prevents infinite loop from `request` being re-rendered
  const runOnceRef = useRef<boolean>(false);

  // On completed needs to be wrapped in useCallback to stop
  // re-rendering of the component
  const handleCompleted = useCallback(
    (data?: TData) => {
      onCompleted?.(data);
    },
    [onCompleted]
  );

  const request = useCallback(
    async (_args?: TArgs) => {
      if (runOnceRef.current || inProgressRef.current) return;

      inProgressRef.current = true;
      runOnceRef.current = true;

      let response;
      try {
        setLoading(true);

        const _arguments = {
          ...(_args || argsRef.current),
        } as TArgs;
        const options = {
          params: {
            info: {
              fieldName,
            },
          },
          // Include cancelToken in the request
          // cancelToken: cancelTokenSourceRef.current.token,
        };

        switch (method) {
          case Methods.GET:
            response = await api.get<TData>({
              ...options,
              params: {
                ...options.params,
                arguments: _arguments,
              },
            });
            break;
          case Methods.POST:
            response = await api.post<TData, TArgs>(_arguments, options);
            break;
          case Methods.PUT:
            response = await api.put<TData, TArgs>(_arguments, options);
            break;
          case Methods.DELETE:
            response = await api.delete<TData>(options);
            break;
          default:
            throw new Error(`😨 Unconfigured/invalid method ${method}`);
        }

        setData(response.data);
        handleCompleted?.(response.data);
      } catch (error) {
        setErrors(error);
      } finally {
        inProgressRef.current = false;
        setLoading(false);

        return response?.data;
      }
    },
    [fieldName, method, handleCompleted]
  );

  const handleTrigger = useCallback(
    async (_args?: TArgs) => {
      runOnceRef.current = false;
      const data = await request(_args);
      return data;
    },
    [request]
  );

  useEffect(() => {
    if (
      // If skip is true
      skip ||
      // If manual is true
      manualRef.current ||
      // If method is not get
      method !== Methods.GET ||
      // If request is in progress
      inProgressRef.current
    )
      return;

    request();

    return () => {
      // Cleanup: cancel the request when the component unmounts and
      // reset state and refs
      cancelTokenSourceRef.current.cancel();
      setLoading(false);
      setData(undefined);
      setErrors(undefined);
    };
  }, [method, request, skip]);

  return {
    loading,
    data,
    error,
    trigger: handleTrigger,
  };
};
