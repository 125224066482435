import { CognitoUserExt } from "common/types/auth";
import { createContext } from "react";

import AuthService from "@s12solutions/auth/helpers/AuthService";

/*
    AUTH CONTEXT
    - Global store for any and all auth related data that would be managed in state
    - You can access user session data by calling:

        const { user } = useContext(AuthContext);

    - If for some unknown reason any mutation of user session data must happen,
      remember to update it here with:

        const { setCredentials } = useContext(AuthContext);
        ...
        setCredentials({ user: newUserData });
*/

// -----
// These types are not currently used explicitly, but we want to keep them for future
// reference if we need to be explicit on whats being passed to the auth context
export type IAuthError = {
  code: string;
  message: string;
};

export interface IAuthState {
  user?: CognitoUserExt;
  authError?: IAuthError;
  authService?: AuthService;
  clearCredentials?: (state?: any) => void;
  setCredentials?: (state?: any) => void;
  setError?: (state?: any) => void;
}
// -----

const initialState: IAuthState = {
  user: undefined,
  authError: { code: "", message: "" },
  authService: undefined,
  clearCredentials: () => {},
  setCredentials: () => {},
  setError: () => {},
};

export const AuthContext = createContext(initialState);
