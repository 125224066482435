import { Claim } from "@s12solutions/types";

export interface ColumnData {
  key: string;
  value: string;
  [key: string]: any;
}

export interface FilterData {
  searchString: string;
}

export interface FeatureFlags {
  claims: boolean;
  mileage: boolean;
  additionalExpenses: boolean;
  fullPatientName: boolean;
  confirmAssessmentTookPlace: boolean;
  provideVehicleInformation: boolean;
  ccgRequiresAmhpTeamName: boolean;
  ccgRequiresNhsNumber: boolean;
  ccgRequiresDoctorToInvoice: boolean;
  ccgRequiresBillingInformation: boolean;
  ccgRequiresLineManager: boolean;
  ccgRequiresGmcNumber: boolean;
  ccgDoesNotAcceptHospitalAssessments: boolean;
  ccgRequiresDoctorMhtAssociations: boolean;
  ccgDisplayClaimLocation: boolean;
  ccgRequiresDoctorAdditionalConfirmation: boolean;
  doctorFeesMileageWithoutInvoice: boolean;
  mhtAndLineManager: boolean;
  ccgRequiresDoctorEmployedStatus: boolean;
}

export interface UserPermissions {
  claimPayer: boolean;
  claimApprover: boolean;
}

export interface TypeClaim extends Claim {
  doctorName: string;
  doctorAdditionalConfCol: string;
  doctorEmployedStatusCol: string;
  employer: string;
  postcode: string;
  ccg: string;
  ccgId: string;
  amhpTeamName: string;
  expenses: string;
  approver: string;
  approverName: string;
  payer: string;
  approvedDate: string;
  paidDate: string;
}

export type HeaderActionButtonId =
  | "PutUnderReview"
  | "Unassign"
  | "PutOnHold"
  | "Approve"
  | "Reject"
  | "MarkAsPaid"
  | "UndoApproval"
  | "MarkAsUnpaid"
  | "AssignToMe"
  | "AssignToAnother";

export interface IHeaderActionButton {
  key: HeaderActionButtonId;
  icon: JSX.Element;
  loading: boolean;
}

export interface IActionButton {
  id: ActionButtonId;
  icon: JSX.Element;
  loading: boolean;
}

export interface ActionButtonList {
  assignMe?: IActionButton;
  approve?: IActionButton;
  markPaid?: IActionButton;
  putOnHoldClaims?: IActionButton;
  unassignClaims?: IActionButton;
  assignClaims?: IActionButton;
}

export enum ActionButtonNames {
  PAID = "Mark as Paid",
  UNPAID = "Mark as Unpaid",
  ASSIGN_TO_ME = "Assign to Me",
  ASSIGN_TO_ANOTHER = "Assign to Another User",
  ON_HOLD = "On Hold",
  UNASSIGN = "Unassign",
  APPROVE = "Approve",
  REJECT = "Reject",
  UNDO_APPROVAL = "Undo Approval"
}

export type ActionButtonId =
  | "mark_paid"
  | "approve"
  | "un_assign"
  | "put-on-hold-claim"
  | "assign_to_me"
  | "assign_claim";

export interface CSVHeader {
  label: string;
  key: string;
}

export interface CSVData {
  // Claim ID
  displayId: string;

  // Doctor Name
  doctorName: string;

  // Doctor Contact Details
  doctorContactDetails: string;

  // Authorised By
  authorisedBy: string;

  // AUthorisartion Date
  authorisationDate: string;

  // Claim Status
  claimStatus: string;

  // Approver Initials
  approver: string;

  // CCG Relation
  ccgRelation: string;

  // CCG Related Location
  ccgLocation: string;

  // Visit Date
  visitDate: string;

  // Visit Time
  visitTime: string;

  // CCG
  ccg: string;

  // Patient Initials / Name
  patient: string;

  // Additional Information
  additionalInfo: string;

  // Paid?
  paid: string;

  // Approved Date
  approvedDate: string;

  // Paid Date
  paidDate: string;

  // Postcode
  postcode?: string | boolean;

  // Mileage
  mileage?: string | boolean;

  // From
  startingPostcode?: string | boolean;

  // To
  assessmentPostcode?: string | boolean;

  // Vehicle Information
  vehicle?: string | boolean;

  // Additional Expenses
  additionalExpenses?: string | boolean;

  // Company Name
  companyName?: string | boolean;

  // Company Address
  companyAddress?: string | boolean;

  // VAT Registration
  vatRegistration?: string | boolean;

  // Assessment Took Place
  assessmentTookPlace?: string | boolean;

  // NHS Number
  patientNhsNumber?: string | boolean;

  // Selected MHT
  selectedMHT?: string | boolean;

  // Doctor Line Manager
  lineManager?: string | boolean;

  // Doctor GMC Number
  doctorGMCNumber?: string | boolean;

  // AMHP Team Name
  amhpTeamName?: string | boolean;

  // Doctor MHT Associations
  mhtAssociation?: string | boolean;

  // Doctor Confirmation
  doctorAdditionalConf?: string | boolean;

  // Assessment Fee (GBP) - Mileage Fee (GBP) - VAT (GBP) - Subtotal (GBP) - TOTAL Exc VAT (GBP) - TOTAL Inc VAT (GBP)

  // Assessment Fee (GBP)
  assessmentFee?: string | boolean;

  // Mileage Fee (GBP)
  travelFee?: string | boolean;

  // Employer - //! NOTE: this is being added to the end as of issue 152; though may require additional logic applying to it for the new claims system as it is developed
  employer: string;
}

export interface ClaimFilterInput {
  searchValue: string;
}

export interface GlobalClaim {
  id: string;
  displayId: string;
  assigneeId: string | null;
  assigneeName: string | null;
  status: string;
  patientFullName: string;
  assessmentPostcode: string;
  patientNhsNumber: string;
  claimPostcode: string;
  doctor: {
    name: string;
  };
}