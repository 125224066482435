import React from "react";
import { makeStyles } from "@mui/styles";
import { TextField, BaseTextFieldProps, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.warning.dark,
    fontWeight: "bold",
  },
}));

interface StyledInputProps {
  id: string;
  testId?: string;
  disabled?: boolean;
  value: number | string | null | undefined;
  label: string;
  onChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  errorText?: string;
  search?: boolean;
  autoFocus?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onFocus?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  multiline?: boolean;
  maxLength?: number;
  required?: boolean;
  tabIndex?: number;
  readOnly?: boolean;
  inputRef?: React.Ref<any>;
}

const StyledInput: React.FC<StyledInputProps & BaseTextFieldProps> = (
  {
    id,
    testId,
    disabled,
    value,
    label,
    onChange,
    error,
    errorText,
    helperText,
    startAdornment,
    endAdornment,
    search,
    onClick,
    onFocus,
    onBlur,
    autoFocus,
    placeholder,
    multiline,
    minRows,
    maxRows,
    maxLength,
    required = false,
    tabIndex = 0,
    readOnly = false,
    inputRef,
    ...props
  },
  { ...rest }
) => {
  const classes = useStyles();
  return (
    <div>
      <TextField
        inputRef={inputRef}
        id={id}
        data-test-id={testId}
        className={classes.input}
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        InputLabelProps={{
          shrink: true,
        }}
        tabIndex={-1}
        variant="outlined"
        autoComplete="off"
        value={value}
        onChange={onChange}
        required={required}
        error={error}
        helperText={helperText}
        InputProps={{
          startAdornment,
          endAdornment,
          readOnly: readOnly,
          onClick,
          onFocus,
          onBlur,
        }}
        sx={props.sx}
        inputProps={{
          maxLength: maxLength,
        }}
        {...rest}
      />
      {error && errorText && (
        <div className={classes.errorText}>
          <Typography variant="h5">{errorText}</Typography>
        </div>
      )}
    </div>
  );
};

export { StyledInput };
