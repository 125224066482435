import HomeIcon from "@mui/icons-material/Home";
import UnassignedIcon from "@mui/icons-material/Inbox";
import TeamClaimsIcon from "@mui/icons-material/Group";
import MyClaimsIcon from "@mui/icons-material/Person";
import RejectedIcon from "@mui/icons-material/Close";
import ApprovedIcon from "@mui/icons-material/Check";
import PaidIcon from "@mui/icons-material/CreditScore";
import SupportIcon from "@mui/icons-material/Help";
import PauseIcon from "@mui/icons-material/Pause";
import ArchiveIcon from "@mui/icons-material/Archive";
// The below interface is required for nested side menu items
// import { MenuItems } from "common/types/sidemenu";
// otherwise use undefined

import {
  HOME,
  APPROVED,
  PAID,
  MY_CLAIMS,
  REJECTED,
  SUPPORT,
  TEAM_CLAIMS,
  UNASSIGNED,
  ON_HOLD,
  ARCHIVED,
} from "../routes/RouteConstants";

const config = [
  {
    name: "Home",
    testId: "sidemenu-home",
    icon: <HomeIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: HOME,
  },
  {
    name: "Unassigned",
    testId: "sidemenu-unassigned",
    icon: <UnassignedIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: UNASSIGNED,
  },
  {
    name: "Team Claims",
    testId: "sidemenu-team-claims",
    icon: <TeamClaimsIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: TEAM_CLAIMS,
  },
  {
    name: "My Claims",
    testId: "sidemenu-my-claims",
    icon: <MyClaimsIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: MY_CLAIMS,
  },
  {
    name: "Rejected",
    testId: "sidemenu-rejected",
    icon: <RejectedIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: REJECTED,
  },
  {
    name: "Approved",
    testId: "sidemenu-approved",
    icon: <ApprovedIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: APPROVED,
  },
  {
    name: "On Hold",
    testId: "sidemenu-on-hold",
    icon: <PauseIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: ON_HOLD,
  },
  {
    name: "Paid",
    testId: "sidemenu-paid",
    icon: <PaidIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: PAID,
  },
  {
    name: "Archived",
    testId: "sidemenu-archived",
    icon: <ArchiveIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: ARCHIVED,
  },
  {
    name: "Support",
    testId: "sidemenu-support",
    icon: <SupportIcon color="primary" />,
    items: undefined,
    count: undefined,
    href: SUPPORT,
  },
].filter((va) => !!va.name) as {
  name: string;
  testId: string;
  icon: JSX.Element;
  items: undefined;
  count: undefined;
  href: string;
}[];

export default config;
