import React, { useEffect } from "react";
// Hooks
import { usePopups } from "hooks";
import { Grid, Typography as Text, AlertColor } from "@mui/material";
import * as constants from "common/constants/theme";
import { TYPE_SUCCESS, TYPE_INFO } from "common/constants/common";

interface BannerMessageProps {
  type?: AlertColor;
  message: string;
}

export const BannerMessageContent: React.FC<BannerMessageProps> = ({
  message,
  type,
}) => (
  <Grid
    xs={12}
    sx={{
      justifyContent: "center",
      display: "flex",
    }}
  >
    <Grid
      item
      xs={12}
      m={1}
      p={1}
      data-test-id="testBannerMessage"
      sx={{
        backgroundColor:
          type === TYPE_SUCCESS
            ? constants.successGreen
            : type === TYPE_INFO
            ? constants.infoBlue
            : constants.red,
        borderWidth: 2,
        borderColor:
          type === TYPE_SUCCESS
            ? constants.successGreen
            : type === TYPE_INFO
            ? constants.infoBlue
            : constants.red,
        borderStyle: "solid",
        justifyContent: "center",
        display: "flex",
        borderRadius: 1,
        flex: 1,
      }}
    >
      <Text sx={{ color: constants.white, fontSize: 18 }}>{message}</Text>
    </Grid>
  </Grid>
);

const BannerMessage: React.FC = () => {
  const {
    bannermessage: { type, message },
    closeBannermessage,
  } = usePopups();
  const isOpen = type && message ? true : false;
  if (type && message) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  useEffect(() => {
    let timer = setTimeout(() => {
      closeBannermessage();
    }, 8000);
    return () => {
      clearTimeout(timer);
    };
  }, [closeBannermessage, isOpen]);

  if (!isOpen) return null;

  return <BannerMessageContent type={type} message={message} />;
};

export default React.memo(BannerMessage);
