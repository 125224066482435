import { createContext } from "react";
import { GridSelectionModel } from "@mui/x-data-grid-pro";

interface ProDataGridContextProps {
  isSelected: boolean;
  setIsSelected: (value: boolean) => void;
  selectionModel: GridSelectionModel;
  setSelectionModel: (value: GridSelectionModel) => void;
  allSelectedRows: GridSelectionModel;
  setAllSelectedRows: (value: GridSelectionModel) => void;
  resetSelected: () => void;
}

const initialState: ProDataGridContextProps = {
  isSelected: false,
  setIsSelected: () => {},
  selectionModel: [],
  setSelectionModel: () => {},
  allSelectedRows: [],
  setAllSelectedRows: () => {},
  resetSelected: () => {},
};

export const ProDataGridContext = createContext(initialState);
