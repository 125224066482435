import React from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.warning.dark,
    fontWeight: "bold",
  },
  dateTime: {
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  timePicker: {
    "& MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
  },
}));

interface StyledTimePickerProps {
  testId?: string;
  disabled?: boolean;
  value: number | string | null;
  label: string;
  onChange: (val: string) => void;
  errorText?: string;
  error: boolean | undefined;
}

const StyledTimePicker: React.FC<StyledTimePickerProps> = (
  { testId, disabled, value, label, onChange, error, errorText },
  { ...rest }
) => {
  const classes = useStyles();
  return (
    <div>
      <TextField
        data-test-id={testId}
        type="time"
        label={label}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className={classes.dateTime}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      {error && errorText && (
        <div className={classes.errorText}>
          <Typography variant="h5">{errorText}</Typography>
        </div>
      )}
    </div>
  );
};

export { StyledTimePicker };
