import React, { useMemo } from "react";
import { Grid, Typography as Text, Card } from "@mui/material";
import dayjs from "dayjs";
import {
  ccgRequiresAmhpTeamName,
  ccgRequiresBillingInformation,
  ccgRequiresDoctorAdditionalConfirmation,
  ccgRequiresDoctorEmployedStatus,
  ccgRequiresDoctorMhtAssociations,
  ccgRequiresDoctorToInvoice,
  ccgRequiresGmcNumber,
  ccgRequiresNhsNumber,
  doctorFeesMileageWithoutInvoice,
  hasAdditionalExpenses,
  hasMileage,
  shouldConfirmAssessmentTookPlace,
  shouldProvideVehicleInformation,
} from "utils/featureFlags";
import { calculateInvoiceTotals } from "utils/invoicing";
import { LocationNameInput } from "common/types/schema";
import { formatAddress } from "utils/address";
import { formatFuelType } from "utils/helpers";
import { Claim } from "@s12solutions/types";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";
import { compact } from "lodash";

interface AssessmentCardProps {
  claim: Claim;
}

const doctorConfText = {
  dpt_inside_contracted_hours: "Inside Hours",
  dpt_outside_contracted_hours: "Outside Hours",
  independent_capacity: "Independent",
};

const doctorEmpStatusText = {
  payroll: "Yes",
  non_payroll: "No",
};

enum DoctorEmployedStatusValues {
  payroll = "payroll",
  non_payroll = "non_payroll",
}

enum DoctorConfirmation {
  dpt_inside_contracted_hours = "dpt_inside_contracted_hours",
  dpt_outside_contracted_hours = "dpt_outside_contracted_hours",
  independent_capacity = "independent_capacity",
}

const AssessmentCard: React.FC<AssessmentCardProps> = ({ claim }) => {
  // which just tests if it accepts claims, not if it accepts additional expenses and other featureFlags.
  const detailsSectionItems = useMemo(() => {
    return compact([
      claim.startingPostcode && {
        label: "Doctor Starting Postcode",
        value: claim.startingPostcode.toUpperCase(),
      },
      hasMileage(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.mileage
        ? {
          label: "Doctor Distance Travelled",
          value: `${claim.mileage} miles`,
        }
        : false,
      shouldProvideVehicleInformation(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) &&
        claim.claimData &&
        claim.claimData.fuelType
        ? {
          label: "Vehicle Information",
          value: formatFuelType(
            claim.claimData.fuelType,
            claim.claimData.engineSize
          ),
        }
        : false,
      hasAdditionalExpenses(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      )
        ? !ccgRequiresDoctorToInvoice(
          claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) &&
        claim.additionalExpenses && {
          label: "Additional Travel Expenses",
          value: `£${claim.additionalExpenses}`,
        }
        : false,
      {
        label: "Independent Doctor",
        value: claim.isIndependentDoctor ? "Yes" : "No",
      },
      !claim.isIndependentDoctor && claim.lineManager
        ? {
          label: "Doctor Line Manager",
          value: claim.lineManager,
        }
        : false,
      !claim.isIndependentDoctor && claim.defaultMHT
        ? {
          label: "Employer",
          value: claim.defaultMHT,
        }
        : false,
      ccgRequiresGmcNumber(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.doctor.gmc
        ? {
          label: "Doctor GMC Number",
          value: claim.doctor.gmc,
        }
        : false,
      {
        label: "Visit Time",
        value: dayjs(claim.visitDate).format("HH:mm"),
      },
      {
        label: "Date",
        value: dayjs(claim.visitDate).format("dddd D MMMM YYYY"),
      },
      ccgRequiresNhsNumber(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.patientNhsNumber
        ? {
          label: "Patient NHS Number",
          value: claim.patientNhsNumber,
        }
        : false,
      {
        label: claim.patientFullName ? "Patient Name" : "Patient Initials",
        value: claim.patientFullName ?? claim.patientInitials,
      },
      claim.additionalInformation
        ? {
          label: "Additional Information",
          value: claim.additionalInformation,
        }
        : false,
      claim.locationType === "gPPractice" && !!claim.locationText
        ? {
          label: "Patient's GP Practice",
          value: claim.locationText,
        }
        : claim.locationType === "patientAddress" && !!claim.locationText
          ? {
            label: "Patient's Address",
            value: claim.locationText,
          }
          : false,
      (!!claim.locationText || !!claim.assessmentPostcode)
        ? {
          label: "Assessment Location",
          value: claim.assessmentPostcode ?? claim.locationText,
        }
        : false,
      ccgRequiresDoctorMhtAssociations(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) &&
        !claim.isIndependentDoctor &&
        compact(claim.doctor.mhtEmployersOrganisationNames).length > 0
        ? {
          label: "Doctor MHT Associations",
          value: compact(claim.doctor.mhtEmployersOrganisationNames).join(
            ",\n"
          ),
        }
        : false,
      shouldConfirmAssessmentTookPlace(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) &&
        typeof claim.assessmentTookPlace !== "undefined" &&
        claim.assessmentTookPlace !== null
        ? {
          label: "Assessment Took Place?",
          value: !!claim.assessmentTookPlace ? "Yes" : "No",
        }
        : false,
      ccgRequiresBillingInformation(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.claimData?.billingCompanyName
        ? {
          label: "Company Name",
          value: claim.claimData?.billingCompanyName,
        }
        : false,
      ccgRequiresBillingInformation(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.claimData?.billingAddress
        ? {
          label: "Company Address",
          value: formatAddress(
            claim.claimData.billingAddress as LocationNameInput
          ),
        }
        : false,
      ccgRequiresBillingInformation(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.claimData?.vatRegistration
        ? {
          label: "VAT Registration",
          value: claim.claimData?.vatRegistration,
        }
        : false,
      ccgRequiresAmhpTeamName(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.amhpTeam?.name
        ? {
          label: "AMHP Team Name",
          value: claim.amhpTeam?.name,
        }
        : false,
      ccgRequiresDoctorAdditionalConfirmation(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) && claim.doctorAdditionalConf
        ? {
          label: "Doctor Confirmation",
          value:
            doctorConfText[claim.doctorAdditionalConf as DoctorConfirmation],
        }
        : false,
      ccgRequiresDoctorEmployedStatus(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      )
        ? {
          label: "Payroll",
          value: claim.doctorEmployedStatus
            ? doctorEmpStatusText[
            claim.doctorEmployedStatus as DoctorEmployedStatusValues
            ]
            : "No",
        }
        : false,
      claim?.locationType
        // we use includes here because a lot of the values were
        // serialised over and over again in the database.
        // e.g. `"\"\\\"\\\\\\\"\\\\\\\\\\\\\\\"gPPractice\\\\\\\\\\\\\\\"\\\\\\\"\\\"\""`.
        // This happend because the sequelize model had this field as a JSON column. The data
        // still needs cleaning but this has been fixed.
        ?.includes("gPPractice") && claim?.locationText
        ? { label: "GP", value: claim.locationText }
        : false
    ]).filter((x) => !!x);
  }, [claim]);

  const feesSectionItems = useMemo(() => {
    // Ignore if the data is showin in the invoice section above ^^
    if (
      ccgRequiresDoctorToInvoice(
        claim?.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      )
    ) {
      return [];
    }
    if (
      !doctorFeesMileageWithoutInvoice(
        claim?.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) ||
      !claim?.invoiceData
    ) {
      return [];
    }
    // Doctor isn't invoicing, but is still required to enter the assessment fee and may enter
    // a mileage also.
    return [
      {
        label: "Assessment Fee (GBP)",
        value: claim?.invoiceData.assessmentFee,
      },
      { label: "Mileage Fee (GBP)", value: claim?.invoiceData.travelFee },
    ].filter((x) => !!x);
  }, [claim?.organisation.featureFlags, claim?.invoiceData]);

  const invoiceSectionItems = useMemo(() => {
    if (
      !ccgRequiresDoctorToInvoice(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      ) ||
      !claim.invoiceData
    ) {
      return [];
    }
    const { subtotal, vat, totalExcVat, totalIncVat } = calculateInvoiceTotals(
      claim,
      Boolean(claim.claimData?.vatRegistration)
    );

    return compact([
      { label: "Assessment Fee (GBP)", value: claim.invoiceData.assessmentFee },
      {
        label: "Mileage Fee (GBP)",
        value: claim.invoiceData.travelFee ?? "0.00",
      },
      hasAdditionalExpenses(
        claim.organisation.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
      )
        ? {
          label: "Additional Travel Expenses",
          value: !!claim.additionalExpenses
            ? `£${claim.additionalExpenses}`
            : "-",
        }
        : false,
      {
        label: "VAT (GBP)",
        value: vat,
      },
      {
        label: "Subtotal (GBP)",
        value: subtotal,
      },
      {
        label: "TOTAL Exc VAT (GBP)",
        value: totalExcVat,
      },
      {
        label: "TOTAL Inc VAT (GBP)",
        value: totalIncVat,
      },
    ]).filter((x) => !!x.label);
  }, [claim]);

  return (
    <>
      <Grid
        m={1}
        p={2}
        component={Card}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderRadius: 1,
        }}
      >
        <Grid container spacing={3} sx={{ height: "fit-content" }}>
          <Grid item>
            <Text variant="h1">Assessment Visit</Text>
          </Grid>
          <Grid container spacing={2} mt={1} ml={1}>
            {detailsSectionItems.map((val) => (
              <Grid item xs={4}>
                <Text variant="body1">{val.label}</Text>
                <Text variant="body1">
                  <b>{val.value}</b>
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {invoiceSectionItems.length > 0 && (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
          }}
        >
          <Grid container spacing={3} sx={{ height: "fit-content" }}>
            <Grid item>
              <Text variant="h1">Invoice Details</Text>
            </Grid>
            <Grid container spacing={2} mt={1} ml={1}>
              {invoiceSectionItems.map((val) => (
                <Grid item xs={4}>
                  <Text variant="body1">{val.label}</Text>
                  <Text variant="body1">
                    <b>{val.value}</b>
                  </Text>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      {feesSectionItems.length > 0 && (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
          }}
        >
          <Grid container spacing={3} sx={{ height: "fit-content" }}>
            <Grid item>
              <Text variant="h1">Doctor Fee and Mileage</Text>
            </Grid>
            <Grid container spacing={2} mt={1} ml={1}>
              {feesSectionItems.map((val) => (
                <Grid item xs={4}>
                  <Text variant="body1">{val.label}</Text>
                  <Text variant="body1">
                    <b>{val.value}</b>
                  </Text>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default React.memo(AssessmentCard);
