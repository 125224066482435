import React from "react";
import { Grid, Typography as Text, Card, Chip } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { LoadingButton } from "@mui/lab";
import { ButtonSpacing, chipRounding } from "common/constants/theme";
import {
  ActionButtonNames,
  HeaderActionButtonId,
  IHeaderActionButton,
} from "common/types/commonTypes";
import { LOADING_STATES } from "common/types/loading";

interface HeaderCardProps {
  goBack?: () => void;
  buttons?: IHeaderActionButton[];
  onClick?: (val: HeaderActionButtonId) => void;
  status?: string;
  assigneeName?: string;
  claimId?: string;
  endpointsLoading?: LOADING_STATES | null
}

const HeaderCard: React.FC<HeaderCardProps> = ({
  goBack,
  buttons,
  onClick,
  status,
  assigneeName,
  claimId,
  endpointsLoading,
}) => {

const assignToAnotherButton = buttons?.find(button => button.key === 'AssignToAnother');

  return (
    <Grid
      m={1}
      p={2}
      component={Card}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 1,
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          height: "fit-content",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Text variant="h1">Claim Details</Text>
            {claimId && (
              <Text mt={2}>
                Claim ID : <b data-test-id="claimId">{claimId}</b>
              </Text>
            )}
            {assigneeName && (
              <Text>
                Assignee : <b data-test-id="assigneeName">{assigneeName}</b>
              </Text>
            )}
          </Grid>
          <Grid
            item
            ml={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "start",
            }}
          >
            {status && (
              <>
                <Chip
                  data-test-id="claimStatus"
                  color={
                    status === "ON-HOLD"
                      ? "warning"
                      : status === "REJECTED"
                        ? "accent"
                        : status === "APPROVED"
                          ? "secondaryWhite"
                          : status === "PAID"
                            ? "purple"
                            : "primary"
                  }
                  sx={{
                    width: "auto",
                    height: 30,
                    borderRadius: chipRounding,
                    marginRight: 0.5,
                  }}
                  label={status}
                />
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid container spacing={ButtonSpacing}>
            {buttons &&
              buttons.map((item) => {
                switch (item.key) {
                  case "AssignToMe":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-assign-to-me"
                          onClick={() => onClick && onClick("AssignToMe")}
                        >
                          {ActionButtonNames.ASSIGN_TO_ME}
                        </LoadingButton>
                      </Grid>
                    );
                  case "AssignToAnother":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          disabled={!!endpointsLoading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-assign-to-another-user"
                          onClick={() => 
                            onClick && onClick("AssignToAnother")}
                        >
                          {ActionButtonNames.ASSIGN_TO_ANOTHER}
                        </LoadingButton>
                      </Grid>
                    );
                  case "MarkAsUnpaid":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="accent"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-mark-as-unpaid"
                          onClick={() => onClick && onClick("MarkAsUnpaid")}
                        >
                          {ActionButtonNames.UNPAID}
                        </LoadingButton>
                      </Grid>
                    );
                  case "UndoApproval":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-undo-approval"
                          onClick={() => onClick && onClick("UndoApproval")}
                        >
                          {ActionButtonNames.UNDO_APPROVAL}
                        </LoadingButton>
                      </Grid>
                    );
                  case "MarkAsPaid":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="secondaryWhite"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-pay-claim"
                          onClick={() => onClick && onClick("MarkAsPaid")}
                        >
                          {ActionButtonNames.PAID}
                        </LoadingButton>
                      </Grid>
                    );

                  case "Reject":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="accent"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-reject"
                          onClick={() => onClick && onClick("Reject")}
                        >
                          {ActionButtonNames.REJECT}
                        </LoadingButton>
                      </Grid>
                    );
                  case "Approve":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="secondary"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-approve"
                          onClick={() => onClick && onClick("Approve")}
                        >
                          {ActionButtonNames.APPROVE}
                        </LoadingButton>
                      </Grid>
                    );
                  case "PutOnHold":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="warning"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-on-hold"
                          onClick={() => onClick && onClick("PutOnHold")}
                        >
                          {ActionButtonNames.ON_HOLD}
                        </LoadingButton>
                      </Grid>
                    );
                  case "Unassign":
                    return (
                      <Grid item>
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          disabled={!!endpointsLoading || !!assignToAnotherButton?.loading}
                          loading={item.loading}
                          startIcon={item.icon}
                          data-testid="claim-details-unassign"
                          onClick={() => onClick && onClick("Unassign")}
                        >
                          {ActionButtonNames.UNASSIGN}
                        </LoadingButton>
                      </Grid>
                    );
                  default:
                    return null;
                }
              })}
            <Grid item>
              <LoadingButton
                variant="outlined"
                color="primary"
                onClick={goBack}
                data-test-id="buttonBack"
              >
                <ArrowBackIosNewIcon
                  fontSize="small"
                  style={{ marginRight: 4 }}
                />
                Back
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(HeaderCard);
