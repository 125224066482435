export enum LOADING_STATES {
  APPROVE = "APPROVE",
  REJECT = "REJECT",
  ON_HOLD = "ON_HOLD",
  ASSIGN = "ASSIGN",
  ASSIGN_TO_USER = "ASSIGN_TO_USER",
  UNASSIGN = "UNASSIGN",
  UNDO_APPROVAL = "UNDO_APPROVAL",
  PAY = "PAY",
  UNDO_PAYMENT = "UNDO_PAYMENT",
}
