import React from "react";

// Material UI
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

// Custom Imports
import Appbar from "./appbar";
import SideMenu from "./sidemenu";
import ApplicationRoutes from "./routes/ApplicationRoutes";
import { AuthContext } from "store/auth/authContext";
import { usePopups } from "hooks";
import { DRAWER_WIDTH } from "common/constants/ui";

import Auth from "@s12solutions/auth/Auth";
import BannerMessage from "components/bannerMessage";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
}));

const Main = styled("main")(({ theme }) => ({
  marginLeft: DRAWER_WIDTH,
  width: `calc(100vw - ${DRAWER_WIDTH})`,
  height: "100vh",
  padding: theme.spacing(1),
}));

const Layout: React.FC = () => {
  const classes = useStyles();
  const { handleSnackbar } = usePopups();

  // Title bar to environment
  switch (process.env.REACT_APP_ENV) {
    case "dev":
      document.title = "S12 Claims - Development";
      break;
    case "preprod":
      document.title = "S12 Claims - Preprod";
      break;
    case "sandbox":
      document.title = 'S12 Claims - Sandbox';
      break;
    default:
      document.title = "S12 Claims";
      break;
  }

  return (
    <Auth
      context={AuthContext}
      handleSnackbar={handleSnackbar}
      enabledUserGroups={["OrgClaims"]}
    >
      <>
        <Appbar />
        <SideMenu />
        <Main>
          <div className={classes.appBarSpacer} />
          <BannerMessage />
          <ApplicationRoutes />
        </Main>
      </>
    </Auth>
  );
};

export default React.memo(Layout);
