import React from "react";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/en-gb';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { CSVLink } from "react-csv";

// Material UI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CSVHeader, CSVData } from "common/types/commonTypes";

interface DateRangeProps {
  open: boolean;
  closeDateRange: () => void;
  dateRange: (value: DateRange<Dayjs>) => void;
  csvData?: [CSVHeader[], CSVData[]];
  loading?: boolean;
  successCallback: () => void;
}

const DateRangeCalendar: React.FC<DateRangeProps> = ({
  open,
  closeDateRange,
  dateRange,
  csvData,
  loading = false,
  successCallback,
}) => {
  const [value, setValue] = React.useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);
  const [startDateRangeError, setStartDateRangeError] = React.useState(false);
  const [endDateRangeError, setEndDateRangeError] = React.useState(false);
  const [startDateRangeErrorText, setStartDateRangeErrorText] =
    React.useState("");
  const [endDateRangeErrorText, setEndDateRangeErrorText] = React.useState("");

  React.useEffect(() => {
    if (open) {
      const startDate = dayjs().subtract(1, "day");
      const endDate = dayjs();
      setValue([startDate,endDate]);
      dateRange([startDate, endDate]);
    }
    //!! Don't add dateRange as a dependency, as it might cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: 8,
            minHeight: 220,
            // width: window.innerWidth / 3,
          },
        }}
      // onBackdropClick={closeConfirmation}
      >
        <DialogTitle data-test-id="title">
          <Typography component="div" variant="h2" sx={{ fontWeight: "bold" }}>
            Export Claims By Date Range
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              start: "Date Received (from)",
              end: "Date Received (to)",
              }}
            adapterLocale={"en-gb"}
          >
            <DateRangePicker
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              onError={([startDateReason, endDateReason]) => {
                switch (startDateReason) {
                  case "disableFuture":
                  case "maxDate":
                    setStartDateRangeError(true);
                    setStartDateRangeErrorText(
                      "Start date cannot be a future date"
                    );
                    break;
                  case "invalidDate":
                  case "invalidRange":
                    setStartDateRangeError(true);
                    setStartDateRangeErrorText("Invalid start date");
                    break;
                  case "minDate":
                    setStartDateRangeError(true);
                    setStartDateRangeErrorText(
                      "Start date cannot be older than 15 months"
                    );
                    break;
                  default:
                    setStartDateRangeError(false);
                    setStartDateRangeErrorText("");
                    break;
                }
                switch (endDateReason) {
                  case "disableFuture":
                  case "maxDate":
                    setEndDateRangeError(true);
                    setEndDateRangeErrorText(
                      "End date cannot be a future date"
                    );
                    break;
                  case "invalidDate":
                  case "invalidRange":
                    setEndDateRangeError(true);
                    setEndDateRangeErrorText("Invalid end date");
                    break;
                  case "minDate":
                    setEndDateRangeError(true);
                    setEndDateRangeErrorText(
                      "End date cannot be older than 15 months"
                    );
                    break;
                  default:
                    setEndDateRangeError(false);
                    setEndDateRangeErrorText("");
                    break;
                }
              }}
              onAccept={([startDateValue, endDateValue]) => {
                if (startDateValue) {
                  setStartDateRangeError(false);
                  setStartDateRangeErrorText("");
                }
                if (endDateValue) {
                  setEndDateRangeError(false);
                  setEndDateRangeErrorText("");
                }

                if (startDateValue && endDateValue) {
                  dateRange([startDateValue, endDateValue]);
                }
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    {...startProps}
                    helperText={startDateRangeErrorText}
                    id="claim-export-claim-by-range-start-date"
                    data-testid="claim-test-export-claim-by-range-start-date"
                  />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField
                    {...endProps}
                    id="claim-export-claim-by-range-end-date"
                    data-testid="claim-test-export-claim-by-range-end-date"
                    helperText={endDateRangeErrorText}
                  />
                </React.Fragment>
              )}
              disableFuture
              minDate={dayjs().subtract(15, "month")}
              maxDate={dayjs()}
            />
          </LocalizationProvider>
          {(!csvData ||
            (csvData && !csvData[1]) ||
            (csvData && csvData[1] && csvData[1].length === 0)) && (
              <Box sx={{ marginTop: 2 }}>
                <Divider />
                <Typography sx={{ marginTop: 1 }}>
                  There were no claims available for export in this period, please
                  select a different date range to try again
                </Typography>
              </Box>
            )}
        </DialogContent>
        <DialogActions
          sx={{ paddingRight: 2, paddingBottom: 1.5, paddingTop: 1.5 }}
        >
          <Button
            data-test-id="cancel-button"
            autoFocus
            onClick={() => {
              closeDateRange();
            }}
            variant={"outlined"}
          >
            Cancel
          </Button>
          <LoadingButton
            data-test-id="confirm-button"
            disabled={
              endDateRangeError ||
              startDateRangeError ||
              !value ||
              (value && !value[0]) ||
              (value && !value[1]) ||
              !csvData ||
              (csvData && !csvData[1]) ||
              (csvData && csvData[1] && csvData[1].length === 0)
            }
            startIcon={<FileDownloadIcon />}
            variant={"contained"}
            onClick={() => {
              csvData &&
                csvData[1] &&
                csvData[1].length > 0 &&
                successCallback();
            }}
            loading={loading}
          >
            <CSVLink
              data={csvData && csvData[1] ? csvData[1] : []}
              headers={csvData && csvData[0] ? csvData[0] : []}
              filename={`${value[0]?.format(
                "YYYY-MM-DD"
              )} to ${value[1]?.format("YYYY-MM-DD")}.csv`}
              style={{ color: "white", textDecoration: "none" }}
              data-testid="claim-export-claim-by-range-submit"
            >
              EXPORT CLAIMS BY RANGE
            </CSVLink>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(DateRangeCalendar);
