import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  Stack,
  TextField,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { LoadingButton } from "@mui/lab";
import { OrgUserResponse } from "@s12solutions/types";

interface DialogListProps {
  openDialog: boolean;
  closeDialog: () => void;
  listData?: OrgUserResponse[];
  handleUser?: (val: { id: string; name: string } | null) => void;
  selectedRowIds?: number;
  loadingUser?: boolean;
  loading?: boolean;
}

const DialogList: React.FC<DialogListProps> = ({
  openDialog,
  closeDialog,
  listData = [],
  handleUser,
  selectedRowIds,
  loadingUser = false,
  loading = false,
}) => {
  const [value, setValue] = React.useState<{ id: string; name: string } | null>(
    null
  );
  const handleClose = () => {
    closeDialog();
    setValue(null);
  };

  const options = listData.map((item) => {
    return {
      id: item.id,
      name: item.name,
    };
  });

  useEffect(() => {
    if (!openDialog) {
      setValue(null);
    }
  }, [openDialog]);

  return (
    <Dialog
      open={openDialog}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: 8,
          minHeight: 220,
          width: window.innerWidth / 3,
        },
      }}
      onClose={(_event, reason) => {
        if (reason === "backdropClick") {
          handleClose();
        }
      }}
      onBackdropClick={handleClose}
    >
      <DialogTitle data-test-id="title">
        <Typography component="div" variant="h1" sx={{ fontWeight: "bold" }}>
          Assign Claim
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        {!value && (
          <Typography sx={{ paddingRight: 1, paddingLeft: 1, fontSize: 20 }}>
            Select user to be assigned
          </Typography>
        )}
        <Autocomplete
          id="claim-assign-to-another-user-ccg-users-list-input"
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          loading={loadingUser}
          sx={{ minWidth: 175, paddingTop: 2, paddingBottom: 2 }}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <>
              <Stack sx={{ display: "flex", flexDirection: "row" }}>
                <PersonIcon
                  sx={{ marginRight: -3, marginTop: 0.5 }}
                  color={"primary"}
                />
                <TextField
                  data-testid="claim-test-assign-to-another-user-ccg-users-list-input"
                  sx={{
                    ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                      marginLeft: 4,
                    },
                  }}
                  {...params}
                  variant="standard"
                  placeholder="Select user"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              </Stack>
            </>
          )}
        />
        {value && (
          <Typography sx={{ paddingRight: 1, paddingLeft: 1, fontSize: 20 }}>
            You are about to assign
            {selectedRowIds === 1
              ? ` ${selectedRowIds} claim`
              : ` ${selectedRowIds} claims`}{" "}
            to {value?.name}.<br />
            Are you sure you want to do this?
          </Typography>
        )}
      </DialogContent>
      <DialogActions
        sx={{ paddingRight: 2, paddingBottom: 1.5, paddingTop: 1.5 }}
      >
        <Button
          data-test-id="cancel-button"
          autoFocus
          onClick={handleClose}
          variant={"outlined"}
        >
          Cancel
        </Button>
        <LoadingButton
          data-test-id="confirm-button"
          disabled={value ? false : true}
          onClick={() => {
            handleUser && handleUser(value);
          }}
          variant={"contained"}
          loading={loading}
        >
          {selectedRowIds === 1 ? "Assign claim" : "Assign claims"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DialogList);
