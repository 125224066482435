export const NO_MATCHING_RECORDS =
  "No matching records found. Please try again.";
export const ASSIGN_CLAIM =
  "You are about to assign this claim to yourself. \nAre you sure you want to do this?";
export const PUT_CLAIM_UNDER_REVIEW =
  "You are about to put this claim under-review. \nAre you sure you want to do this?";
export const PUT_CLAIMS_UNDER_REVIEW =
  "You are about to put these claims under-review. \nAre you sure you want to do this?";
export const REJECT_CLAIM =
  "Please explain why this claim is being re-opened. This information will be visible to the doctor and other claim processors. When clicking the Reopen Claim button, the claim will return to the Unassigned Tab.";
export const PUT_CLAIM_ON_HOLD =
  "Please explain why this claim is being put on hold.\nThis information will be visible to the doctor and other claim processors.";
export const PUT_CLAIMS_ON_HOLD =
  "Please explain why {count} is being put on hold.\nThis information will be visible to the doctor and other claim processors.";
export const CONFIRM_EXPORT_CLAIMS =
  "You are about to export {count}. \nAre you sure you want to do this?";
export const ASSIGN_CLAIMS =
  "You are about to assign {count} to yourself. \nAre you sure you want to do this?";
export const UNASSIGN_CLAIMS =
  "You are about to unassign {count}. \nAre you sure you want to do this?";
export const UNASSIGN_CLAIM =
  "You are about to unassign this claim. \nAre you sure you want to do this?";
export const PAID_CLAIMS =
  "By marking these {count} as paid, you're confirming the payment for the claim has been made.";

export const PAID_CLAIM =
  "By marking this claim as paid, you're confirming the payment for the claim has been made.";

export const APPROVE_CLAIMS =
  "By approving these claim forms, you're confirming that the information provided is accurate and the fee is eligible for payment.";

export const APPROVE_CLAIM =
  "By approving this claim form, you're confirming that the information provided is accurate and the fee is eligible for payment.";

export const UNDO_APPROVAL_CLAIM =
  "Please explain why this claim is being re-opened. This information will be visible to the doctor and other claim processors. When clicking the Reopen Claim button, the claim will return to the Unassigned Tab.";

export const UNPAID_CLAIM =
  "You are about to mark this claim as unpaid.\nAre you sure you want to do this?";

// Generic messages
export const UNEXPECTED_ERROR_MESSAGE = "Unexpected Error Ocurred";

export const CLAIM_UNASSIGNED = "Claim Unassigned";
export const CLAIM_ASSIGNED = "Claim Assigned";
