import { useState } from "react";

import { AuthContext } from "./authContext";
import { config } from "./config";

// TODO: this wil be extracted into the auth package
import AuthService from "@s12solutions/auth/helpers/AuthService";

/**
 * AuthProvider
 * A very basic wrapper to enable putting the provider in the application tree where
 * we need it to be. Would rather do this at the root level application but can't with
 * current design choices there.
 * @param {React.ReactNode} children - the rest of the app
 * @returns one whole claims processing application with an authenticated ribbon on
 */
const authService = new AuthService(config);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState();
  const [authError, setAuthError] = useState();

  return (
    <AuthContext.Provider
      value={{
        user,
        authError,
        authService,
        setCredentials: setUser,
        clearCredentials: () => setUser(undefined),
        setError: setAuthError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
