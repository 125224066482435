import React from "react";

import { Grid, Typography as Text } from "@mui/material";

const Info: React.FC = () => {
  return (
    <Grid container spacing={3} m={1}>
      <Grid item xs={12}>
        <Text variant="h1">Info</Text>
      </Grid>
    </Grid>
  );
};

export default React.memo(Info);