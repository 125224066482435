import { Button, Grid, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import theme from "theme/theme";

interface StyledHomePageButtonProps {
  color: string;
  navigate: NavigateFunction;
  navigateURL: string;
  title: string;
  count?: string | number;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
}

export const StyledHomePageButton: React.FC<StyledHomePageButtonProps> = ({
  color,
  navigate,
  navigateURL,
  title,
  count,
  Icon,
}) => {
  return (
    <Grid
      data-test-id={`claim-section-button-${title
        .trim()
        .replaceAll(/\s+/g, "-")
        .toLowerCase()}`}
      component={Button}
      variant="outlined"
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        width: 140,
        height: 140,
        borderRadius: 1,
        bgcolor: theme.palette.primary.light,
      }}
      onClick={() => navigate(navigateURL)}
      color={color}
      mr={1}
    >
      <Grid item mt={1}>
        <Typography color={theme.palette.primary.dark} variant="h3" style={{ textTransform: "none" }}>
          {title}
        </Typography>
      </Grid>
      <Grid item mt={1}>
        {count && (
          <Typography
            variant="h1"
            data-test-id={`claim-section-count-${title
              .trim()
              .replaceAll(/\s+/g, "-")
              .toLowerCase()}`}
          >
            {count}
          </Typography>
        )}
      </Grid>
      <Grid item mt={1}>
        <Icon fontSize="large" />
      </Grid>
    </Grid>
  );
};
