import React, { useEffect } from "react";

// Material UI Imports
import { Grid, Card, Typography as Text } from "@mui/material";

import { useNavigate } from "react-router-dom";
import {
  MY_CLAIMS,
  UNASSIGNED,
  TEAM_CLAIMS,
  REJECTED,
  APPROVED,
  PAID,
  SUPPORT,
  ON_HOLD,
  ARCHIVED,
} from "layout/routes/RouteConstants";
import { useAPI, useAuth, usePopups } from "hooks";
import { Methods } from "api";
import {
  Archive,
  Inbox,
  Close,
  CreditScore,
  Done,
  Group,
  Help,
  Pause,
  Person,
} from "@mui/icons-material";
import { StyledHomePageButton } from "components/uiElements";
import theme from "theme/theme";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { TYPE_ERROR } from "common/constants/common";

export interface ClaimCountsResponse {
  unassigned: number;
  my_claims: number;
  team_claims: number;
  on_hold: number;
  approved: number;
  rejected: number;
  approved_and_paid: number;
  archived: number;
  [key: string]: number;
}

const Homepage: React.FC = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const { handleBannerMessage } = usePopups();

  const {
    data,
    error,
    trigger: getClaimCounts,
  } = useAPI<
    ClaimCountsResponse | null,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimCounts",
    manual: true,
  });

  useEffect(() => {
    if (user.user) {
      getClaimCounts({ id: user.user.username });
    }
  }, [getClaimCounts, user.user]);

  useEffect(() => {
    if (error) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [error, handleBannerMessage]);

  return (
    <Grid
      m={1}
      p={2}
      component={Card}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid container m={0}>
        <Grid container spacing={3} sx={{ height: "fit-content" }}>
          <Grid item xs={12}>
            <Text variant="h1">Claims Portal</Text>
            <Text mt={3} sx={{ fontWeight: "bold" }}>
              Please navigate to a common page below, or use the side-menu for
              more options.
            </Text>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.text.primary}
                Icon={Inbox}
                navigate={navigate}
                navigateURL={UNASSIGNED}
                title="Unassigned"
                count={data?.unassigned}
              />
            </Grid>
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.accent.main}
                Icon={Close}
                navigate={navigate}
                navigateURL={REJECTED}
                title="Rejected"
                count={data?.rejected}
              />
            </Grid>
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.secondary.dark}
                Icon={Done}
                navigate={navigate}
                navigateURL={APPROVED}
                title="Approved"
                count={data?.approved}
              />
            </Grid>
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.warning.main}
                Icon={Pause}
                navigate={navigate}
                navigateURL={ON_HOLD}
                title="On Hold"
                count={data?.on_hold}
              />
            </Grid>
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.success.main}
                Icon={CreditScore}
                navigate={navigate}
                navigateURL={PAID}
                title="Paid"
                count={data?.approved_and_paid}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.primary.main}
                Icon={Group}
                navigate={navigate}
                navigateURL={TEAM_CLAIMS}
                title="Team Claims"
                count={data?.team_claims}
              />
            </Grid>
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.primary.main}
                Icon={Person}
                navigate={navigate}
                navigateURL={MY_CLAIMS}
                title="My Claims"
                count={data?.my_claims}
              />
            </Grid>
            <Grid item>
              <StyledHomePageButton
                color={theme.palette.primary.main}
                Icon={Archive}
                navigate={navigate}
                navigateURL={ARCHIVED}
                title="Archived"
                count={data?.archived}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <StyledHomePageButton
              color={theme.palette.primary.main}
              Icon={Help}
              navigate={navigate}
              navigateURL={SUPPORT}
              title="Support"
              count={undefined}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(Homepage);
