import * as React from "react";
import {
  DataGridPro,
  GridOverlay,
  GridColumns,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExportContainer,
  GridToolbarDensitySelector,
  GridFooter,
  GridColumnMenuContainer,
  GridFilterMenuItem,
  SortGridMenuItems,
  GridCsvExportMenuItem,
  GridEventListener,
  GridColumnMenuProps,
  GridSelectionModel,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import * as constants from "common/constants/theme";
import {
  Grid,
  Autocomplete,
  TextField,
  Stack,
  Typography,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import DateRangeCalendar from "components/dateRangeCalendar/DateRangeCalendar";
import * as MESSAGES from "common/constants/messages";
import { useDebounce } from "use-debounce";
import { MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH } from "common/constants/common";
import { makeStyles } from "@mui/styles";
import { DateRange, LoadingButton } from "@mui/lab";
import { usePopups } from "hooks";
import { ConfirmationDialog } from "components/confirmation";
import {
  ActionButtonId,
  ActionButtonList,
  ActionButtonNames,
  ColumnData,
  CSVData,
  CSVHeader,
  FilterData,
  TypeClaim,
} from "common/types/commonTypes";
import { ReactElement } from "react";
import { Dayjs } from "dayjs";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { LOADING_STATES } from "common/types/loading";
import { useProDataGrid } from "./useProDataGrid";

const useStyles = makeStyles((theme) => ({
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.warning.dark,
    fontWeight: "bold",
  },
}));

interface ProDataGridProps {
  title: string;
  subtitle?: string;
  rows: GridRowsProp;
  columns: GridColumns;
  ccgList: ColumnData[];
  assigneeList?: ColumnData[];
  initialState?: GridInitialStatePro;
  loading?: boolean;
  ccgLoading?: boolean;
  assigneeListLoading?: boolean;
  disableSelectionOnClick?: boolean;
  checkboxSelection?: boolean;
  rowsPerPageOptions?: number[];
  onCellEditCommit?: GridEventListener<"cellEditCommit">;
  onClickRowData?: (val: TypeClaim) => void;
  debouncedSearchString?: (val: FilterData) => void;
  required?: boolean;
  requiredAssigneeList?: boolean;
  helperText?: string;
  placeholder?: string;
  dataError?: boolean;
  autoFocus?: boolean;
  errorText?: string;
  filterData: FilterData | null;
  ccgValue?: (val: ColumnData | null) => void;
  assigneeValue?: (val: ColumnData | null) => void;
  filterCcgData?: ColumnData | null;
  filterAssigneeData?: ColumnData | null;
  dateRange?: (val: DateRange<Dayjs>) => void;
  csvData?: [CSVHeader[], CSVData[]];
  selectedCsvData?: [CSVHeader[], CSVData[]];
  button?: ActionButtonList;
  buttonId?: (val: ActionButtonId) => void;
  selectedRows?: (val: string[]) => void;
  refetchData?: () => void;
  dateRangeLoading?: boolean;
  isAssigneeList?: boolean;
  endpointsLoading?: LOADING_STATES | null;
}

const ProDataGrid: React.FC<ProDataGridProps> = ({
  title,
  subtitle,
  rows,
  columns,
  isAssigneeList = false,
  assigneeList,
  ccgList,
  loading,
  ccgLoading,
  assigneeListLoading,
  debouncedSearchString,
  required = false,
  requiredAssigneeList = false,
  helperText,
  placeholder,
  dataError = false,
  autoFocus,
  errorText = "Please select item before search",
  filterData,
  ccgValue,
  assigneeValue,
  filterCcgData,
  filterAssigneeData,
  dateRange,
  csvData,
  selectedCsvData = [[], []],
  button,
  buttonId,
  selectedRows,
  refetchData,
  initialState,
  onClickRowData,
  onCellEditCommit,
  disableSelectionOnClick = true,
  checkboxSelection = true,
  rowsPerPageOptions = [50, 100, 250],
  dateRangeLoading = false,
  endpointsLoading,
  ...props
}) => {
  const classes = useStyles();
  const apiRef = useGridApiRef();
  const [currentPageSize, setCurrentPageSize] = React.useState<number>(
    rowsPerPageOptions[0]
  );
  const [openCalendar, setOpenCalendar] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [searchString, setSearchString] = React.useState<string>(
    filterData ? filterData.searchString : ""
  );
  const [ccgKey, setCcgKey] = React.useState<ColumnData | null>(
    filterCcgData ?? null
  );
  const [assigneeKey, setAssigneeKey] = React.useState<ColumnData | null>(
    filterAssigneeData ?? null
  );
  const { handleBannerMessage } = usePopups();

  const [searchFocus, setSearchFocus] = React.useState<boolean>(false);
  const [errorSearch, setErrorSearch] = React.useState<boolean>(false);
  const [debouncedString] = useDebounce(searchString.trim(), 1000);

  const shouldGetCcgListRef = React.useRef<boolean>(true);

  // Sort the filter lists
  assigneeList = assigneeList?.sort((a, b) =>
    a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
  );
  ccgList = ccgList?.sort((a, b) =>
    a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1
  );

  const {
    isSelected,
    setIsSelected,
    selectionModel,
    setSelectionModel,
    allSelectedRows,
    setAllSelectedRows,
  } = useProDataGrid();
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  /**
   * Fetches the currently visible rows after filtering/sorting is applied.
   */
  const getVisibleRows = () => {
    return apiRef.current.getVisibleRowModels();
  };

  /**
   * Handles the selection of rows for the currently visible rows on the current page.
   * - Updates the cumulative selection across all pages.
   * - Retains previous selections while adding new selections for the current page.
   * - Passes the updated selection back to the parent component.
   *
   * @param newSelection - The newly selected rows (from the current page).
   */
  const handleSelectionChange = (newSelection: GridSelectionModel) => {
    const visibleRowsOnPage = [...getVisibleRows().values()].slice(
      currentPage * currentPageSize,
      (currentPage + 1) * currentPageSize
    );

    const visibleSelection = newSelection.filter((id) =>
      visibleRowsOnPage.some((row) => row.id === id)
    );

    const updatedSelection = [
      ...allSelectedRows.filter(
        (id) => !visibleRowsOnPage.some((row) => row.id === id)
      ),
      ...visibleSelection,
    ];

    setAllSelectedRows(updatedSelection);
    setSelectionModel(updatedSelection);
    selectedRows && selectedRows(updatedSelection.map((id) => id.toString()));
    setIsSelected(updatedSelection.length > 0);
  };

  /**
   * Handles the page change event.
   * - Updates the current page and adjusts the selection model for visible rows.
   *
   * @param newPage - The index of the new page.
   */
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    const visibleRowsOnPage = [...getVisibleRows().values()].slice(
      newPage * currentPageSize,
      (newPage + 1) * currentPageSize
    );
    const visibleSelectionOnPage = allSelectedRows.filter((id) =>
      visibleRowsOnPage.some((row) => row.id === id)
    );
    setSelectionModel(visibleSelectionOnPage);
  };

  /**
   * Handles the change in page size, updating the page size and resetting the current page.
   * - Adjusts the selection model to display rows only visible with the new page size.
   *
   * @param newPageSize - The new number of rows per page.
   */
  const handlePageSizeChange = (newPageSize: number) => {
    setCurrentPageSize(newPageSize);
    setCurrentPage(0);
    const visibleRowsOnPage = [...getVisibleRows().values()].slice(
      0,
      newPageSize
    );
    const visibleSelectionOnPage = allSelectedRows.filter((id) =>
      visibleRowsOnPage.some((row) => row.id === id)
    );
    setSelectionModel(visibleSelectionOnPage);
  };

  // Handle Search
  React.useEffect(() => {
    if (debouncedString.length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH) {
      debouncedSearchString &&
        debouncedSearchString({ searchString: debouncedString });
    } else if (debouncedString.length === 0) {
      refetchData && refetchData();
    }
  }, [debouncedString, debouncedSearchString]);

  React.useEffect(() => {
    if (!shouldGetCcgListRef.current) return;

    if (ccgList && ccgList?.length === 1) {
      ccgValue?.(ccgList?.[0]);
      setCcgKey(ccgList?.[0]);
      shouldGetCcgListRef.current = false;
    }
  }, [ccgList]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          mb={3}
        >
          <Grid item mb={constants.ButtonSpacing}>
            <Typography
              variant="h1"
              data-test-id={`title-${title.replaceAll(" ", "-").toLowerCase()}`}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="h4"
                color="textSecondary"
                sx={{ mt: 2, fontWeight: "light" }}
              >
                {subtitle}
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Grid container spacing={constants.ButtonSpacing}>
              <Grid item>
                <GridToolbarExportContainer
                  variant="contained"
                  color="primary"
                  data-testid="claim-export-options"
                >
                  {isSelected ? (
                    <>
                      <MenuItem
                        onClick={() => {
                          setOpenDialog(true);
                        }}
                        data-testid="claim-export-selected-by-csv"
                      >
                        Export Selected
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <GridCsvExportMenuItem data-testid="claim-export-selected-by-table" />
                    </>
                  )}
                  <MenuItem
                    onClick={() => {
                      setOpenCalendar(true);
                    }}
                    data-testid="claim-export-selected-by-range"
                  >
                    Export By Range
                  </MenuItem>
                </GridToolbarExportContainer>
              </Grid>

              {button && button.markPaid && (
                <Grid item>
                  <LoadingButton
                    disabled={
                      !isSelected ||
                      endpointsLoading !== null ||
                      button.assignClaims?.loading
                    }
                    loading={button.markPaid.loading}
                    variant="contained"
                    color="secondaryWhite"
                    startIcon={button.markPaid.icon}
                    onClick={() => {
                      button &&
                        button.markPaid &&
                        buttonId &&
                        buttonId(button.markPaid.id);
                    }}
                    data-testid="claim-mark-as-paid"
                  >
                    {ActionButtonNames.PAID}
                  </LoadingButton>
                </Grid>
              )}

              {button && button.approve && (
                <Grid item>
                  <LoadingButton
                    disabled={
                      !isSelected ||
                      endpointsLoading !== null ||
                      button.assignClaims?.loading
                    }
                    loading={button.approve.loading}
                    variant="contained"
                    color="secondary"
                    startIcon={button.approve.icon}
                    onClick={() => {
                      button &&
                        button.approve &&
                        buttonId &&
                        buttonId(button.approve.id);
                    }}
                    data-testid="claim-approve"
                  >
                    {ActionButtonNames.APPROVE}
                  </LoadingButton>
                </Grid>
              )}

              {button && button.putOnHoldClaims && (
                <Grid item>
                  <LoadingButton
                    disabled={
                      !isSelected ||
                      endpointsLoading !== null ||
                      button.assignClaims?.loading
                    }
                    loading={button.putOnHoldClaims.loading}
                    variant="contained"
                    color="warning"
                    startIcon={button.putOnHoldClaims.icon}
                    onClick={() => {
                      button &&
                        button.putOnHoldClaims &&
                        buttonId &&
                        buttonId(button.putOnHoldClaims.id);
                    }}
                    data-testid="claim-on-hold"
                  >
                    {ActionButtonNames.ON_HOLD}
                  </LoadingButton>
                </Grid>
              )}

              {button && button.assignMe && (
                <Grid item>
                  <LoadingButton
                    disabled={
                      !isSelected ||
                      endpointsLoading !== null ||
                      button.assignClaims?.loading
                    }
                    loading={button.assignMe.loading}
                    variant="contained"
                    color="primary"
                    startIcon={button.assignMe.icon}
                    onClick={() => {
                      button &&
                        button.assignMe &&
                        buttonId &&
                        buttonId(button.assignMe.id);
                    }}
                    data-testid="claim-assign-to-me"
                  >
                    {ActionButtonNames.ASSIGN_TO_ME}
                  </LoadingButton>
                </Grid>
              )}

              {button && button.assignClaims && (
                <Grid item>
                  <LoadingButton
                    disabled={!isSelected || endpointsLoading !== null}
                    loading={button.assignClaims.loading}
                    variant="contained"
                    color="primary"
                    startIcon={button.assignClaims.icon}
                    onClick={() => {
                      button &&
                        button.assignClaims &&
                        buttonId &&
                        buttonId(button.assignClaims.id);
                    }}
                    data-testid="claim-assign-to-another-user"
                  >
                    {ActionButtonNames.ASSIGN_TO_ANOTHER}
                  </LoadingButton>
                </Grid>
              )}

              {button && button.unassignClaims && (
                <Grid item>
                  <LoadingButton
                    disabled={
                      !isSelected ||
                      endpointsLoading !== null ||
                      button.assignClaims?.loading
                    }
                    loading={button.unassignClaims.loading}
                    variant="contained"
                    color="primary"
                    startIcon={button.unassignClaims.icon}
                    onClick={() => {
                      button &&
                        button.unassignClaims &&
                        buttonId &&
                        buttonId(button.unassignClaims.id);
                    }}
                    data-testid="claim-unassign"
                  >
                    {ActionButtonNames.UNASSIGN}
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
          spacing={constants.ButtonSpacing}
        >
          <Grid item>
            <Stack sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                sx={{
                  marginTop: 0.35,
                  minWidth: 150,
                }}
                size="small"
                variant="standard"
                placeholder="Search..."
                value={searchString}
                autoFocus={searchFocus}
                id="claims-user-input"
                data-testid="claims-test-user-input"
                onChange={(e) => {
                  setSearchString(e.target.value);
                  setSearchFocus(true);
                  if (
                    e.target.value.length <=
                    MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH &&
                    e.target.value.length > 0
                  ) {
                    setErrorSearch(true);
                  } else {
                    setErrorSearch(false);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchString.length > 0 && (
                        <IconButton
                          data-testid="claim-user-input-clear"
                          onClick={() => {
                            setSearchString("");
                            refetchData && refetchData();
                            setErrorSearch(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {errorSearch && (
                <div className={classes.errorText}>
                  <Typography variant="h5">
                    Please type least three characters
                  </Typography>
                </div>
              )}
            </Stack>
          </Grid>

          <Grid item>
            <Autocomplete
              id="filter_ccg"
              options={ccgList}
              getOptionLabel={(option) => option.value}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              loading={ccgLoading}
              // Disable the CCG filter field if the user only have one CCG to avoid unnecessary issues
              disabled={ccgList && ccgList?.length === 1}
              sx={{ minWidth: 200 }}
              value={ccgKey ?? undefined}
              onChange={(event, value) => {
                setSearchFocus(false);
                if (value) {
                  ccgValue && ccgValue(value);
                  setCcgKey(value);
                } else {
                  ccgValue && ccgValue(null);
                  setCcgKey(null);
                }
              }}
              renderInput={(params) => (
                <>
                  <Stack sx={{ display: "flex", flexDirection: "row" }}>
                    <FilterListIcon sx={{ marginRight: -3, marginTop: 0.5 }} />
                    <TextField
                      sx={{
                        ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                          marginLeft: 4,
                        },
                      }}
                      {...params}
                      variant="standard"
                      placeholder="CCG Filter"
                      required={required}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      data-testid="claim-ccg-filter-input"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {ccgLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  </Stack>
                </>
              )}
            />
          </Grid>
          {isAssigneeList && (
            <Grid item>
              <Autocomplete
                id="filter_assignee"
                options={assigneeList ?? []}
                getOptionLabel={(option) => option.value}
                isOptionEqualToValue={(option, value) =>
                  option.key === value.key
                }
                loading={assigneeListLoading}
                sx={{ minWidth: 200 }}
                value={assigneeKey}
                onChange={(event, value) => {
                  setSearchFocus(false);
                  if (value) {
                    assigneeValue && assigneeValue(value);
                    setAssigneeKey(value);
                  } else {
                    assigneeValue && assigneeValue(null);
                    setAssigneeKey(null);
                  }
                }}
                renderInput={(params) => (
                  <>
                    <Stack sx={{ display: "flex", flexDirection: "row" }}>
                      <FilterListIcon
                        sx={{ marginRight: -3, marginTop: 0.5 }}
                      />
                      <TextField
                        sx={{
                          ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                            marginLeft: 4,
                          },
                        }}
                        {...params}
                        variant="standard"
                        placeholder="Assignee Filter"
                        required={required}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        data-testid="claim-assignee-filter-input"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {assigneeListLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    </Stack>
                  </>
                )}
              />
            </Grid>
          )}
        </Grid>
      </GridToolbarContainer>
    );
  };

  const CustomFooter = () => {
    return (
      <GridToolbarContainer
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <GridToolbarColumnsButton data-testid="claim-toolbar-columns" />
          <GridToolbarDensitySelector data-testid="claim-toolbar-density" />
        </Grid>
        <Grid item>
          <GridFooter />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const CustomColumnMenu = (
    props: GridColumnMenuProps
  ): ReactElement<any, any> | null => {
    const { hideMenu, currentColumn } = props;
    return (
      <GridColumnMenuContainer
        hideMenu={hideMenu}
        currentColumn={currentColumn}
        open={false}
      >
        <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
        <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      </GridColumnMenuContainer>
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DateRangeCalendar
        open={openCalendar}
        closeDateRange={() => {
          setOpenCalendar(false);
        }}
        csvData={csvData}
        dateRange={(val) => {
          dateRange && dateRange(val);
        }}
        loading={dateRangeLoading}
        successCallback={() => {
          setOpenCalendar(false);
          handleBannerMessage(
            "success",
            `Claims exported successfully completed`
          );
        }}
      />

      <ConfirmationDialog
        open={openDialog}
        selectedCsvData={selectedCsvData}
        message={MESSAGES.CONFIRM_EXPORT_CLAIMS.replace(
          "{count}",
          `${selectedCsvData &&
            selectedCsvData[1] &&
            selectedCsvData[1].length === 1
            ? selectedCsvData[1].length + " claim"
            : selectedCsvData[1].length + " claims"
          }`
        )}
        closeConfirmation={() => {
          setOpenDialog(false);
        }}
        successCallback={() => {
          handleBannerMessage(
            "success",
            `Claims exported successfully completed`
          );
        }}
      />
      <DataGridPro
        {...props}
        apiRef={apiRef}
        localeText={{
          toolbarExport: "Export Options",
          toolbarExportCSV: "Export Table",
        }}
        components={{
          Toolbar: CustomToolbar,
          Footer: CustomFooter,
          ColumnMenu: CustomColumnMenu,

          NoRowsOverlay: () => (
            <GridOverlay>
              <Typography>{MESSAGES.NO_MATCHING_RECORDS}</Typography>
            </GridOverlay>
          ),
        }}
        onRowClick={(event) => {
          onClickRowData && onClickRowData(event.row as TypeClaim);
        }}
        disableColumnPinning={true}
        disableColumnReorder={true}
        disableSelectionOnClick={disableSelectionOnClick}
        checkboxSelection={checkboxSelection}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionChange}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onCellEditCommit={onCellEditCommit}
        columns={columns}
        rows={rows}
        error={dataError || undefined}
        loading={loading}
        pageSize={currentPageSize}
        rowsPerPageOptions={rowsPerPageOptions}
        initialState={initialState}
        pagination
        autoHeight={true}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row": {
            ":hover": {
              cursor: "pointer",
              backgroundColor: constants.lightBaseGrey,
            },
          },
          "& .super-app-theme--even": {
            backgroundColor: constants.lightGrey,
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        getRowClassName={(params) =>
          `super-app-theme--${params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }`
        }
      />
    </Box>
  );
};

export default React.memo(ProDataGrid);
