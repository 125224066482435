import { useEffect, useState } from "react";
import axios from "axios";
const ENV = process.env;
export function useCheckAppVersion() {
  const [data, setData] = useState({ live: "0" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const url = `${ENV.REACT_APP_S12_API_EP}/checkAppVersion`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (err: any) {
        setError(err);
        setData({ live: "0" });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
}
