// Root
export const ROOT = "/";
// Home
export const HOME = "/home";
// Approved
export const APPROVED = "/approved";
// On Hold
export const ON_HOLD = "/on-hold";
// MyClaims
export const MY_CLAIMS = "/my-claims";
// Paid
export const PAID = "/paid";
// Rejected
export const REJECTED = "/rejected";
// Signin
export const SIGNIN = "/signin";
// Support
export const SUPPORT = "/support";
// TeamClaims
export const TEAM_CLAIMS = "/team-claims";
// Unassigned
export const UNASSIGNED = "/unassigned";
export const UNASSIGNED_DETAILS = "/unassigned/details";
// Info
export const INFO = "/info";
// Archived
export const ARCHIVED = "/archived";

export const PRIVACY_POLICY = "/support/privacypolicy";
export const TERMS_OF_USE = "/support/eula";
export const SUPPORT_EXPLAINED = "/support/explained";
export const CLAIM_EXPLAINED = "/support/claimprocess";
