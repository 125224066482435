import React from "react";
import { Grid, Typography as Text } from "@mui/material";
import Box from "@mui/material/Box";
import * as constants from "common/constants/theme";

interface ContactButtonProps {
  label?: string | undefined;
  contactData?: string | undefined;
  otherData?: string | undefined;
  icon?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  width?: number;
  height?: number;
  contactFontSize?: number;
  iconWidth?: number;
  iconHeight?: number;
}
const ContactButton: React.FC<ContactButtonProps> = ({
  label,
  contactData,
  otherData,
  icon,
  onClick,
  width = "auto",
  height = 100,
  contactFontSize = 20,
  iconWidth = 36,
  iconHeight = 36,
}) => {
  return (
    <Box
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10,
      }}
      width={width}
      height={height}
      sx={{
        backgroundColor: constants.white,
        "&:hover": {
          backgroundColor: constants.lightBaseGrey,
          opacity: [0.9, 0.8, 0.7],
          cursor: "pointer",
        },
        borderRadius: constants.rounding,
      }}
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Grid
          style={{
            paddingRight: 10,
          }}
        >
          <Box
            sx={{
              backgroundColor: constants.secondaryMain,
              borderRadius: 8,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: iconWidth,
              height: iconHeight,
            }}
          >
            {icon}
          </Box>
        </Grid>
        <Grid
          style={{
            paddingLeft: 10,
          }}
        >
          <Grid>
            <Text
              style={{
                fontSize: 16,
                color: constants.textLabel,
                marginBottom: 5,
              }}
            >
              {label}
            </Text>
          </Grid>
          <Grid>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: contactFontSize,
              }}
            >
              {contactData}
            </Text>
          </Grid>
          <Grid>
            <Text
              style={{
                fontSize: 14,
              }}
            >
              {otherData}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactButton;
