/**
 * The main function that does the heavy lifting in the file
 * Bitwise operation of the target feature, against all of the features
 * https://www.w3schools.com/js/js_bitwise.asp
 */
const hasTargetFeatureFlag = (
  targetFeature: number | string,
  features: number | string
) => {
  if (typeof features === "string" && typeof targetFeature === "string") {
    // If we are a string, we are using the *new* system.
    const flags = JSON.parse(features);
    return !!flags[targetFeature];
  } else if (
    typeof features === "object" &&
    typeof targetFeature === "string"
  ) {
    return !!features[targetFeature];
  } else if (
    typeof features === "number" &&
    typeof targetFeature === "number"
  ) {
    // If we are a number, we are using the old system.
    return !!(targetFeature & features);
  } else {
    throw new Error(
      `Unexpected type combination: ${typeof features}, ${typeof targetFeature}`
    );
  }
};

/**
 * updateFeatureFlag:
 * - constructs new updated feature flag field for insertion into DB
 * @param featureFlags - existing featureFlags attribute string
 * @param updateObj - new flags as an object
 * @returns - updated featureFlags attribute string to be inserted into database
 */
// const updateFeatureFlag = (featureFlags: string, updateObj: any) =>
//   JSON.stringify({ ...JSON.parse(featureFlags), ...updateObj });

// CCG CHECKS
export const hasClaims = (features: number | string) =>
  hasTargetFeatureFlag("claims", features);
export const hasMileage = (features: number | string) =>
  hasTargetFeatureFlag("mileage", features);
export const hasAdditionalExpenses = (features: number | string) =>
  hasTargetFeatureFlag("additionalExpenses", features);
export const hasFullPatientName = (features: number | string) =>
  hasTargetFeatureFlag("fullPatientName", features);
export const shouldConfirmAssessmentTookPlace = (features: number | string) =>
  hasTargetFeatureFlag("confirmAssessmentTookPlace", features);
export const shouldProvideVehicleInformation = (features: number | string) =>
  hasTargetFeatureFlag("provideVehicleInformation", features);
export const ccgRequiresNhsNumber = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresNhsNumber", features);
export const ccgRequiresDoctorToInvoice = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresDoctorToInvoice", features);
export const ccgRequiresBillingInformation = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresBillingInformation", features);
export const ccgRequiresLineManager = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresLineManager", features);
export const ccgRequiresGmcNumber = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresGmcNumber", features);
export const ccgDoesNotAcceptHospitalAssessments = (
  features: number | string
) => hasTargetFeatureFlag("ccgDoesNotAcceptHospitalAssessments", features);
export const ccgRequiresAmhpTeamName = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresAmhpTeamName", features);
export const ccgRequiresDoctorMhtAssociations = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresDoctorMhtAssociations", features);
export const ccgDisplayClaimLocation = (features: number | string) =>
  hasTargetFeatureFlag("ccgDisplayClaimLocation", features);
export const ccgRequiresDoctorAdditionalConfirmation = (
  features: number | string
) => hasTargetFeatureFlag("ccgRequiresDoctorAdditionalConfirmation", features);
export const doctorFeesMileageWithoutInvoice = (features: number | string) =>
  hasTargetFeatureFlag("doctorFeesMileageWithoutInvoice", features);
export const mhtAndLineManager = (features: number | string) =>
  hasTargetFeatureFlag("mhtAndLineManager", features);
export const ccgRequiresDoctorEmployedStatus = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresDoctorEmployedStatus", features);
export const docInformalMedRecs = (features: number | string) =>
  hasTargetFeatureFlag("docInformalMedRecs", features);

export const convertBitwiseToFeatureFlagString_CCG = (features: number) => {
  const res = {
    claims: hasClaims(features),
    mileage: hasMileage(features),
    additionalExpenses: hasAdditionalExpenses(features),
    fullPatientName: hasFullPatientName(features),
    confirmAssessmentTookPlace: shouldConfirmAssessmentTookPlace(features),
    provideVehicleInformation: shouldProvideVehicleInformation(features),
    ccgRequiresAmhpTeamName: ccgRequiresAmhpTeamName(features),
    ccgRequiresNhsNumber: ccgRequiresNhsNumber(features),
    ccgRequiresDoctorToInvoice: ccgRequiresDoctorToInvoice(features),
    ccgRequiresBillingInformation: ccgRequiresBillingInformation(features),
    ccgRequiresLineManager: ccgRequiresLineManager(features),
    ccgRequiresGmcNumber: ccgRequiresGmcNumber(features),
    ccgDoesNotAcceptHospitalAssessments:
      ccgDoesNotAcceptHospitalAssessments(features),
    ccgRequiresDoctorMhtAssociations:
      ccgRequiresDoctorMhtAssociations(features),
    ccgDisplayClaimLocation: ccgDisplayClaimLocation(features),
    ccgRequiresDoctorAdditionalConfirmation:
      ccgRequiresDoctorAdditionalConfirmation(features),
    doctorFeesMileageWithoutInvoice: doctorFeesMileageWithoutInvoice(features),
    mhtAndLineManager: mhtAndLineManager(features),
    ccgRequiresDoctorEmployedStatus: ccgRequiresDoctorEmployedStatus(features),
  };
  return JSON.stringify(res);
};
