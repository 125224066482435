import React from "react";
import { Grid, Typography as Text, Card, Stack } from "@mui/material";
import { Email, Call } from "@mui/icons-material";
import ContactButton from "components/contactButton";
import * as constants from "common/constants/theme";
import { AmhpProfile, S12Doctor } from "@s12solutions/types";

interface ContactCardProps {
  docData?: S12Doctor;
  amhpData?: AmhpProfile;
}

const ContactCard: React.FC<ContactCardProps> = ({ docData, amhpData }) => {
  return (
    <Grid
      m={1}
      p={2}
      component={Card}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 1,
      }}
    >
      <Grid container spacing={3} sx={{ height: "fit-content" }}>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
          xs={12}
        >
          <Grid item xs={4}>
            <Stack spacing={1}>
              <Text variant="body1">
                <b>AMHP</b>
              </Text>

              {amhpData?.name && (
                <>
                  <Text variant="body1">Full Name</Text>
                  <Text variant="h1">
                    <b>{amhpData.name}</b>
                  </Text>
                </>
              )}
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {amhpData?.phone && (
                  <ContactButton
                    label="Phone"
                    contactData={amhpData?.phone}
                    icon={
                      <Call htmlColor={constants.white} sx={{ fontSize: 24 }} />
                    }
                    height={65}
                    contactFontSize={16}
                    onClick={() => {
                      window.open(`tel:${amhpData?.phone}`, "_self");
                    }}
                  />
                )}
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {amhpData?.email && (
                  <ContactButton
                    label="Email"
                    contactData={amhpData?.email}
                    icon={
                      <Email
                        htmlColor={constants.white}
                        sx={{ fontSize: 24 }}
                      />
                    }
                    height={65}
                    contactFontSize={16}
                    onClick={() => {
                      window.open(`mailto:${amhpData?.email}`, "_self");
                    }}
                  />
                )}
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack spacing={1}>
              <Text variant="body1">
                <b>Doctor</b>
              </Text>
              {docData?.name && (
                <>
                  <Text variant="body1">Full Name</Text>
                  <Text variant="h1">
                    <b data-test-id="contactDoctorName">{docData?.name}</b>
                  </Text>
                </>
              )}

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {docData?.phone && (
                  <ContactButton
                    label="Phone"
                    contactData={docData?.phone}
                    icon={
                      <Call htmlColor={constants.white} sx={{ fontSize: 24 }} />
                    }
                    height={65}
                    contactFontSize={16}
                    onClick={() => {
                      window.open(`tel:${docData?.phone}`, "_self");
                    }}
                  />
                )}
              </Grid>

              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {docData?.email && (
                  <ContactButton
                    label="Email"
                    contactData={docData?.email}
                    icon={
                      <Email
                        htmlColor={constants.white}
                        sx={{ fontSize: 24 }}
                      />
                    }
                    height={65}
                    contactFontSize={16}
                    onClick={() => {
                      window.open(`mailto:${docData?.email}`, "_self");
                    }}
                  />
                )}
              </Grid>
            </Stack>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ContactCard);
