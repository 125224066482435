import React from "react";

import { Grid, Typography as Text, Link, Card } from "@mui/material";
import ContactButton from "components/contactButton";
import { Call, Email } from "@mui/icons-material";
import * as constants from "common/constants/theme";
import Divider from "@mui/material/Divider";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { useNavigate } from "react-router-dom";
import {
  CLAIM_EXPLAINED,
  PRIVACY_POLICY,
  SUPPORT_EXPLAINED,
  TERMS_OF_USE,
} from "layout/routes/RouteConstants";

interface SupportProps {}

const Support: React.FC<SupportProps> = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        m={1}
        p={2}
        component={Card}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Grid item>
          <Grid item xs={12}>
            <Text variant="h1" data-test-id="title-support">Support</Text>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Text variant="h2">Contact Us</Text>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Grid item xs={4}>
              <ContactButton
                label="Phone"
                contactData="0808 168 0160"
                otherData="Monday - Friday, 8:30am - 5:30pm (excl bank holidays)"
                icon={
                  <Call htmlColor={constants.white} sx={{ fontSize: 36 }} />
                }
                height={130}
                contactFontSize={18}
                iconWidth={60}
                iconHeight={60}
                onClick={() => {
                  window.open(`tel:+4408081680160`, "_self");
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <ContactButton
                label="Email Address"
                contactData="s12solutions-support@vitalhub.com"
                otherData="Monday - Friday, 8:30am - 5:30pm (excl bank holidays)"
                icon={
                  <Email htmlColor={constants.white} sx={{ fontSize: 36 }} />
                }
                height={130}
                contactFontSize={18}
                iconWidth={60}
                iconHeight={60}
                onClick={() => {
                  window.open(
                    `mailto:s12solutions-support@vitalhub.com`,
                    "_self"
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid
            item
            xs={12}
            mt={5}
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              // alignItems: "flex-start",
            }}
          >
            <Grid
              item
              xs={4}
              // sx={{
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "flex-start",
              //   alignItems: "flex-start",
              // }}
            >
              <Grid
                style={{
                  marginBottom: 25,
                  marginLeft: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <ContactSupportIcon color="primary" />
                <Link
                  component="button"
                  variant="h2"
                  underline="hover"
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    navigate(SUPPORT_EXPLAINED);
                  }}
                >
                  Support explained
                </Link>
              </Grid>
              <Grid
                style={{
                  marginBottom: 25,
                  marginLeft: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <AssessmentIcon color="primary" />
                <Link
                  component="button"
                  variant="h2"
                  underline="hover"
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    navigate(CLAIM_EXPLAINED);
                  }}
                >
                  Claim process explained
                </Link>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              ml={24}
              // sx={{
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "flex-start",
              //   alignItems: "flex-start",
              // }}
            >
              <Grid
                style={{
                  marginBottom: 25,
                  marginRight: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <LibraryBooksIcon color="primary" />
                <Link
                  component="button"
                  variant="h2"
                  underline="hover"
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    navigate(TERMS_OF_USE);
                  }}
                >
                  Terms of use
                </Link>
              </Grid>
              <Grid
                style={{
                  marginBottom: 25,
                  marginRight: 20,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <PrivacyTipIcon color="primary" />
                <Link
                  component="button"
                  variant="h2"
                  underline="hover"
                  sx={{ marginLeft: 1 }}
                  onClick={() => {
                    navigate(PRIVACY_POLICY);
                  }}
                >
                  Privacy policy
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
        {/* <Grid item>
          <Grid item xs={12} mb={3}>
            <Divider variant="middle">
              <Text fontWeight={600}>Website Version: 2.5.19c</Text>
            </Divider>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
};

export default React.memo(Support);
