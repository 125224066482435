import { FC, memo, useCallback, useEffect, useState } from "react";
import HeaderCard from "components/detailsPage/HeaderCard";
import AssessmentCard from "components/detailsPage/AssessmentCard";
import ContactCard from "components/detailsPage/ContactCard";
import HistoryCard from "components/detailsPage/HistoryCard";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import { CLAIM_STATUS } from "common/constants/options";
import LoadingCircle from "components/loadingCircle";
import {
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "common/constants/common";
import { AssignmentInd, AssignmentRounded } from "@mui/icons-material";
import {
  CcgUpdateClaimInput,
  Claim,
  ModelListClaimUsersFilterInput,
  Scalars,
  OrgUserResponse
} from "@s12solutions/types";
import dayjs from "dayjs";
import { HeaderActionButtonId } from "common/types/commonTypes";
import { LOADING_STATES } from "common/types/loading";
import { Grid, Card, Typography } from "@mui/material";
import DialogList from "components/dialogList";
import { CognitoUserExt } from "common/types/auth";
import { Methods } from "api";
import { CLAIM_NOTES } from "common/constants/notes";
import { useLocation, useNavigate } from "react-router-dom";

interface UnassignedDetailsProps {
  goBack: (from: "claimUpdating" | "backButton" | "sideMenuClick") => void;
  rowId: string;
  ccgId: string | undefined;
}

const UnassignedDetails: FC<UnassignedDetailsProps> = ({
  goBack,
  rowId,
  ccgId,
}) => {
  const { user } = useAuth();
  const { loadingState, setLoadingState, finishLoading } = useLoading();
  const [data, setData] = useState<Claim | undefined>(undefined);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const [userData, setUserData] = useState<OrgUserResponse[] | undefined>(
    undefined
  );

  const navigate = useNavigate();
  const location = useLocation();

  // Mutations
  const {
    loading: updateClaimLoading,
    error: updateClaimError,
    trigger: updateClaim,
  } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput;
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
  });

  // Queries

  const {
    loading: usersLoading,
    error: usersError,
    trigger: getUsers,
  } = useAPI<
    OrgUserResponse[],
    {
      filter: ModelListClaimUsersFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaimUsers",
    manual: true,
  });

  const {
    data: unassignedClaimDetailsData,
    loading: unassignedClaimDetailsLoading,
    error: unassignedClaimDetailsError,
  } = useAPI<
    Claim,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaim",
    args: {
      id: rowId,
    },
  });


  const assignClaimToUser = useCallback(
    (
      userData: {
        id: string;
        name: string;
      } | null
    ) => {
      if (!userData) {
        return;
      }

      setLoadingState(LOADING_STATES.ASSIGN_TO_USER);
      updateClaim({
        input: {
          id: [rowId],
          assigneeId: userData.id.toString(),
          assigneeName: userData.name,
          notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${userData.name ?? "N/A"
            }@${dayjs().format()}`,
          status: CLAIM_STATUS.underReview,
        },
      })
        .then((data) => {
          if (data && data.length > 0) {
            goBack("claimUpdating");
            handleBannerMessage(TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
          } else {
            handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
          }
        })
        .catch(() => {
          handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
        })
        .finally(finishLoading);
    },
    [
      finishLoading,
      goBack,
      handleBannerMessage,
      rowId,
      setLoadingState,
      updateClaim,
    ]
  );

  const handleClick = useCallback(
    (name: HeaderActionButtonId, ccgId: string, user: CognitoUserExt) => {
      switch (name) {
        case "AssignToAnother":
          if ([rowId].length > 0) {
            getUsers({
              filter: {
                loggedInUserId: user.username,
                selectedClaimOrganisations: [ccgId],
              },
            }).then((data) => {
              setShowUsers(true);
              setUserData(data);
            });
          }
          break;
        case "AssignToMe":
          if (!user) {
            handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
            return;
          }
          handleConfirmation(
            MESSAGES.ASSIGN_CLAIM,
            () => {
              setLoadingState(LOADING_STATES.ASSIGN);
              updateClaim({
                input: {
                  id: [rowId],
                  assigneeId: user.username ?? { eq: "!!!" },
                  assigneeName: user.attributes.name ?? null,
                  notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${user.attributes.name ?? "N/A"
                    }@${dayjs().format()}`,
                  status: CLAIM_STATUS.underReview,
                },
              })
                .then((data) => {
                  if (data && data.length > 0) {
                    goBack("claimUpdating");

                    handleBannerMessage(TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
                  } else {
                    handleBannerMessage(
                      TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                  }
                })
                .catch(() => {
                  handleBannerMessage(
                    TYPE_ERROR,
                    MESSAGES.UNEXPECTED_ERROR_MESSAGE
                  );
                })
                .finally(finishLoading);
            },
            "Assign Claim",
            "ASSIGN CLAIM",
            "CANCEL"
          );
          break;
        default:
          return;
      }
    },
    [
      finishLoading,
      getUsers,
      goBack,
      handleBannerMessage,
      handleConfirmation,
      rowId,
      setLoadingState,
      updateClaim,
    ]
  );

  const buttonClick = useCallback(
    (name: HeaderActionButtonId) => {
      if (!user) {
        handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
        return;
      }
      if (ccgId) {
        handleClick(name, ccgId, user);
      } else if (data?.organisation.id) {
        handleClick(name, data?.organisation.id, user);
      } else {
        handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
        return;
      }
    },
    [ccgId, handleBannerMessage, handleClick, data?.organisation.id, user]
  );

  useEffect(() => {
    if (location?.state?.isSameMenu && !!goBack) {
      goBack("sideMenuClick");
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: false },
      });
    }
  }, [goBack, location.pathname, location?.state?.isSameMenu, navigate]);


  useEffect(() => {
    if (
      unassignedClaimDetailsData &&
      unassignedClaimDetailsData.status === "under_review" &&
      unassignedClaimDetailsData.assigneeId === "!!!"
    ) {
      setData(unassignedClaimDetailsData);
    }
  }, [unassignedClaimDetailsData]);

  useEffect(() => {
    if (unassignedClaimDetailsError || updateClaimError || usersError) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    handleBannerMessage,
    unassignedClaimDetailsError,
    updateClaimError,
    usersError,
  ]);

  return (
    <>
      {unassignedClaimDetailsLoading ? (
        <LoadingCircle />
      ) : data ? (
        <>
          <DialogList
            openDialog={showUsers}
            closeDialog={() => {
              setShowUsers(false);
            }}
            selectedRowIds={[rowId].length}
            listData={userData}
            handleUser={(user) => {
              setShowUsers(false);
              assignClaimToUser(user);
            }}
            loadingUser={usersLoading}
            loading={updateClaimLoading}
          />
          <HeaderCard
            status={data?.status === CLAIM_STATUS.underReview ? "OPEN" : ""}
            assigneeName={data?.assigneeName ?? ""}
            endpointsLoading={loadingState}
            goBack={() => goBack("backButton")}
            buttons={[
              {
                key: "AssignToMe",
                loading: loadingState === LOADING_STATES.ASSIGN,
                icon: <AssignmentRounded />,
              },
              {
                key: "AssignToAnother",
                loading:
                  usersLoading ||
                  loadingState === LOADING_STATES.ASSIGN_TO_USER,
                icon: <AssignmentInd />,
              },
            ]}
            onClick={(a) => buttonClick(a)}
            claimId={data?.displayId}
          />
          <AssessmentCard claim={data} />
          <ContactCard docData={data?.doctor} amhpData={data?.amhp} />
          <HistoryCard data={data} />
        </>
      ) : (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <Typography variant="h1">
                  Unexpected error occurred when fetching the claim data
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(UnassignedDetails);
