export enum CLAIM_NOTES {
  CLAIM_ASSIGNED = "Claim assigned to team member",
  CLAIM_APPROVED = "Claim approved by team member",
  CLAIM_CREATED = "Claim created by AMHP",
  CLAIM_SUBMITTED = "Claim submitted by Doctor",
  CLAIM_UNDO_APPROVAL = "Claim approval undo by team member",
  CLAIM_MARKED_AS_PAID = "Claim marked as paid by team member",
  CLAIM_REJECTED = "Claim rejected by team member",
  CLAIM_PUT_TO_ON_HOLD = "Claim put to on-hold by team member",
  CLAIM_UNASSIGNED = "Claim unassigned by team member",
}
