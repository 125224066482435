export const INITIAL_FEATURE_FLAG_VALUES = JSON.stringify(
  {}
  /*{
  claims: false,
  mileage: false,
  additionalExpenses: false,
  fullPatientName: false,
  confirmAssessmentTookPlace: false,
  provideVehicleInformation: false,
  ccgRequiresAmhpTeamName: false,
  ccgRequiresNhsNumber: false,
  ccgRequiresDoctorToInvoice: false,
  ccgRequiresBillingInformation: false,
  ccgRequiresLineManager: false,
  ccgRequiresGmcNumber: false,
  ccgDoesNotAcceptHospitalAssessments: false,
  ccgRequiresDoctorMhtAssociations: false,
  ccgDisplayClaimLocation: false,
  ccgRequiresDoctorAdditionalConfirmation: false,
  doctorFeesMileageWithoutInvoice: false,
  mhtAndLineManager: false,
  ccgRequiresDoctorEmployedStatus: false,
  }*/
);

export const INITIAL_USER_PERMISSION_VALUES = JSON.stringify({
  claimPayer: false,
  claimApprover: false,
});
