import { Engine, FuelType } from "@s12solutions/types";

export function formatFuelType(
  fuelType?: FuelType | null,
  engineSize?: Engine | null
) {
  return fuelType
    ? `${formatCamelCase(fuelType)}${
        engineSize ? " (" + formatEngineSize(fuelType, engineSize) + ")" : ""
      }`
    : "";
}

export function formatCamelCase(input: string) {
  return input
    .replace(/^\w/, (c) => c.toUpperCase())
    .replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function formatEngineSize(
  fuelType: FuelType | null,
  engineSize: Engine | null
) {
  if (!fuelType || !engineSize) return "";

  const engineSizeValues = {
    small: "< 1L",
    medium: "1L - 1.5L",
    large: "> 1.5L",
  };
  return engineSizeValues[engineSize];
}

export function formatSnakeCase(input: string) {
  return input.replace(/\s+/g, "_").toLowerCase();
}
