import { Card, Grid, Typography as Text } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <>
      <Grid
        m={1}
        p={2}
        component={Card}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Text mt={1.5} mb={1.5} variant="h1">
          Privacy Policy
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Purpose of this privacy notice
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          We want to let you know how S12 Solutions collects and processes your
          personal data when you use our app. Our app is not intended for
          children and we do not knowingly collect data relating to children. It
          is important that you read this privacy notice and any other privacy
          notice or fair processing notice we give you so that you are fully
          aware of how and why we are using your data. This privacy notice
          supplements the other notices and is not intended to override them.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Controller
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          S12 Solutions Limited is the controller and responsible for your
          personal data (referred to as "S12 Solutions", "we", "us" or "our" in
          this privacy notice). If you have any questions about this privacy
          notice, including any requests to exercise your legal rights, please
          contact our data privacy manager using the details set out below.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Contact Details
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Our full details are: Full name of legal entity: S12 Solutions Limited
          FAO Data Privacy Manager Email address:
          s12solutions-support@vitalhub.com Postal address: 5 Kelso Place, Bath
          BA1 3AU You have the right to make a complaint at any time to the
          Information Commissioner’s Office (ICO), the UK supervisory authority
          for data protection issues (www.ico.org.uk). We would, however,
          appreciate the chance to deal with your concerns before you approach
          the ICO so please contact us in the first instance.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Changes to the privacy notice and your duty to inform us of changes
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Any changes we make to this privacy notice will be posted on this page
          and, where appropriate, notified to you. We want to make sure the
          personal data we hold about you is accurate and current so please keep
          us informed if that data changes during your relationship with us.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          The data we collect about you
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Personal data, or personal information, is any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data). We
          may collect, use, store and transfer different kinds of personal data
          about you: Identity Data includes first name, maiden name, last name,
          username or similar identifier, title, line manager / responsible
          officer, employer, GMC number, date of birth and gender. Contact Data
          includes email address and telephone numbers. Availability for Work
          Data includes the information submitted by you regarding your
          availability for work. Transaction Data includes details about the
          submission and processing of your payment claim forms. Technical Data
          includes internet protocol (IP) address, your login data, browser type
          and version, time zone setting and location, browser plug-in types and
          versions, operating system and platform and other technology on the
          devices you use to access this app. Profile Data includes your
          username and password, preferences, languages spoken, personal
          description and photograph,feedback and survey responses. Usage Data
          includes information about how you use our app and services. We do not
          collect any Special Categories of Personal Data about you (this
          includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, Information about your health and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          If you fail to provide personal data
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with
          services). In this case, we may have to cancel a service you have with
          us but we will notify you if this is the case at the time.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          How is your personal data collected?
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Methods include: Direct interactions. For example you may give us your
          Identity and Contact data by filling in forms or by corresponding with
          us by post, phone, email or otherwise. This includes personal data you
          provide when you: apply for our services; create an account on our
          app; fill in your work availability calendar; use our app (eg location
          and availability for work data); or give us some feedback. Automated
          technologies or interactions. As you interact with our app, we may
          automatically collect Technical Data about your equipment, browsing
          actions and patterns. We collect this personal data by using server
          logs, metrics endpoints, and other similar technologies.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          How we use your personal data
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          We only use your personal data in accordance with the law. The most
          common uses are: In order to perform the contract between us. Where it
          is necessary for our legitimate interests (or those of a third party)
          and your interests and fundamental rights do not override those
          interests. Where we need to comply with a legal or regulatory
          obligation.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Purposes for which we will use your personal data
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Below is a description of all the ways we plan to use your personal
          data and the legal bases for doing so. We have also identified what
          our legitimate interests are where appropriate. We may process your
          personal data using more than one lawful basis depending on the use of
          that data. Please contact us if you need more details about the
          specific legal ground we are relying on to process your personal data
          where more than one ground has been set out in the table below.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Purpose / Activity -Lawful basis for processing including basis of
          legitimate interest
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          To register you as a new user of our services - Performance of a
          contract with you To process information you submit about your work
          availability (a) Performance of a contract with you (b) Necessary for
          our legitimate interests (to enable you and other users of our
          services to view your availability) To manage our relationship with
          you which will include: (a) Notifying you about changes to our terms
          or privacy notice (b) Asking you to leave a review or take a survey -
          (a) Performance of a contract with you (b) Necessary to comply with a
          legal obligation (c) Necessary for our legitimate interests (to keep
          our records updated and to study how customers use our services) To
          administer and protect our business and this app (including
          troubleshooting, data analysis, testing, system maintenance, support,
          reporting and hosting of data) - (a) Necessary for our legitimate
          interests (for running our business, provision of administration and
          IT services, network security, to prevent fraud and in the context of
          a business reorganisation or group restructuring exercise) (b)
          Necessary to comply with a legal obligation
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Change of purpose
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us. If we need to use your personal data for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so. Please note that we may process your personal data
          without your knowledge or consent, in compliance with the above rules,
          where this is required or permitted by law.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Disclosures of your personal data
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          We may share your personal data with: Other companies in the S12
          Solutions Group and third parties which provide IT and system
          administration services, including Amazon Web Services, Inc. which
          hosts the app infrastructure and ensures that it functions properly.
          Third parties with whom we have contractual obligations related to the
          provision of our app (including but not limited to relevant Clinical
          Commissioning Groups, Mental Health Trusts and other NHS bodies).
          Professional advisers including lawyers, bankers, auditors and
          insurers who provide consultancy, banking, legal, insurance and
          accounting services. HM Revenue &amp; Customs, regulators and other
          authorities who require reporting of processing activities in certain
          circumstances. Third parties to whom we may choose to sell, transfer,
          or merge parts of our business or our assets. Alternatively, we may
          seek to acquire other businesses or merge with them. If a change
          happens to our business, then the new owners may use your personal
          data in the same way as set out in this privacy notice. We require all
          third parties to respect the security of your personal data and to
          treat it in accordance with the law. We do not allow our third-party
          service providers to use your personal data for their own purposes and
          only permit them to process your personal data for specified purposes
          and in accordance with our instructions.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          International transfers
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          All of your personal data is stored and will remain in the European
          Economic Area or the United Kingdom. Any transfer of personal data
          from the European Economic Area to the United Kingdom or vice versa
          will be in full accordance with all applicable data protection law,
          including the General Data Protection Regulation(UK and EU versions)
          (GDPR) and the Data Protection Act 2018 and you consent to any such
          transfer being made (though we may rely on derogations other than
          consent and/or other means to comply with the GDPR and the Data
          Protection Act 2018 in making such transfers).
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Data security
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality. We have put in place procedures to deal
          with any suspected personal data breach and will notify you and any
          applicable regulator of a breach where we are legally required to do
          so.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Data retention
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          How long will you use my personal data for? We will only retain your
          personal data for as long as necessary to fulfil the purposes we
          collected it for, including for the purposes of satisfying any legal,
          accounting, or reporting requirements. To determine this we consider
          the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means and the
          applicable legal requirements. By law we have to keep basic
          information about our users (including Contact, Identity, and
          Transaction Data) for six years after they cease being users for tax
          purposes. In some circumstances you can ask us to delete your data:
          see Request erasure below for further information. In some
          circumstances we may anonymise your personal data (so that it can no
          longer be associated with you) for research or statistical purposes in
          which case we may use this information indefinitely without further
          notice to you.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Your legal rights
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data. Please click on the links
          below to find out more about these rights: Request access to your
          personal data. Request correction of your personal data. Request
          erasure of your personal data. Object to processing of your personal
          data. Request restriction of processing your personal data. Request
          transfer of your personal data. Right to withdraw consent. If you wish
          to exercise any of the rights set out above, please contact us.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          No fee usually required
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee or refuse your request if it is clearly unfounded, repetitive or
          excessive.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          What we may need from you
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          Time limit to respond
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          We try to respond to all legitimate requests within one month.
          Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h1">
          Glossary
        </Text>
        <Text mt={1.5} mb={1.5}></Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          LAWFUL BASIS
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          Legitimate Interest means the interest of our business in conducting
          and managing our business to enable us to give you the best
          service/product and the best and most secure experience. We make sure
          we consider and balance any potential impact on you (both positive and
          negative) and your rights before we process your personal data for our
          legitimate interests. We do not use your personal data for activities
          where our interests are overridden by the impact on you (unless we
          have your consent or are otherwise required or permitted to by law).
          You can obtain further information about how we assess our legitimate
          interests against any potential impact on you in respect of specific
          activities by contacting us. Performance of Contract means processing
          your data where it is necessary for the performance of a contract to
          which you are a party or to take steps at your request before entering
          into such a contract. Comply with a legal or regulatory obligation
          means processing your personal data where it is necessary for
          compliance with a legal or regulatory obligation that we are subject
          to.
        </Text>
        <Text mt={1.5} mb={1.5} variant="h2">
          YOUR LEGAL RIGHTS
        </Text>
        <Text mt={1.5} mb={1.5} variant="body1">
          You have the right to: Request access to your personal data (commonly
          known as a “data subject access request”). This enables you to receive
          a copy of the personal data we hold about you and to check that we are
          lawfully processing it. Request correction of the personal data that
          we hold about you. This enables you to have any incomplete or
          inaccurate data we hold about you corrected, though we may need to
          verify the accuracy of the new data you provide to us. Request erasure
          of your personal data. This enables you to ask us to delete or remove
          personal data where there is no good reason for us continuing to
          process it. You also have the right to ask us to delete or remove your
          personal data where you have successfully exercised your right to
          object to processing (see below), where we may have processed your
          information unlawfully or where we are required to erase your personal
          data to comply with local law. Note, however, that we may not always
          be able to comply with your request of erasure for specific legal
          reasons which will be notified to you, if applicable, at the time of
          your request. Object to processing of your personal data where we are
          relying on a legitimate interest (or those of a third party) and there
          is something about your particular situation which makes you want to
          object to processing on this ground as you feel it impacts on your
          fundamental rights and freedoms. In some cases, we may demonstrate
          that we have compelling legitimate grounds to process your information
          which override your rights and freedoms. Request restriction of
          processing of your personal data. This enables you to ask us to
          suspend the processing of your personal data in the following
          scenarios: (a) if you want us to establish the data’s accuracy; (b)
          where our use of the data is unlawful but you do not want us to erase
          it; (c) where you need us to hold the data even if we no longer
          require it as you need it to establish, exercise or defend legal
          claims; or (d) you have objected to our use of your data but we need
          to verify whether we have overriding legitimate grounds to use it.
          Request the transfer of your personal data to you or to a third party.
          We will provide to you, or a third party you have chosen, your
          personal data in a structured, commonly used, machine-readable format.
          Note that this right only applies to automated information which you
          initially provided consent for us to use or where we used the
          information to perform a contract with you. Withdraw consent at any
          time where we are relying on consent to process your personal data.
          However, this will not affect the lawfulness of any processing carried
          out before you withdraw your consent. If you withdraw your consent, we
          may not be able to provide certain products or services to you. We
          will advise you if this is the case at the time you withdraw your
          consent.
        </Text>
      </Grid>
    </>
  );
};

export { PrivacyPolicy };
