// Main application layout, setting up style, routes and manus
import React from "react";

// Material UI
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Custom Imports
import theme from "theme";
import Layout from "layout";
import { PopupsProvider } from "hooks";
import Confirmation from "components/confirmation";
import Snackbar from "components/snackbar";
import AppVersionContext from "store/appVersionContext";
import { useCheckAppVersion } from "./hooks/useCheckAppVersion";

import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from "@sentry/react";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

Sentry.init({
  dsn: "https://917a9b9bc769360be60a91e3a5e013e2@o4506066239094784.ingest.sentry.io/4506066240208897",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "https://claims.my.s12solutions.com",
        "https://yjlkvcabtj.execute-api.eu-west-2.amazonaws.com/prod/claims",
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = () => {
  const { data, loading, error } = useCheckAppVersion();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppVersionContext.Provider
            value={{ version: data.live, loading, error }}
          >
            <PopupsProvider>
              <Layout />
              <Confirmation />
              <Snackbar />
            </PopupsProvider>
          </AppVersionContext.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default React.memo(App);
