import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
// Hooks
import ProDataGrid from "components/proDataGrid/ProDataGrid";
import { Card, Grid } from "@mui/material";
import { CLAIM_STATUS } from "common/constants/options";
import UnassignedDetails from "./UnassignedDetails";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import dayjs, { Dayjs } from "dayjs";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DialogList from "components/dialogList";
import {
  mapCSVData,
  mapTableData,
  tableColumnList,
} from "utils/commonFunctions";
import { uniq, isEmpty } from "lodash";
import { INITIAL_FEATURE_FLAG_VALUES } from "common/constants/initialValues";
import {
  CcgUpdateClaimInput,
  Claim,
  ModelClaimFilterInputNew,
  ModelListClaimUsersFilterInput,
  OrgUserResponse,
} from "@s12solutions/types";
import {
  ColumnData,
  CSVData,
  CSVHeader,
  FeatureFlags,
  FilterData,
  TypeClaim,
} from "common/types/commonTypes";
import { LOADING_STATES } from "common/types/loading";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Methods } from "api";
import { CLAIM_NOTES } from "common/constants/notes";
import { useLocation, useNavigate } from "react-router-dom";
import { useProDataGrid } from "components/proDataGrid/useProDataGrid";
import { ProDataGridProvider } from "components/proDataGrid/ProDataGridProvider";

const UnAssignedWrapper = () => {
  return (
    <ProDataGridProvider>
      <Unassigned />
    </ProDataGridProvider>
  )
}

const Unassigned: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { loadingState, setLoadingState, finishLoading } = useLoading();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [rowData, setRowData] = useState<TypeClaim[]>([]);
  const [clickedRowId, setClickedRowId] = useState<string>("");
  const [searchData, setSearchData] = useState<FilterData | null>(null);
  const [ccgFilterData, setCcgFilterData] = useState<ColumnData | null>(null);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "day"),
    dayjs(),
  ]);
  const [csvData, setCsvData] = useState<[CSVHeader[], CSVData[]]>([[], []]);
  const [selectedCsvData, setSelectedCsvData] = useState<
    [CSVHeader[], CSVData[]]
  >([[], []]);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const [userData, setUserData] = useState<OrgUserResponse[] | undefined>(
    undefined
  );
  const { resetSelected } = useProDataGrid();

  // Queries
  const {
    data: unassignedClaimData,
    loading: unassignedClaimLoading,
    error: unassignedClaimError,
    trigger: getUnassignedClaim,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    manual: true,
  });
  const {
    data: dateRangeData,
    loading: dateRangeDataLoading,
    error: dateRangeDataError,
    trigger: getDateRangeData,
  } = useAPI<
    Claim[],
    {
      filter: ModelClaimFilterInputNew;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaims",
    args: {
      filter: {
        status: CLAIM_STATUS.underReview,
        assigneeId: { eq: "!!!" },
        receivedDateFrom:
          dateRange && dateRange[0] ? dateRange[0].format("YYYY-MM-DD") : "",
        receivedDateTo:
          dateRange && dateRange[1] ? dateRange[1].format("YYYY-MM-DD") : "",
      },
    },
    manual: true,
  });

  const {
    data: ccgData,
    loading: ccgLoading,
    error: ccgError,
    trigger: getCcg,
  } = useAPI<
    OrgUserResponse[],
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUserOrgs",
    manual: true,
  });

  const {
    loading: usersLoading,
    error: usersError,
    trigger: getUsers,
  } = useAPI<
    OrgUserResponse[],
    {
      filter: ModelListClaimUsersFilterInput;
    }
  >({
    method: Methods.GET,
    fieldName: "listClaimUsers",
    manual: true,
  });

  //Mutations
  const {
    loading: updateClaimLoading,
    error: updateClaimError,
    trigger: updateClaim,
  } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput;
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
    onCompleted() {
      setSelectedRowIds([]);
      resetSelected();
    }
  });

  const ccgList = useMemo(
    () =>
      ccgData?.map((item) => {
        return {
          key: item.id,
          value: item.name,
        };
      }) ?? [],
    [ccgData]
  );

  const ccg = useMemo(() => ccgData ?? [], [ccgData]);

  const featuresFromAllOrgs = useMemo(
    () =>
      ccg
        .map(
          (o) =>
            JSON.parse(
              o?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
            ) as FeatureFlags
        )
        .reduce(
          (acc, curr) => ({
            ...acc,
            ...Object.entries(curr)
              .filter(([_, value]) => !!value)
              .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {}),
          }),
          {} as FeatureFlags
        ),
    [ccg]
  );

  const currentOrg = useMemo(
    () =>
      ccgFilterData
        ? ccg.find((o) => o.id === ccgFilterData?.key)
        : ({} as OrgUserResponse),
    [ccg, ccgFilterData]
  );
  const featuresFromCurrentOrg = useMemo(
    () =>
      !isEmpty(
        JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags
      )
        ? (JSON.parse(
          currentOrg?.featureFlags ?? INITIAL_FEATURE_FLAG_VALUES
        ) as FeatureFlags)
        : featuresFromAllOrgs,
    [currentOrg?.featureFlags, featuresFromAllOrgs]
  );

  const getClaims = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    return getUnassignedClaim({
      filter:
        searchData && ccgFilterData
          ? {
            status: CLAIM_STATUS.underReview,
            assigneeId: { eq: "!!!" },
            searchValue: searchData.searchString,
            claimOrganisationId: ccgFilterData.key,
          }
          : searchData
            ? {
              status: CLAIM_STATUS.underReview,
              assigneeId: { eq: "!!!" },
              searchValue: searchData.searchString,
            }
            : ccgFilterData
              ? {
                status: CLAIM_STATUS.underReview,
                assigneeId: { eq: "!!!" },
                claimOrganisationId: ccgFilterData.key,
              }
              : {
                status: CLAIM_STATUS.underReview,
                assigneeId: { eq: "!!!" },
              },
    });
  }, [
    ccgFilterData,
    getUnassignedClaim,
    handleBannerMessage,
    searchData,
    user,
  ]);

  const assignToMe = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.ASSIGN_CLAIMS.replace(
        "{count}",
        `${selectedRowIds.length === 1
          ? selectedRowIds.length + " claim"
          : selectedRowIds.length + " claims"
        }`
      ),
      () => {
        setRowData((data) =>
          data.filter((val) => !selectedRowIds.includes(val.id))
        );
        setLoadingState(LOADING_STATES.ASSIGN);
        updateClaim({
          input: {
            id: selectedRowIds,
            assigneeId: user.username,
            assigneeName: user.attributes.name,
            notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${user.attributes.name ?? "N/A"
              }@${dayjs().format()}`,
            status: CLAIM_STATUS.underReview,
          },
        })
          .then((data) => {
            if (data && data.length > 0) {
              getClaims();
              handleBannerMessage(COMMON.TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          })
          .finally(finishLoading);
      },
      "Assign Claim",
      selectedRowIds.length === 1 ? "ASSIGN CLAIM" : "ASSIGN CLAIMS",
      "CANCEL"
    );
  }, [
    finishLoading,
    getClaims,
    handleBannerMessage,
    handleConfirmation,
    selectedRowIds,
    setLoadingState,
    updateClaim,
    user,
  ]);

  const assignClaimToUser = useCallback(
    (
      userData: {
        id: string;
        name: string;
      } | null
    ) => {
      if (!userData) {
        return;
      }
      setLoadingState(LOADING_STATES.ASSIGN_TO_USER);
      setRowData((data) =>
        data.filter((val) => !selectedRowIds.includes(val.id))
      );
      updateClaim({
        input: {
          id: selectedRowIds,
          assigneeId: userData.id.toString(),
          assigneeName: userData.name,
          notes: `${CLAIM_NOTES.CLAIM_ASSIGNED}@${userData.name ?? "N/A"
            }@${dayjs().format()}`,
          status: CLAIM_STATUS.underReview,
        },
      })
        .then((data) => {
          if (data && data.length > 0) {
            getClaims();
            handleBannerMessage(COMMON.TYPE_SUCCESS, MESSAGES.CLAIM_ASSIGNED);
          } else {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        })
        .catch(() => {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        })
        .finally(finishLoading);
    },
    [
      setLoadingState,
      updateClaim,
      selectedRowIds,
      finishLoading,
      handleBannerMessage,
      getClaims,
    ]
  );

  useEffect(() => {
    if (unassignedClaimData && !unassignedClaimLoading) {
      setRowData(unassignedClaimData?.map(mapTableData));
    } else {
      setRowData([]);
    }

    return () => {
      setRowData([]);
    };
  }, [unassignedClaimData, unassignedClaimLoading]);

  // Get Unassigned Claim
  useEffect(() => {
    getClaims();
  }, [getClaims]);

  useEffect(() => {
    if (user) {
      getCcg({
        id: user.username,
      });
    }
  }, [getCcg, user]);

  useEffect(() => {
    if (dateRange) {
      getDateRangeData();
    }
  }, [dateRange, getDateRangeData]);

  useEffect(() => {
    if (dateRangeData) {
      setCsvData(mapCSVData(dateRangeData));
    }
  }, [dateRangeData]);

  useEffect(() => {
    if (
      unassignedClaimError ||
      ccgError ||
      dateRangeDataError ||
      usersError ||
      updateClaimError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    ccgError,
    dateRangeDataError,
    handleBannerMessage,
    unassignedClaimError,
    usersError,
    updateClaimError,
  ]);

  const setSearchDataValue = useCallback(
    (val: FilterData) => {
      setSearchData(val);
    },
    [setSearchData]
  );

  useEffect(() => {
    if (location?.search.length > 0) {
      setClickedRowId(decodeURIComponent(location?.search.substring(1)));
      setShowDetails(true);
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: true },
      });
    }
  }, [location.pathname, location?.search, navigate]);

  return (
    <>
      {showDetails && (
        <UnassignedDetails
          rowId={clickedRowId}
          goBack={(from: "claimUpdating" | "backButton" | "sideMenuClick") => {
            switch (from) {
              case "sideMenuClick":
              case "backButton":
              case "claimUpdating":
                getClaims();
                break;
              default:
                break;
            }

            setShowDetails(false);
          }}
          ccgId={rowData.find((val) => val.id === clickedRowId)?.ccgId}
        />
      )}
      {!showDetails && (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <DialogList
                openDialog={showUsers}
                closeDialog={() => {
                  setShowUsers(false);
                }}
                selectedRowIds={selectedRowIds.length}
                listData={userData}
                handleUser={(user) => {
                  setShowUsers(false);
                  assignClaimToUser(user);
                }}
                loadingUser={usersLoading}
                loading={updateClaimLoading}
              />
              <Grid item xs={12}>
                <ProDataGrid
                  title="Unassigned"
                  rows={rowData}
                  columns={tableColumnList(
                    featuresFromAllOrgs,
                    featuresFromCurrentOrg,
                    ccgFilterData ? ccgFilterData.key : undefined,
                    "unassigned"
                  )}
                  dataError={!unassignedClaimData && !unassignedClaimLoading}
                  ccgList={ccgList}
                  filterData={searchData}
                  filterCcgData={ccgFilterData}
                  csvData={csvData}
                  selectedCsvData={selectedCsvData}
                  dateRangeLoading={dateRangeDataLoading}
                  button={{
                    assignMe: {
                      id: "assign_to_me",
                      icon: <AssignmentRoundedIcon />,
                      loading: loadingState === LOADING_STATES.ASSIGN,
                    },
                    assignClaims: {
                      id: "assign_claim",
                      icon: <AssignmentIndIcon />,
                      loading:
                        usersLoading ||
                        loadingState === LOADING_STATES.ASSIGN_TO_USER,
                    },
                  }}
                  buttonId={(val) => {
                    if (!user) {
                      handleBannerMessage(
                        COMMON.TYPE_ERROR,
                        MESSAGES.UNEXPECTED_ERROR_MESSAGE
                      );
                      return;
                    }
                    switch (val) {
                      case "assign_to_me":
                        assignToMe();
                        break;
                      case "assign_claim":
                        if (selectedRowIds.length > 0) {
                          getUsers({
                            filter: {
                              loggedInUserId: user.username,
                              selectedClaimOrganisations: uniq(
                                rowData
                                  .filter((val) =>
                                    selectedRowIds.some((v) => v === val.id)
                                  )
                                  .map((val) => val.ccgId)
                              ),
                            },
                          }).then((data) => {
                            setShowUsers(true);
                            setUserData(data);
                          });
                        }
                        break;

                      default:
                        break;
                    }
                  }}
                  dateRange={(val) => {
                    setDateRange(val);
                  }}
                  loading={unassignedClaimLoading}
                  ccgLoading={ccgLoading}
                  refetchData={() => {
                    setSearchData(null);
                  }}
                  debouncedSearchString={setSearchDataValue}
                  ccgValue={(val) => {
                    setCcgFilterData(val);
                  }}
                  selectedRows={(rowIds) => {
                    setSelectedRowIds(rowIds);
                    if (unassignedClaimData) {
                      setSelectedCsvData(
                        mapCSVData(unassignedClaimData, rowIds)
                      );
                    }
                  }}
                  onClickRowData={(event) => {
                    setClickedRowId(event.id);
                    setShowDetails(true);
                    navigate(location.pathname, {
                      state: { isSameMenu: false, isInDetailsPage: true },
                    });
                  }}
                  endpointsLoading={loadingState}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(UnAssignedWrapper);
