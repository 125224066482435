import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import HeaderCard from "components/detailsPage/HeaderCard";
import AssessmentCard from "components/detailsPage/AssessmentCard";
import ContactCard from "components/detailsPage/ContactCard";
import HistoryCard from "components/detailsPage/HistoryCard";
import { useAPI, useAuth, useLoading, usePopups } from "hooks";
import * as MESSAGES from "common/constants/messages";
import { CLAIM_STATUS } from "common/constants/options";
import LoadingCircle from "components/loadingCircle";
import dayjs from "dayjs";
import { TYPE_ERROR } from "common/constants/common";
import { Close, CreditScore } from "@mui/icons-material";
import { CcgUpdateClaimInput, User, Claim } from "@s12solutions/types";
import { HeaderActionButtonId } from "common/types/commonTypes";
import { LOADING_STATES } from "common/types/loading";
import { Grid, Card, Typography } from "@mui/material";
import { Methods } from "api";
import { compact } from "lodash";
import { CLAIM_NOTES } from "common/constants/notes";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { CLAIM_ACTIONS } from "common/types/schema";

interface ApprovedDetailsProps {
  goBack: (from: "claimUpdating" | "backButton" | "sideMenuClick") => void;
  rowId: string;
}

const ApprovedDetails: FC<ApprovedDetailsProps> = ({ goBack, rowId }) => {
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const { user } = useAuth();
  const { loadingState, setLoadingState, finishLoading } = useLoading();

  const [data, setData] = useState<Claim | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  // Queries
  const {
    data: approvedClaimDetailsData,
    loading: approvedClaimDetailsLoading,
    error: approvedClaimDetailsError,
  } = useAPI<
    Claim,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaim",
    args: {
      id: rowId,
    },
  });

  const {
    data: claimUserData,
    error: claimUserDataError,
    trigger: getUserData,
  } = useAPI<
    User,
    {
      id: string;
    }
  >({
    method: Methods.GET,
    fieldName: "getClaimUser",
    manual: true,
  });

  // Mutations
  const { error: updateClaimError, trigger: updateClaim } = useAPI<
    Claim[],
    {
      input: CcgUpdateClaimInput & {
        action?: CLAIM_ACTIONS;
      };
    }
  >({
    method: Methods.PUT,
    fieldName: "updateClaim",
  });

  const userPermissions = useMemo(
    () => ({
      claimApprover: claimUserData?.claimApprover ?? false,
      claimPayer: claimUserData?.claimPayer ?? false,
    }),
    [claimUserData]
  );

  const buttonClick = useCallback(
    (name: HeaderActionButtonId) => {
      if (!user) {
        handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
        return;
      }
      switch (name) {
        case "UndoApproval":
          !!userPermissions.claimApprover &&
            handleConfirmation(
              MESSAGES.UNDO_APPROVAL_CLAIM,
              (reason?: string) => {
                setLoadingState(LOADING_STATES.UNDO_APPROVAL);
                updateClaim({
                  input: {
                    id: [rowId],
                    notes: reason
                      ? `${CLAIM_NOTES.CLAIM_UNDO_APPROVAL}@${
                          user.attributes.name ?? "N/A"
                        }@${dayjs().format()}@${reason}`
                      : `${CLAIM_NOTES.CLAIM_UNDO_APPROVAL}@${
                          user.attributes.name ?? "N/A"
                        }@${dayjs().format()}`,
                    status: CLAIM_STATUS.underReview,
                    action: "UNDO_APPROVAL",
                    assigneeId: "!!!",
                    assigneeName: "!!!",
                  },
                })
                  .then((data) => {
                    if (data && data.length > 0) {
                      handleBannerMessage(
                        "success",
                        `Claim approval undo by ${user.attributes.name}`
                      );
                      goBack("claimUpdating");
                    } else {
                      handleBannerMessage(
                        TYPE_ERROR,
                        MESSAGES.UNEXPECTED_ERROR_MESSAGE
                      );
                    }
                  })
                  .catch((error) => {
                    handleBannerMessage(
                      TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                    Sentry.captureException(
                      "Claims > approved details, undo approval error.",
                      error
                    );
                  })
                  .finally(finishLoading);
              },
              "Reopen Claim",
              "REOPEN CLAIM",
              "CANCEL",
              true
            );
          break;
        case "MarkAsPaid":
          !!userPermissions.claimPayer &&
            handleConfirmation(
              MESSAGES.PAID_CLAIM,
              () => {
                setLoadingState(LOADING_STATES.PAY);
                updateClaim({
                  input: {
                    id: [rowId],
                    notes: `${CLAIM_NOTES.CLAIM_MARKED_AS_PAID}@${
                      user.attributes.name ?? "N/A"
                    }@${dayjs().format()}`,
                    status: CLAIM_STATUS.paid,
                  },
                })
                  .then((data) => {
                    if (data && data.length > 0) {
                      handleBannerMessage(
                        "success",
                        `Claim marked as paid by ${user.attributes.name}`
                      );
                      goBack("claimUpdating");
                    } else {
                      handleBannerMessage(
                        TYPE_ERROR,
                        MESSAGES.UNEXPECTED_ERROR_MESSAGE
                      );
                    }
                  })
                  .catch((error) => {
                    handleBannerMessage(
                      TYPE_ERROR,
                      MESSAGES.UNEXPECTED_ERROR_MESSAGE
                    );
                    Sentry.captureException(
                      "Claims > approved details, mark as paid error.",
                      error
                    );
                  })
                  .finally(finishLoading);
              },
              "Mark as Paid",
              "MARK AS PAID",
              "CANCEL"
            );
          break;
        default:
          break;
      }
    },
    [
      finishLoading,
      goBack,
      handleBannerMessage,
      handleConfirmation,
      rowId,
      setLoadingState,
      updateClaim,
      user,
      userPermissions.claimApprover,
      userPermissions.claimPayer,
    ]
  );

  useEffect(() => {
    if (user) {
      getUserData({
        id: user.username,
      });
    }
  }, [getUserData, user]);

  useEffect(() => {
    if (
      approvedClaimDetailsData &&
      approvedClaimDetailsData.status === "approved"
    ) {
      setData(approvedClaimDetailsData);
    }
  }, [approvedClaimDetailsData]);

  useEffect(() => {
    if (updateClaimError || approvedClaimDetailsError || claimUserDataError) {
      handleBannerMessage(TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    updateClaimError,
    claimUserDataError,
    approvedClaimDetailsError,
    handleBannerMessage,
  ]);

  useEffect(() => {
    if (location?.state?.isSameMenu && !!goBack) {
      goBack("sideMenuClick");
      navigate(location.pathname, {
        state: { isSameMenu: false, isInDetailsPage: false },
      });
    }
  }, [goBack, location.pathname, location?.state?.isSameMenu, navigate]);

  return (
    <>
      {approvedClaimDetailsLoading ? (
        <LoadingCircle />
      ) : data ? (
        <>
          <HeaderCard
            status={data?.status === CLAIM_STATUS.approved ? "APPROVED" : ""}
            claimId={data?.displayId}
            endpointsLoading={loadingState}
            assigneeName={data?.assigneeName ?? ""}
            goBack={() => goBack("backButton")}
            buttons={compact([
              !!userPermissions.claimApprover
                ? {
                    key: "UndoApproval",
                    loading: loadingState === LOADING_STATES.UNDO_APPROVAL,
                    icon: <Close />,
                  }
                : null,
              !!userPermissions.claimPayer
                ? {
                    key: "MarkAsPaid",
                    loading: loadingState === LOADING_STATES.PAY,
                    icon: <CreditScore />,
                  }
                : null,
            ])}
            onClick={(a) => {
              buttonClick(a);
            }}
          />
          <AssessmentCard claim={data} />
          <ContactCard docData={data?.doctor} amhpData={data?.amhp} />
          <HistoryCard data={data} />
        </>
      ) : (
        <Grid
          m={1}
          p={2}
          component={Card}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container m={0}>
            <Grid container spacing={3} sx={{ height: "fit-content" }}>
              <Grid item xs={12}>
                <Typography variant="h1">
                  Unexpected error occurred when fetching the claim data
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default memo(ApprovedDetails);
