import { Navigate, Outlet } from 'react-router-dom';

interface PrivateRouteProps {
  access: boolean;
  redirectUrl?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ access, redirectUrl = '/signin' }) => {
    // If access is true, return an outlet that will render child elements
    // If not, return element that will navigate to the specified redirectUrl (by default '/signin')
    return access ? <Outlet /> : <Navigate to={redirectUrl} />;
}

export default PrivateRoute;