import React from "react";
import { Grid, Typography as Text, Card, Button } from "@mui/material";
import dayjs from "dayjs";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { Claim } from "@s12solutions/types";
import { compact, slice } from "lodash";
import { CLAIM_NOTES } from "common/constants/notes";

interface HistoryCardProps {
  data?: Claim;
}

const NUMBER_OF_TIMELINES_PER_INCREMENT = 5;

const HistoryCard: React.FC<HistoryCardProps> = ({ data }) => {
  const [timelineData, setTimelineData] = React.useState<string[]>([]);
  const [index, setIndex] = React.useState(NUMBER_OF_TIMELINES_PER_INCREMENT);
  const initialData = React.useMemo(
    () => slice(timelineData, 0, index),
    [index, timelineData]
  );
  const [isCompleted, setIsCompleted] = React.useState(false);

  React.useEffect(() => {
    setTimelineData(
      compact([
        ...compact(data?.notes).reverse(),
        data?.receivedDate
          ? `${CLAIM_NOTES.CLAIM_SUBMITTED}@${data?.doctor.name ?? "N/A"
            }@${dayjs(data?.receivedDate).format()}`.toString()
          : null,
        data?.createdAt
          ? `${CLAIM_NOTES.CLAIM_CREATED}@${data?.amhp.name ?? "N/A"}@${dayjs(
            data?.createdAt
          ).format()}`.toString()
          : null,
      ])
    );
  }, [data]);

  const loadMore = () => {
    setIndex((index) => index + NUMBER_OF_TIMELINES_PER_INCREMENT);
  };

  const loadLess = () => {
    setIndex(NUMBER_OF_TIMELINES_PER_INCREMENT);
  };

  React.useEffect(() => {
    if (index >= timelineData.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  }, [index, timelineData.length]);

  return (
    <Grid
      m={1}
      p={2}
      component={Card}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        borderRadius: 1,
      }}
    >
      <Grid container spacing={3} sx={{ height: "fit-content" }}>
        <Grid item spacing={2}>
          <Text variant="h1">Claim History</Text>
          {/* {!data?.notes && <Text variant="body1">No history data</Text>} */}
          {initialData.length > 0 && (
            <Timeline position="right">
              {initialData.map((item, index, array) => {
                let name = `${item.split("@")[0]} ( ${item.split("@")[1]} )`;
                let date = dayjs(item.split("@")[2]).format("D MMMM YYYY");
                let time = dayjs(item.split("@")[2]).format("HH:mm");
                let reason = `Reason: ${item.split("@")[3]}`;
                return (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot
                        color="primary"
                        variant={index === 0 ? "filled" : "outlined"}
                      />
                      {index !== array.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent style={{ flex: "1000 1 100%" }}>
                      <Text variant="body1">{name}</Text>
                      {!!item.split("@")[3] && (
                        <Text variant="body2">{reason}</Text>
                      )}
                      <Text variant="body2">
                        {date} - {time}
                      </Text>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          )}

          {!isCompleted ? (
            <Button
              onClick={loadMore}
              variant="contained"
              size="large"
              color="primary"
              data-testid="claim-details-show-more-timeline"
            >
              Show More
            </Button>
          ) : (
            initialData.length > NUMBER_OF_TIMELINES_PER_INCREMENT && (
              <Button
                onClick={loadLess}
                variant="contained"
                size="large"
                color="secondaryWhite"
                data-testid="claim-details-show-less-timeline"
              >
                Show Less
              </Button>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(HistoryCard);
